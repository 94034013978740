import React from 'react'
import '../../assets/css/privacy.css'

export default function Terms_sec() {
  return (
    <>
        <div className='privcy-main'>
            <div className='cstm-container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='awrd-ttl'>Capricorn Logistics Pvt. Ltd. – Terms & Conditions</h2>

                        <h2 className='awrd-ttl'>Terms</h2>
                        
                        <p>The terms and conditions contained in the succeeding paragraphs govern your use of this website. By accessing this website, you are agreeing to be bound by these website Terms and Conditions of Use, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this website. The materials contained in this website are protected by applicable copyright and trademark law.</p>

                        <h2 className='awrd-ttl'>Copyright</h2>

                        <p>The copyright in this publication is owned by Capricorn Logistics Private Limited (“CLPL”).</p>

                        <h2 className='awrd-ttl'>Authorization to reproduce</h2>

                        <p>No person shall modify, copy, reproduce, transmit or distribute any portion of this material, unless the following conditions are fulfilled:</p>
                        
                        <ul class="mission-list">
                            <li>The information must be used for informational and non-commercial purposes only.</li>
                            <li>The information used cannot be subject to any modification, alteration or amendment. It must be used in its original form.</li>
                            <li>Any copy of any portion of the material used must include the following copyright notice :<br /><em>Copyright @ Capricorn Logistics Private Limited. All Rights Reserved.</em></li>
                        </ul>

                        <h2 className='awrd-ttl'>Trademark information</h2> 

                        <p>All CLPL’s trademarks – (Mention the trademarks in use) – are registered trademarks of CLPL or its subsidiaries worldwide. No license to use any of these trademarks is given or implied. None of these abovementioned trademarks can be copied, downloaded, reproduced, used, modified or distributed in any way (except as an integral part of an authorized copy of material appearing in these web pages, as set forth in the previous section paragraph) without our prior written consent.</p>

                        <p>All other trademarks or trade names that appear on this website are the property of their respective owners. This website may contain other proprietary notices and trademark information. The terms of all these notices must be observed and followed.</p>

                        <a className="nav-link navbar-brand logo" href="/"><img src={require('../../assets/img/logo2.png')}></img></a>

                        <h2 className='awrd-ttl'>Use of website</h2>

                        <p>You understand that, except for information, products or services clearly identified as being supplied by CLPL, CLPL does not operate, control or endorse any information, products or services on the Internet in any way. Except for CLPL identified information, products or services, all information, products and services offered through the website or on the Internet generally are offered by third parties that are not affiliated with CLPL.</p>

                        <h2 className='awrd-ttl'>Limited Access</h2> 

                        <p>This Website may, at times, be inaccessible or inoperable for any reason, including equipment malfunctions, maintenance procedures or repairs, causes which CLPL has no control over and those that are not reasonably foreseeable by CLPL, or any other time at the sole discretion of CLPL.</p>                    

                        <h2 className='awrd-ttl'>Indemnity</h2> 

                        <p>You agree to indemnify, defend and hold harmless CLPL, its officers, directors, employees, agents, licensors, suppliers and any third party information providers to the Service from and against all losses, expenses, damages and costs, including reasonable attorneys’ fees, resulting from any violation of this Agreement (including negligent or wrongful conduct) by you or any other person accessing the Service.</p>

                        <h2 className='awrd-ttl'>Disclaimer of Warranties</h2> 

                        <p>CLPL disclaims all warranties, whether express, implied, statutory or otherwise, including but not limited to the implied warranties of merchantability, non-infringement of third parties rights and fitness for a particular purpose. CLPL, its affiliates, and licensors make no representations or warranties about the accuracy, completeness, security or timeliness of the services, content or information provided on or through the CLPL website or systems. No information obtained via the CLPL systems or website shall create any warranty not expressly stated by CLPL in these terms and conditions.<br /><br />
 
                        Some jurisdictions do not allow limitations of implied warranties, so the limitations and exclusions in this section may not apply to you. If you are dealing as a consumer, these provisions do not affect your statutory rights that cannot be waived, if any. You agree and acknowledge that the limitations and exclusions of liability and warranty provided in these terms and conditions are fair and reasonable.</p>

                        <h2 className='awrd-ttl'>Viruses</h2> 

                        <p>You understand that CLPL cannot and does not guarantee or warrant that files available for downloading through the website will be free of infection or viruses, worms, Trojan horses or other code that manifest contaminating or destructive properties. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data input and output, and for maintaining a means external to the website for the reconstruction of any lost data.</p>

                        <h2 className='awrd-ttl'>Links</h2>

                        <p>CLPL has not reviewed all of the sites linked to this website and cannot be held responsible for the contents of any such linked website. The inclusion of any link does not imply an endorsement by CLPL of the linked website. Use of any such linked website is at the user’s own risk.</p>

                        <h2 className='awrd-ttl'>Limitation of liability</h2>

                        <p>To the extent permitted by law, in no event shall CLPL, its affiliates or licensors or any third parties mentioned at the CLPL website be held liable for any incidental, indirect, exemplary, punitive and consequential damages, lost profits, or damages resulting from lost data or business interruption resulting from the use of or inability to use the CLPL website and CLPL systems, services, content or information whether based on warranty, contract, tort, delict, or any other legal theory, and whether or not CLPL is advised of the possibility of such damages.</p>

                        <h2 className='awrd-ttl'>Products & Services</h2>
                        
                        <p>Unless otherwise agreed in writing, the transportation products and services mentioned on these web pages are subject to CLPL’s Terms and Conditions of carriage. Since these may vary depending on the location of the country of origin of the shipment, please contact the nearest CLPL service center to obtain a copy of the local terms and conditions. Not all of CLPL’s products and services may be available in every country.</p>


                        <h2 className='awrd-ttl'>Disclosure of Information</h2>
                        
                        <p>All information provided to CLPL by visitors to these web pages is considered to be confidential and will not be disclosed by CLPL to any third party except as may be required for the provision of the services. Read our Privacy Policy for more details.</p>

                        <h2 className='awrd-ttl'>Changes in Terms & Conditions</h2>
                        
                        <p>CLPL may revise these terms and conditions from time-to-time. The revised terms and conditions will apply to the use of this website from the date of the publication of the revised terms and conditions on this website. Please check this page regularly to be updated with the modified terms and conditions.</p>
                    </div>
                </div>
            </div>
        </div>  
    </>
  );
}
