import { useEffect } from 'react';
import '../../assets/css/connect_us.css'; 
import {NavLink} from 'react-router-dom';
import $ from "jquery";

export default function Connect_us() {

    useEffect(() => {

        $('#get-quaot-widget').on("click", function() {
          $('#get-in-touch').css('opacity','1').fadeIn();
          $('#get-in-touch').css('display','block').fadeIn();
        //   $("body").css("overflow", "hidden");
        });
        
    })    

  return (
    <>
        <div className='' id='connect-us'>
            <div className='cstm-container'>
                <div className='row'>
                    <div className='col-lg-6  connect-col1'>
                        <h2 className='connect-ttl'>Connect With Us</h2>
                        <p className='connect-para'>Entrust your logistics needs to our experts. And scale your business to greater success. See how we can help you.</p>
                    </div>

                    <div className='col-lg-6  connect-col2'>
                        {/* <NavLink to='#' className='connect-us-btn' id='get-quaot-widget'>Get A Quote</NavLink> */}
                        <a role="button" className='connect-us-btn red-btn' id='get-quaot-widget'>Get a Quote</a>
                        <a href='/contact' className='connect-us-btn'>Contact us</a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
