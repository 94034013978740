import React from 'react'
import '../../assets/css/why_capricorn.css'; 
import $ from "jquery";
import { useEffect } from 'react';


export default function Why_capricorn() {

    useEffect(() => {        

		$('.read-mr-btn').on("click", function() {
			$('.technology-content').css('display','block');
		});

        $('.read-mr-btn-qlty').on("click", function() {
            $('.quality-content').css('display','block');
		});

        $('.read-mr-btn-envrmt').on("click", function() {
            $('.environment-content	 ').css('display','block');
		});

        $('.content-close-btn').on("click", function() {
			$('.technology-content').css('display','none');
            $('.quality-content').css('display','none');
            $('.environment-content').css('display','none');
		});

        $('#technology-tab2').on("click", function() {
            $('.img1').css({'opacity':'1', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'0', 'position':'absolute', 'top':'0'});
		});

        $('#quality-tab2').on("click", function() {
            $('.img1').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'1', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'0', 'position':'absolute', 'top':'0'});
		});

        $('#esg-tab2').on("click", function() {
            $('.img1').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'1', 'position':'absolute', 'top':'0'});
		});
         
    })

  return (
    <>
      <div className='whyCp_main whyCp_abt_pg' id='techno'><div id='why-capricorn'></div>
        <div className='technology-content'>
            <div className='content-inner-div'>
                <h2 class="heading">Technology</h2>
                    <p className='cap_tabs_para tabs-content'>Stay ahead of the curve with our cutting-edge logistics solutions. Providing top-quality logistics service requires consistency, and Capricorn Logistics delivers you the best service with our deeply integrated solutions. Move your goods via cost-effective modes and be in constant view of your shipments. With our innovative LIVE SMS feature, you can stay up-to-date on all your bookings. You receive real-time notifications whenever your milestones are complete. Even if you have multiple branches and locations, we present consolidated reports that always give you a complete picture. With CAPLOG, our state-of-the-art software solution, you can stay in control of all your operations all the time. The power of automation drastically reduces repetitive tasks and eliminates manual errors. <u>As a result, you also gain speed on time to market.</u> The acquired ability to move goods faster is a welcome for forwarders. Our skilled technical team understands the pain points of the industry. Therefore our solutions are designed to simplify logistics operations with the latest technology.
                    </p>
                    <a className="content-close-btn" href='#techno'>CLOSE  X</a>
            </div> 
        </div>

        <div className='quality-content'>
            <div className='content-inner-div'>            
                <h2 class="heading">Quality</h2>
                    <p className='cap_tabs_para tabs-content'>Excellence is an integral part of all our services. We aim to deliver quality that makes us the preferred logistics service provider. To help our customers reap the maximum from their investment in logistics, we go to great lengths. Every time a shipment is booked, our internal teams work to provide the best quote by evaluating several permutations. We endeavor to provide maximum benefits to our customers. Our quality standards mean meeting stringent measures at each step, from booking a shipment to the last mile delivery.
                    </p>
                    <a className="content-close-btn" href='#techno'>CLOSE  X</a>
            </div>        
        </div>

        <div className='environment-content'>
            <div className='content-inner-div'>            
                <h2 class="heading">Environment</h2>
                    <p className='cap_tabs_para tabs-content'>We direct our conscious effort toward a sustainable future. We have aligned our processes for a common goal of keeping the environment healthy. By moving most of our functions to digital, we have helped eliminate piles of paper-based documents. Our near-future ambition is to drastically reduce our carbon footprint and make several changes in how we handle logistics. We understand that protecting the environment is a collective effort. So we incorporate sustainability measures at different stages in the processes. We believe in making small changes today to secure a sustainable and safe future. By employing environmental-friendly measures, our customers can expect reduced damage to nature and develop cost-effective supply chains.
                    </p>
                    <a className="content-close-btn" href='#techno'>CLOSE  X</a>
            </div>        
        </div>

        {/* <div className='col-lg-6 whyCp-col2 img-content mbl-img'>
                <div className='img1'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_opportunities_2.jpg')}></img>                            
                </div>
                <div className='img2'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_growth_2.jpg')}></img>                            
                </div>
                <div className='img3'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_culture_1.jpg')}></img>                            
                </div>
      </div> */}

          <div className='row whyCp_row' id='tech-anchr'>                 
              <div className='col-lg-6 whyCp-col1'>
                    <div  className='sec-name-dv'>
                        <span className='dot'></span>
                        <h3 className='sec-name-ttl'>Why Capricorn</h3>
                    </div>
                    <p className='sec-ttl'>We Make a Difference</p>

                    <div className='cap_tabs_sec why_cap_tab'>

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="active" id="technology-tab2" data-bs-toggle="tab" href="#technology2" role="tab" aria-controls="technology" aria-selected="true"><img className='whyCp_icon' src={require('../../assets/img/technology.png')}></img>Technology</a>
                            </li>
                            <li className="nav-item" role="presentation2">                                
                                <a className="" id="quality-tab2" data-bs-toggle="tab" href="#quality2" role="tab" aria-controls="quality" aria-selected="false"><img className='whyCp_icon' src={require('../../assets/img/quality.png')}></img>Quality</a>
                            </li>
                            <li className="nav-item" role="presentation2">                                
                                <a className="" id="esg-tab2" data-bs-toggle="tab" href="#esg2" role="tab" aria-controls="esg" aria-selected="false"><img className='whyCp_icon' src={require('../../assets/img/esg.png')}></img>Environment</a>
                            </li>
                        </ul>


                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technology2" role="tabpanel" aria-labelledby="technology-tab">
                                <p className='cap_tabs_para'>Stay ahead of the curve with our cutting-edge logistics solutions. Providing top-quality logistics service requires consistency, and Capricorn Logistics delivers you the best service with our deeply integrated solutions.</p>

                                <a className="read-mr-btn" href="#tech-anchr"> Read more </a>
                            </div>
                            <div className="tab-pane fade" id="quality2" role="tabpanel" aria-labelledby="quality-tab">
                                <p className='cap_tabs_para'>Excellence is an integral part of all our services. We aim to deliver quality that makes us the preferred logistics service provider. To help our customers reap the maximum from their investment in logistics, we go to great lengths.</p>

                                <a className="read-mr-btn-qlty" href="#tech-anchr"> Read more </a>
                            </div>
                            <div className="tab-pane fade" id="esg2" role="tabpanel" aria-labelledby="esg-tab">
                                <p className='cap_tabs_para'>We direct our conscious effort toward a sustainable future. We have aligned our processes for a common goal of keeping the environment healthy. By moving most of our functions to digital, we've helped eliminate piles of paper-based documents.</p>

                                <a className="read-mr-btn-envrmt" href="#tech-anchr"> Read more </a>
                            </div>
                        </div>


                    </div>
              </div>

              <div className='col-lg-6 whyCp-col2 img-content desk-img abt-whyCp'>
                <div className='img1'>
                    <img className='' src={require('../../assets/img/about-us/why-us/capricorn_logistics_technology.jpg')}></img>                            
                </div>
                <div className='img2'>
                    <img className='' src={require('../../assets/img/about-us/why-us/capricorn_logisitcs_quality.jpg')}></img>                            
                </div>
                <div className='img3'>
                    <img className='' src={require('../../assets/img/about-us/why-us/capricorn_logistics_environment.jpg')}></img>                            
                </div>
              </div>  
          </div>
      </div>
    </>
  )
}
