import React from 'react'
import '../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function Contact_spot() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight ntwrk-sptlt'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a><span>/</span> Contact</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Contact us</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        {/* <p className='sec-sub-ttl'>Management of goods by land, sea and air.</p> */}
                        
                        {/* <p className='dcrb'>Capricorn Logistics was founded in 2001 to make a mark in India’s
                        Clearing and Forwarding industry. Capricorn started its operations with the aim to take
                        India’s logistics industry to the next level, offering the best in logistics services.</p>   */}

                    </div>
                </div>
            </div>
            {/* <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img> */}
        </div>
    </>
  )
}
