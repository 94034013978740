import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/media_news.css'; 
import Masonry from 'react-masonry-css';
import useFetch from "react-fetch-hook";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 

export default function Media_news() {
  
    const news = {
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500  
    };


    const { isLoading, data, error } = useFetch(
        "https://phpstack-608738-2577092.cloudwaysapps.com/wordpress/wp-json/wp/v2/news/?categories=4"
    );
    
    const breakpointColumnsObj = {
        default:3,
        1100: 3,
        700: 2,
        500: 1
    };
  return (
    <>
    <div className='media_new_sec'>
    <div className='cstm-container '>
       <div className='row'>
            <div className='co-lg-12'>

            {isLoading && <div>A moment please...</div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}

                <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
                >
                
                </Masonry>
                {/* <Slider {...news} className='news-sld'>
                    {data && data.map(({ id, title , body }) => (
                        <div className='new-block' key={id}>
                            <h5>{id}</h5>
                            <h3>{title}</h3>
                            <p>{body}</p>
                        </div>
                    ))}     
                </Slider> */}

            </div>
        </div> 

        <div className='row'>
            <div className='col-12'>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >
                        
                        {data && data.map(({ id, title , acf , categories, slug }) => (
                            <div className='news-item nws-grd' key={id}>
                                
                                <div className='nw-prt1'>
                                    <div className='nws-dates'>
                                        <p>{acf.media_category}</p>
                                        <p className='dt'><b>.</b></p>
                                        <p>{acf.date}</p>
                                    </div>
                                    <div className='add-btn'>
                                        <span className='frst-spn'></span>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='nw-prt2'>
                                    <h2>{title.rendered}</h2>
                    
                                </div>

                                <div className='nw-prt3'>
                                    <img src={acf.image} />
                                    <a className='blg-view-btn' href={"/news/" + slug}>Read more </a> 
                                </div>
                            </div>
                        ))}

                </Masonry>
            </div>
        </div>
    </div> 
    </div>
                  
    </>
  )
}
