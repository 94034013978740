import React from 'react'
import Slider from 'react-slick';
import { useState, useRef ,useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Journey_slider() {

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    gsap.registerPlugin(ScrollTrigger); 
    
    const jrny_anime_fade = useRef(null);
    const jrny_anime_sldup = useRef(null);

    useEffect(() => {
        gsap.to("#jrny_anime", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.8,
        scrollTrigger: {
            trigger: "#jrny_anime",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#bx_sldup", {
        opacity: 1, 
        y: 0,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#bx_sldup",
            markers: true
        }
        });
    }, []);



    useEffect(() => {

        setNav1(slider1);
        setNav2(slider2);

    });

    const from_client = {
        dots: false,
        swipe:false,
        // fade:true,
        arrow:true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        asNavFor: '.slider-nav'
    };
    
    const to_client = {
        dots: false,
        swipe:false,
        // fade:true,
        infinite: true,
        arrow:false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.slider-for'
    };

  return (
    <>
      <div className='journey-section' id='our-journey'>
                <div className='cstm-container'>
                    <div className='row no_mrgin'>
                        <div className='col-lg-6 jrny-col1'>
                            <h2 ref={jrny_anime_fade} id='jrny_anime'>Our Journey<br/>Of Growth.</h2>
                            <div className='innr-dv1'>
                                <span className='white-spc1'></span>
                                <div className='frm-box1' ref={jrny_anime_sldup} id='bx_sldup'>
                                    <p className='clnt-dtl'>From <br/></p>
                                <Slider {...from_client} className='frm-clnt-sld' asNavFor={nav2} ref={slider => (setSlider1(slider))}>

                                    <div>
                                        <p className='clnt-dtl'>50 Clients <br/> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                    <div>
                                        <p className='clnt-dtl'>04 Locations <br/> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                    <div>
                                        <p className='clnt-dtl'>17 Employees <br/> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                    
                                    <div>
                                        <p className='clnt-dtl'>04 Sectors <br/> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                
                                </Slider>
                                    
                                </div>
                                <span className='blue-box1'></span>
                            </div>
                            <div className='innr-dv2'>
                                <span className='white-spc2'></span>
                                <span className='blue-box2'></span>
                            </div>
                        
                        </div>
                        <div className='col-lg-6 jrny-col2'>
                            <div className='innr-col2-dv1'>
                                <div className='frm-box2' ref={jrny_anime_sldup} id='bx_sldup'>
                                    <p className='clnt-dtl'>To <br/></p>
                                    <Slider {...to_client} className='to-clnt-sld' asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                                    
                                        <div>
                                            <p className='clnt-dtl'>1200+ Clients <br/> in 2022</p>
                                            <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>    
                                        </div>
                                        <div>
                                            <p className='clnt-dtl'>52 Locations <br/> in 2022</p>
                                            <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>    
                                        </div>
                                        <div>
                                            <p className='clnt-dtl'>1200 Employees <br/> in 2022</p>
                                            <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>    
                                        </div>
                                        <div>
                                            <p className='clnt-dtl'>10 Sectors <br/> in 2022</p>
                                            <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>    
                                        </div> 
                                    </Slider>    
                                </div>
                                <span className='blue-box3'></span>
                            </div>
                            <div className='innr-col2-dv2'>
                                <span className='trans-spc'></span>
                                <span className='blue-box4'></span>
                            </div>
                        </div>

                        
                    </div>    
                </div>
            </div>
    </>
  )
}
