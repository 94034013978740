import React from 'react'
import '../../assets/css/life_capricorn.css'; 
import { useRef , useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


export default function Life_capricorn() {

    const revel = useRef(null);
    const parallex1 = useRef(null);
    const parallex2 = useRef(null);

    useEffect(() => {
        gsap.to(".prlx_img1", {
            yPercent: 30,
            ease: "none",
            scrollTrigger: {
              trigger: ".cul-para",
              scrub: true
            }, 
        });
    }, []);

    useEffect(() => {
    
        gsap.to(".prlx_img2", {
            yPercent: -30,
            ease: "none",
            scrollTrigger: {
              trigger: ".cul-para",
              scrub: true
            }, 
        });
    }, []);


    useEffect(() => {
        gsap.to(".rvl-layer", {
        left: 370,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: ".rvl-layer",
            markers: true
        }
        });
    }, []);

  return (
    <>
      <div className='life-capricorn light-blue sec-pd-tb'>
          <div className='cstm-container'>
              <div className='row lc-row'>
                  <div className='col-12 lc-hd-col'>
                        <div>
                            <span className='dot'></span>
                            <h3 className='sec-name-ttl'>Life at Capricorn</h3>
                        </div>
                        <h2 className='lc-hd'>Step Into a New<br/> World With Us</h2>
                  </div>
                  <div className='col-12 lc-tabs-col'>
                    
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <a className="active" id="culture-tab" data-bs-toggle="tab" href="#culture" role="tab" aria-controls="culture" aria-selected="true">Culture</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="" id="growth-tab" data-bs-toggle="tab" href="#growth" role="tab" aria-controls="growth" aria-selected="false">Growth</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className=" opport" id="opportunities-tab" data-bs-toggle="tab" href="#opportunities" role="tab" aria-controls="opportunities" aria-selected="false">Opportunities</a>
                        </li>
                    </ul>
          
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="culture" role="tabpanel" aria-labelledby="culture-tab">
                            <div className='row cul-row'>
                                <div className='col-lg-4 cul-col1'>
                                    {/* <div className='reveal' ref={parallex1}>
                                        <span className='rvl-layer'></span> */}
                                        <img className='cul-img cultr-img1'  src={require('../../assets/img/homepage/life-at-capricorn/culture/capricorn_logstics_culture_2.png')}></img>
                                    {/* </div> */}
                                    <p className='cul-para'>We bring together people from various cultural backgrounds with a wide range of knowledge, skill set, ideas, and innovation. We share a culture of mutual respect and drive our passion for achieving a common goal of excellence and customer satisfaction.</p>
                                    <a href='/careers/life-at-capricorn' className='brder-btn cltr-lrn-mor mbl-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col2'>
                                    <img className='packge-img' src={require('../../assets/img/capricorn_logstics_people_carrying_package.png')}></img>
                                    <h2 className='cul-ttl'>Culture</h2>
                                    <img className='conversn-img' src={require('../../assets/img/capricorn_logstics_People_having_a_conversation.png')}></img>
                                    <a href='/careers/life-at-capricorn' className='brder-btn cltr-lrn-mor desk-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col3'>                                                                     
                                        <img className='cul-img cultr-img2'  src={require('../../assets/img/homepage/life-at-capricorn/culture/capricorn_logstics_culture_1.png')}></img>                                   
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="growth" role="tabpanel" aria-labelledby="growth-tab">
                            <div className='row cul-row'>
                                <div className='col-lg-4 cul-col1'>
                                    {/* <div className='reveal'> */}
                                        <img className='cul-img  growth-img1' src={require('../../assets/img/homepage/life-at-capricorn/growth/capricorn_logistics_growth_2.png')}></img>
                                    {/* </div> */}
                                    <p className='cul-para'>Pursuing a career with Capricorn can give you various career growth opportunities within the organization. Being a part of passion-driven professionals, you will be challenged to go beyond the status quo to achieve more every day.</p>
                                    <a href='/careers/life-at-capricorn' className='brder-btn cltr-lrn-mor mbl-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col2'>
                                    <img className='packge-img' src={require('../../assets/img/capricorn_logstics_people_carrying_package.png')}></img>
                                    <h2 className='cul-ttl'>Growth</h2>
                                    <img className='conversn-img' src={require('../../assets/img/capricorn_logstics_People_having_a_conversation.png')}></img>
                                    <a href='/careers/life-at-capricorn' className='brder-btn grdth-lrn-mor desk-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col3'>
                                    <img className='cul-img  growth-img2' src={require('../../assets/img/homepage/life-at-capricorn/growth/capricorn_logistics_growth_1.png')}></img>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="opportunities" role="tabpanel" aria-labelledby="opportunities-tab">
                            <div className='row cul-row'>
                                <div className='col-lg-4 cul-col1'>
                                    <img className='cul-img opportu-img1' src={require('../../assets/img/homepage/life-at-capricorn/opportunities/capricorn_logistics_opportunities_1.png')}></img>
                                    <p className='cul-para'>We value our employees and provide them with unique opportunities to use their skills and imagination to push the boundaries of what's possible in the freight and logistics domain. We encourage our employees to upskill and gain knowledge and experience.</p>
                                    <a href='/careers/life-at-capricorn' className='brder-btn cltr-lrn-mor mbl-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col2'>
                                    <img className='packge-img' src={require('../../assets/img/capricorn_logstics_people_carrying_package.png')}></img>
                                    <h2 className='opp-ttl'>Opportunities</h2>
                                    <img className='conversn-img' src={require('../../assets/img/capricorn_logstics_People_having_a_conversation.png')}></img>
                                    <a href='/careers/life-at-capricorn' className='brder-btn cltr-lrn-mor desk-btn'>Learn More</a>
                                </div>
                                <div className='col-lg-4 cul-col3'>
                                    <img className='cul-img opportu-img2' src={require('../../assets/img/homepage/life-at-capricorn/opportunities/capricorn_logistics_opportunities_2.png')}></img>
                                </div>
                            </div>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
      </div>
    </>
  )
}
