import React from 'react'
import '../../../assets/css/ocean_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Ocean_freight_services() {
  return (
    <>
      <div className='service_main sec-pd-tb ocean_freight_services'>
                <div className='col-lg-4 ocean-srvc-col1 mob-txt'>
                      <h3 className='ocean_srvc_ttl'>Expand Your Global Reach with Capricorn Sea Freight Forwarding</h3>
                      <p className='ocean_srvc_para'>
                      Be it less-than-container loads or full container loads, special equipment, or oversized cargos, we at Capricorn are well equipped to handle them all. From booking cargo to arranging the shipping documents up to delivery, Capricorn Logistics is equipped to handle all of your freight forwarding needs.</p>
                </div>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-8 ocean-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/sea-freight-forwarding/capricorn_logistics_ncl_non_container_load.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>NCL (Non Container Load)</h3>
                                    <p className='srvc-bx-para'>
                                    A non-containerized load service is the best solution for large-sized or unusual cargo.
                                    </p>
                                   
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/sea-freight-forwarding/capricorn_logistics_fcl_full_container_load.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>FCL (Full Container Load)</h3>
                                    <p className='srvc-bx-para'>
                                    Our full container load shipping services provide best-in-class services and cost-efficiency.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/sea-freight-forwarding/capricorn_logistics_lcl_less_than_container_load.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>LCL (Less-Than-Container Load)</h3>
                                    <p className='srvc-bx-para'>
                                    We specialize in providing less-than-container load services to major destinations.
                                    </p>
                                     
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/sea-freight-forwarding/capricorn_logistics_freight_management_services.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Freight Management Services</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics has established esteemed relationships with major carriers in it’s over two decades of logistics and freight forwarding expertise.
                                    </p>
                                     
                                </div>
                            </div>
                        </div>



                      </div>
                  </div>

                  <div className='col-lg-4 ocean-srvc-col1 desk-txt'>
                      <h3 className='ocean_srvc_ttl'>Expand Your Global Reach with Capricorn Sea Freight Forwarding</h3>
                      <p className='ocean_srvc_para'>
                      Be it less-than-container loads or full container loads, special equipment, or oversized cargos, we at Capricorn are well equipped to handle them all. From booking cargo to arranging the shipping documents up to delivery, Capricorn Logistics is equipped to handle all of your freight forwarding needs.</p>
                  </div>

                  
              </div>
          </div>
          <img className='ocean_servc_bg' src={require('../../../assets/img/service_oean_vector.png')}></img>
      </div>
    </>
  )
}
