import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/media_news.css'; 
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';
 

export default function Accounts_assistant() {
 

  return (
    <>
    <div className='media_new_sec' id='custmr_serv'>
    <div className='cstm-container '>
       <div className='row'>
            <div className='co-lg-12'>
            
            </div>
        </div> 

        <div className='row'>
            <div className='col-12'>  
                        
                            <div className='news-item nws-grd'>                             
                                
                                <div className='nw-prt2'>
                                        <h2>Accounts assistant / executive</h2>
                                        <h5 className="job-descrp main-descrp">Job Description</h5>
                                        <ul class="ul-styl">
                                            <li>Bank reconcilation</li>
                                            <li>Knowledge of TDS Deduction, Return &amp; Revised Return.</li>
                                            <li>Knowledge of Service tax, Service tax return.</li>
                                            <li>Knowledge of handling the Service tax Assessment, Scrutiny.</li>
                                            <li>Checking the vendor Invoice</li>
                                            <li>Knowledge of handling the Income tax scrutiny, assessment.</li>
                                            <li>Replying to the notice received from the Income tax, Service Tax &amp; TDS.</li>
                                            <li>Knowledge about finalization of balance sheet.</li>
                                            <li>Collecting the Invoices &amp; keeping the record of the same.</li>
                                        </ul>
                                        
                                        <div class="row  job-detail">
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Department</h5>
                                                <ul class="ul-styl">
                                                    <li>Accounts & finance</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Location</h5>
                                                <ul class="ul-styl">
                                                    <li>Mumbai – andheri</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Qualification</h5>
                                                <ul class="ul-styl">
                                                    <li>Bcom graduate</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Experience</h5>
                                                <ul class="ul-styl">
                                                    <li>1 – 4 years</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>                      
            
            </div>
        </div>
    </div> 
    </div>
    </>
  )
}
