import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/media_news.css'; 
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';
 

export default function Assistant_manager() {
 

  return (
    <>
    <div className='media_new_sec' id='custmr_serv'>
    <div className='cstm-container '>
       <div className='row'>
            <div className='co-lg-12'>
            
            </div>
        </div> 

        <div className='row'>
            <div className='col-12'>  
                        
                            <div className='news-item nws-grd'>                             
                                
                                <div className='nw-prt2'>
                                        <h2>Assistant manager</h2>
                                        <h5 className="job-descrp main-descrp">Job Description</h5>
                                        <ul class="ul-styl">
                                            <li>Monitoring the day to day work done by the team members, Branch accountant.</li>
                                            <li>Co-ordination with other department for the account related query.</li>
                                            <li>Ledger Scrutiny of vendor, Expenses, Depsoit A/c etc</li>
                                            <li>finalization of balance sheet &amp; coordinating with auditor.</li>
                                        </ul>
                                        
                                        <div class="row  job-detail">
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Department</h5>
                                                <ul class="ul-styl">
                                                    <li>Accounts & finance</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Location</h5>
                                                <ul class="ul-styl">
                                                    <li>Mumbai – andheri</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Qualification</h5>
                                                <ul class="ul-styl">
                                                    <li>Bcom graduate</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Experience</h5>
                                                <ul class="ul-styl">
                                                    <li>1 – 4 years</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>                      
            
            </div>
        </div>
    </div> 
    </div>
    </>
  )
}
