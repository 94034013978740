import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/home_spotlight.css'; 


export default function Home_spotlight() {

    const settings = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
	    slidesToScroll: 1,
        speed: 700
         
    };
    return (
    <>
        <div className='spotlight-main'>
            
            
            <Slider {...settings} className='spotlight-sld'>
                
                <div className='sld1 sld'>                   
                    <video loop autoPlay playsinline muted>
                            <source src={require('../../assets/video/CL_Spotlight_Video_new.mp4')} type="video/mp4" />
                    </video>
                        
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'>Best Integrated Logistics and Supply Chain Solutions.</h1>
                        <a href='/services/integrated-supply-chain-solutions' className='rd-btn spt-btn'>Explore Solutions</a>
                        <a className='brder-btn spt-btn' href="/about">About Us</a>
                    </div>
                </div>
                <div className='sld3 sld'>
                    <div className='overlay-img desk-img'>
                    </div>
                    <div className='overlay-img mbl-img'>
                        <img className='overly-img' src={require('../../assets/img/capricorn_logistics_spotlight_img_mob.jpg')}></img>
                    </div>
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'> Fast, Focused, & Flexible Solutions.</h1>
                        <p className='spt-dcrp'>Capricorn Logistics carries a legacy of over two decades in delivering seamless freight forwarding and clearance operations across countries. Our logistics services have enabled our customers deliver operational excellence and enhance customer satisfaction.</p>
                        <a href='/services/global-freight-forwarding-solutions#ocean_freight' className='rd-btn spt-btn'>Our Services</a>
                        <a className='brder-btn spt-btn' href="/about">About Us</a>
                    </div>
                </div>
                <div className='sld2 sld'>
                    <div className='overlay-img desk-img'>
                    </div>
                    <div className='overlay-img mbl-img'>
                        <img className='overly-img' src={require('../../assets/img/capricorn_logistics_spotlight_man_in_warehouse_img_mob.jpg')}></img>
                    </div>
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'> Solutions Optimized for Growth</h1>
                        <p className='spt-dcrp'>At Capricorn Logistics, we are assisting businesses grow by doing more than moving goods. We are trasforming logistics operations to support the world move faster without any supply chain glitches.</p>
                        <a href='/services/integrated-supply-chain-solutions' className='rd-btn spt-btn'>Our Services</a>
                        <a className='brder-btn spt-btn' href="/about">About Us</a>
                    </div>
                </div>
                
            </Slider>
            
        </div>
    </>
  )
}
