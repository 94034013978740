import React from 'react'
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Masonry from 'react-masonry-css';
import { NavLink} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { HashLink } from 'react-router-hash-link';

export default function Service_search(props) {


  let { name } = useParams();

    const { isLoading, data, error } = useFetch(
      `https://phpstack-608738-2577092.cloudwaysapps.com/wordpress/wp-json/wp/v2/services?search=${name}`
    );


    const breakpointColumnsObj = {
      default:1,
      1100: 1,
      700: 2,
      500: 1
  };


  return (
    <>

                    <div className='col-12 srvc-resul-pg'>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                            >
                              {/* {data.length} */}
                                  {data && data.map(({  title , acf , content }) => (
                                    <div className='cstm-container blog-inside-page' >
                                      {isLoading && <div>A moment please...</div>}
                                  {error && (
                                      <div>{`There is a problem fetching the post data - ${error}`}</div>
                                  )}
                                          <h1 className="sec-ttl">{title.rendered}</h1>
                                          <p>{content.rendered}</p>
                                            <HashLink to={acf.service_url} scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'end' })}>
                                                Read more	
                                            </HashLink>
                                            
                                      </div>
                                  ))}
                        </Masonry>
                    </div>
   


      
    </>
  )
}