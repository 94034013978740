import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/cap_fun_slider.css'; 
import Masonry from 'react-masonry-css';
import useFetch from "react-fetch-hook";
import $ from "jquery";
import { useEffect } from 'react';

export default function Cap_fun_slider() {

    useEffect(() => {
        
    })

    const breakpointColumnsObj = {
        default:3,
        1100: 3,
        1024: 3,
        768: 2,
        500: 1
    };

  return (
    <>
    <div className='funimg-slider sec-pd-tb'>
        <div className='cstm-container'>
            <div className='row'>
                <div className='col-12'>
                    <h2 className='fun-ttl'>Fun at Work</h2>
                    <p className='fun-sub-ttl'>View some of the fun moments working @ Capricorn</p>
                </div>
                <div className='col-lg-12 test-col1'>
                    
                    {/* <Slider {...image_sld} className='fun-sld'> */}
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid"
                        columnClassName="my-masonry-grid_column"
                        >
                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/door_to_door.png')}></img>
                                </div>

                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/frght_mang_servc.png')}></img>
                                </div>

                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/integrated_supply.png')}></img>
                                </div>

                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/global_freight.png')}></img>
                                </div>

                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/door_to_door.png')}></img>
                                </div>

                                <div className='item'>
                                    <img className='fun-img' src={require('../../assets/img/frght_mang_servc.png')}></img>
                                </div>

                    </Masonry>
                    {/* </Slider> */}
                </div>
                
            </div>
        </div>
    </div>
    </>
  )
}
