import React from 'react'
import { NavLink } from 'react-router-dom';
import '../../assets/css/media_news.css';
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';


export default function Business_development_manger_melbourne() {


    return (
        <>
            <div className='media_new_sec' id='custmr_serv'>
                <div className='cstm-container '>
                    <div className='row'>
                        <div className='co-lg-12'>

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>

                            <div className='news-item nws-grd'>

                                <div className='nw-prt2'>
                                    <h2>Business Development Manager - Melbourne - Australia</h2>
                                    <h5 className="job-descrp main-descrp">As a Manager in Sales &amp; Marketing Department of the Company, your main job functions shall broadly
                                        be to:</h5>
                                    <ol class="ul-styl" type='i'>
                                        <li>Implement corporate philosophy for business development as may be decided by the
                                            Management from time to time.</li>
                                        <li>CDevelop Business Growth projection of the branch.</li>
                                        <li>Corporate image building.</li>
                                        <li>Identify high profitable markets / customers and develop strategy for penetration.</li>
                                        <li>Strive to make Capricorn Group services as one of the best in the market by continuously
                                            benchmarking with other competitors.</li>
                                        <li>Be sensitive to the environment, market and customers with strong sense of identity and
                                            results.</li>
                                    </ol>


                                    <h5 className="job-descrp main-descrp">Key Result Areas:</h5>
                                    <ul class="ul-styl">
                                        <li>You shall be responsible to make at-least 25 calls every week to his/her own clients and make sure that he/she generates maximum number of inquiries.</li>
                                        <li>You shall be responsible to make at-least 5 times of gross revenue equal to his (salary + conveyance + mobile exp+ other incidental expense) every month.</li>
                                        <li>You have knowledge about all segments of Logistics Industries –Freight & Clearance(Imports/ Export), Transportation and Supply Chain Management, trade & industry.</li>
                                        <li>You will have to ensure the recovery of payment within the agreed credit limit by getting the weekly updates from the concern Departments. Initiate monitoring pending payments and coordinating/joint visits with Credit control team and client for outstanding issues to settle the payments.</li>
                                        <li>You shall be liable to follow the bench marks on rates set by the superiors/Management during your all business dealing.</li>
                                        <li>You will have to inform Operation/CS/Documentation/Billing team of our company about the commitments /special requirements of the clients, while you are closing a deal with the client.</li>
                                        <li>You need to get feedback from the clients for quotations issued</li>
                                        <li>Be honest to whatever you do or carryout as a part of discharge of your functions and for moral standard before subordinate staff.</li>
                                        <li>Work continuously on modification of systems for reduction in time cycle for preparation of documents for improving overall efficiency.</li>
                                        <li>Job needs utmost honesty, integrity & accuracy at all level for preparation of documents to reduce chances of revenue leakages</li>
                                        <li>The job is Managerial/supervisory and confidential in nature calling for initiative and drive.</li>
                                        <li>You shall perform jobs which are incidental to or connected with above functions and which you are capable of handling or being assigned to you from time to time.</li>
                                        <li>Prepare weekly/monthly sales report and market report</li>
                                    </ul>


                                    <h5 className="job-descrp main-descrp">GENERAL</h5>
                                    <ol class="ul-styl" type='1'>
                                        <li>If at any time in our opinion, which is final in this matter you are found non- performer or guilty
                                            of fraud, dishonest, disobedience, disorderly behavior, negligence, indiscipline, absence from duty
                                            without permission or any other conduct considered by us deterrent to our interest or of violation of
                                            one or more terms of this letter alongwith the Appointment Letter attached, your services may be
                                            terminated without notice and on account of reason of any of the acts or omission the company shall be
                                            entitled to recover the damages from you.</li>
                                        <li>You will not accept any present, commission or any sort of gratification in cash or kind from any
                                            person, party or firm or Company having dealing with the company and if you are offered any, you
                                            should immediately report the same to the Management.</li>
                                        <li>You are expected to have thorough knowledge of Custom rules and other legal formalities
                                            related to our business/ operation for their effective discharge and shall act in compliance with
                                            applicable laws and regulations.</li>
                                        <li>Integrity and transparency are the core values in all our business dealings. You shall act in
                                            compliance with applicable laws and regulations, in a manner that excludes considerations of personal
                                            advantage and will not compromise in our commitment to honesty and integrity in any aspect of our
                                            business. We are committed to excellence, in all our endeavors.</li>

                                    </ol>

                                    <div class="row  job-detail">
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Department</h5>
                                            <ul class="ul-styl">
                                                <li>Accounts & finance</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Location</h5>
                                            <ul class="ul-styl">
                                                <li>Melbourne</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Qualification</h5>
                                            <ul class="ul-styl">
                                                <li>Bcom graduate</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Experience</h5>
                                            <ul class="ul-styl">
                                                <li>1 – 4 years</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
