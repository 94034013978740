import React from 'react'
import '../../../assets/css/ocean_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Transportation_services() {
  return (
    <>
      <div className='service_main sec-pd-tb ocean_freight_services'>

                    <div className='col-lg-4 ocean-srvc-col1 mob-txt'>
                                    <h3 className='ocean_srvc_ttl'>We are committed to providing technology-enabled end-to-end transportation solutions.</h3>
                                    <p className='ocean_srvc_para'>
                                    Capricorn's Logistics transportation network spreads across several locations. Rely on our trusted and well-managed fleet for secure, timely, and cost-effective transportation across cities.
                                    </p>
                    </div>

          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-8 ocean-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/transportation/capricorn_logistics_primary_transportation.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Primary Transportation</h3>
                                    <p className='srvc-bx-para'>
                                    At Capricorn logistics, we study the requirements of our customers and provide a customized primary transportation solution that optimizes the customer's transportation path. Using advanced technology, we strategically plan the fastest possible paths between the delivery points using the least number of vehicles, thereby helping our customers save on costs.<br/><br/>
                                    </p>
                                    <p className='srvc-bx-para'>
                                    <b>Services that we offer under Primary Transportation:</b><br/>
                                    </p>
                                    <p className='srvc-bx-para'>
                                    <ul className='mission-list'>
	                                 <li>Milk run planning</li>
	                                 <li>Cross-dock and Consolidation</li>
	                                 <li>Faster transit for long haul trucks</li>
	                                 <li>Real-time tracking & Visibility</li>
	                                 <li>Alerts and Notifications </li>
	                                 <li>Mobile App</li>
                                    </ul>
                                    </p>
                                   
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/transportation/capricorn_logistics_secondary_transportation.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Secondary Transportation</h3>
                                    <p className='srvc-bx-para'>
                                    At Capricorn, we have a vast distribution network, including strategically located warehouses and distribution centres and the most proficient carriers for delivery to any location, anytime. We provide various secondary transportation models, including full truckload, part truckload, milk run, in-city, and reverse logistics as per the requirements of our customers. Our dynamic secondary transportation planning for our customers' needs helps optimize the supply chain costs. Our mobile app provides confirmation and delivery visibility to our customers and all the stakeholders involved.
                                    </p><br/>
                                    <p className='srvc-bx-para'>
                                    <b>Services that we provide under Secondary Transportation:</b><br/>
                                    </p>
                                    <p className='srvc-bx-para'>
                                    <ul className='mission-list'>
	                                 <li>Last-mile delivery</li>
	                                 <li>Same day delivery</li>
	                                 <li>Dynamic route delivery</li>
	                                 <li>In-city consolidation</li>
	                                 <li>Mobile app</li>
                                    </ul>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship last-mile-spacmange'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/transportation/capricorn_logistics_last_mile_solutions.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Last Mile Solutions</h3>
                                    <p className='srvc-bx-para'>
                                    Our top-rated last-mile delivery service asserts our commitment to quality.Our last mile solutions are equipped to handle large volumes on short notice; whereas provide verified, secured and personalized delivery, Hassle-free deliveries from Capricorn Logistics allow you to strengthen your business relations further.
                                    </p>
                                     
                                </div>
                            </div>
                        </div>

                        {/* <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/frght_mang_servc.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Freight management services</h3>
                                    <p className='srvc-bx-para'>
                                        Our dedicated team of logistic professionals meets your service criteria and provides seamless transportation through various modes, including air, sea and land freights. 
                                    </p>
                                     
                                </div>
                            </div>
                        </div> */}



                      </div>
                  </div>

                  <div className='col-lg-4 ocean-srvc-col1 desk-txt'>
                      <h3 className='ocean_srvc_ttl'>We are committed to providing technology-enabled end-to-end transportation solutions.
</h3>
                      <p className='ocean_srvc_para'>
                      Capricorn's Logistics transportation network spreads across several locations. Rely on our trusted and well-managed fleet for secure, timely, and cost-effective transportation across cities.
                      </p>
                  </div>

                  
              </div>
          </div>
          <img className='ocean_servc_bg' src={require('../../../assets/img/service_oean_vector.png')}></img>
      </div>
    </>
  )
}
