import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';

export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> <a href="/services/industrial-and-infactory-solutions/">Industrial & Infactory Solutions </a> <span>/</span> FMCG</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1 fmcg-col' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>FMCG Industry <br />Solutions</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Driving Efficiency and Consistency in Supply Chain for the Country's Largest FMCG Brands.</p>
                        
                        <p className='dcrb'>At Capricorn, we understand the dynamics of the FMCG industry, which involves fluctuations in demand, seasonality, growing competition, pressure on margins, numerable in the form of suppliers, distributors, retailers, and the logistics solutions that make the industry very complex. In addition, the FMCG industry requires logistics solutions for swift and wide distribution. We have been servicing some of the country's most prominent brands and execute thousands of loads daily using our fleets and a network of qualified carriers.<br/><br/>
                        Our wide range of supply chain services, dedicated staff, and global offices help you achieve greater economies of scale to maximise profits. Our supply chain solutions, including supply chain solution planning, design & management in network/facility, warehousing, transportation, and value-added services such as consolidation, packaging, labeling, etc., are all backed by a WMS and real-time status update of the SKUs.</p>

                    </div>
                </div>
            </div>
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
