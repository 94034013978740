import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Compliance_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services compliance'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>
                      <h3 className='air_srvc_ttl'>Moving your goods seamlessly through international trade barriers.</h3>
                      <p className='air_srvc_para'>
                      The consequences of non-compliance with customs clearance include costly penalties, shipping delays, and the retroactive assessment of duties. Our expert customs brokers regularly participate in customs automated programs to keep themselves abreast with the latest tariffs, regulations, and documentation requirements to extend technical expertise to you.<br/><br/>
                      A well-managed documentation process handled by our competent and efficient staff focuses on personalized monitoring and smoother clearance of shipments within 24 hours for air freight and 72 hours for ocean freight. In addition, we provide automated Web-Based Daily Status Reports.
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_classification_and_examination_of_shipments.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Classification & Examination of Shipments</h3>
                                    <p className='srvc-bx-para'>
                                    Our classification team at Capricorn ensures that all the relevant procedures are followed during filing the bill of entry, applying correct duty rates, and identifying origin preferential treatment opportunities.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_direct_port_delivery.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Direct Port Delivery</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn takes pride in being the first customs broker to have started the direct port delivery concept in India. The direct port delivery process helps our customers reduce cost and save time and decongestion at ports, thus facilitating trade and ease of doing business. At present, we do direct port delivery for major automotive giants.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship license-spacmange'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_license_Management.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>License Management</h3>
                                    <p className='srvc-bx-para'>
                                    A well-managed documentation assistance and facilitation for licenses like EPCG, SVB, etc., handled by our competent and efficient staff, focuses on personalized monitoring and smoother clearance of shipments within 24 hours for air freight and 72 hours for ocean freight. We provide automated Web-Based Daily Status Reports.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                         <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_customs_consultancy.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Customs Consultancy</h3>
                                    <p className='srvc-bx-para'>
                                    We come with expertise and domain knowledge of managing complex customs procedures and various documentation involved. We provide customs consultancy to our clients and keep them updated with the latest guidelines and requirements of Indian Customs, including customs regulations, procedures, customs systems, implementation of conventions, etc.
                                    </p>
                                    
                                </div>
                            </div>
                        </div> 



                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img>
      </div>
    </>
  )
}
