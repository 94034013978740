import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';

export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> Integrated
Supply Chain</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl' id='srvcs-ttl'>Integrated<br/> Supply Chain <br/>Solutions </p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Build agility, resilience, and collaboration with Capricorn Logistics Integrated Supply Chain Solutions.</p>
                        
                        <p className='dcrb'>At Capricorn, we provide a comprehensive supply chain management solution combining our highly efficient logistics services, a team of skilled professionals, and digitalization by adopting innovative technologies.</p> 
                        <p className='dcrb'>Our fully integrated and optimized supply chain solution incorporates techniques and best practices connecting each step of your supply chain. It results in one seamless, integrated experience that establishes a robust collaboration between parties. We are specialists in developing an end-to-end supply chain solution tailored specifically to your needs. This helps you reduce operational costs and increase productivity while maintaining sustainability standards.</p>   

                    </div>
                </div>
            </div>
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
