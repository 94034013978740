import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';

export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> <a href="/services/industrial-and-infactory-solutions/">Industrial & Infactory Solutions </a> <span>/</span> Pharma</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Pharma Industry Solutions</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Your trusted partner for Pharma and Healthcare Logistics.</p>
                        
                        <p className='dcrb'>At Capricorn, our specialised team is well equipped and knowledgeable to cater to the specific challenges and requirements of the pharma sector. We provide the most appropriate solutions as per the unique requirements of every pharma customer.</p>  
                        <p className='dcrb'>Our dedicated team for the pharma sector designs comprehensive and 
compliant solutions to deliver your temperature-sensitive shipments till it reaches their destination.</p>  

                    </div>
                </div>
            </div>
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
