import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../../assets/css/fmcg_services.css"

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services fmcg_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-6 serv_img_overlap'>

                      <h3 className='air_srvc_ttl'>Reasons we are trusted by the leading FMCG players.</h3>
                      
                  </div>
                  <div className='col-lg-6 serv_img_overlap'></div>


                  <div className='col-lg-6 serv_img_overlap serv_clmn_gap_lft'>
                    <ul className='serv_cap_icn_lst'>
                      <li>Dedicated team to design and analyse complete supply chain as per the customer's requirement</li>
	<li>Customer-based cost-effectiveness analysis</li>
	<li>Warehousing and transportation services are linked with our in-house WMS, making communications immediate and traceable.</li>
                    </ul>
                  </div>

                  <div className='col-lg-6 serv_img_overlap serv_clmn_gap_rgt'>
                  <ul className='serv_cap_icn_lst'>
                  <li>We offer a broad range of value-added services and a vast network of owned and leased networks of qualified carriers.</li>
	<li>Ensuring transfer only on full truckload as per company norm to optimise cost</li>
	<li>Bundling activity for promotional items.</li>
                    </ul>
                  </div>




                  
              </div>
          </div>
          {/* <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img> */}
      </div>
    </>
  )
}
