import React from 'react'
import Header from '../../components/global/header';
import Blogs_spotlight from '../../components/spotlight/blogs_spotlight';
import Media_blogs from '../../components/sections/media_blogs';

import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';


export default function media_center() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Blogs_spotlight/>
          <Media_blogs/>
  
          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}
