import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Value_added() {
  return (
    < >
      <div className='vlus_main value_added'>
              <div className='mbl-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_value_added_services.png')}></img>
              </div>  
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                    <h2 className='air-frgt-ttl'>Value-Added Solution</h2>
                    <p className='air-frgt-para'>
                    Delivering quality services for fostering trust.
                    </p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_value_added_services.png')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
