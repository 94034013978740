import React from 'react'
import Header from '../../../components/global/header';
import Pharma_spotlight from '../../../components/spotlight/services/pharma_spotlight';
import Pharma from '../../../components/sections/services/pharma';
import Pharma_services from '../../../components/sections/services/pharma_services';
import Global_reach from '../../../components/sections/global_reach';
import Footer from '../../../components/global/footer';
import Connect_us from '../../../components/sections/connect_us';


export default function value_added_solutions_page() {
  return (
    <>
      <Header/>
          <Pharma_spotlight/>
          <Pharma/>
          <Pharma_services/>
          <Connect_us />
          <Footer/>
    </>
  )
}
