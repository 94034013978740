import React from 'react'
import { a} from 'react-router-dom';
import { useEffect } from 'react';
import '../../assets/css/header_style.css'; 
import { HashLink as Link } from 'react-router-hash-link';
import $ from "jquery";
import {useState} from 'react';
import { Collapse } from 'bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Header(props) {

	useEffect(() => {


			var prevScrollpos = window.pageYOffset;
		
			window.onscroll = function() {

				var element = document.getElementById("desk-menu");
				var track  = document.getElementById("track_div");
				var top_bar  = document.getElementById("navbar");
				var currentScrollPos = window.pageYOffset;

				if (prevScrollpos > currentScrollPos) {
					element.classList.remove("scroll-menu");
					track.classList.remove("scroll-menu");
					top_bar.classList.add("topbar_show");
					top_bar.classList.remove("topbar_hide");
				} 
				else {
					element.classList.add("scroll-menu");
					track.classList.add("scroll-menu");
					top_bar.classList.remove("topbar_show");
					top_bar.classList.add("topbar_hide");
				}
				prevScrollpos = currentScrollPos;
			}



		const pathname = window.location.pathname;
		let url = window.location.pathname;


		$('#menu a').each(function() {
			if ($(this).attr('href') == pathname) {
				$(this).addClass('active');
			}
		});


		if((url == '/contact') || (url=='/careers/application-form') || (url=='/careers/current-openings/customer-service-executive') || (url=='/careers/current-openings/accounts-assistant-executive') || (url=='/careers/current-openings/assistant-manager'))
		{
			$('.enq-btn').hide();
		}
		else
		{
			$('.enq-btn').show();
		}

        $('.search-dv').on("click", function(e) {
          $('.search_div').css('z-index','9');
		  $('.search_div').css('opacity','1');
        });
		
		$('.cls-btn').on("click", function(e) {
			$('.search_div').css('z-index','-2');
		  	$('.search_div').css('opacity','0');
		});

	
		// lang chnge div js  

		$('.arabic').on("click", function() {
			$(".lang-drop span").fadeOut(function () {
				$(".lang-drop span").text(($(".lang-drop span").text() == 'Ar') ? 'Ar' : 'Ar').fadeIn();
			})
		});

		$('.icon').on("click", function() {
			$('#myLinks').toggle('hidden');
		});


		// careers menu on click active js  

		if ($( ".career1, .career2 , .career3" ).hasClass('active')) {
			$( ".careers-btn" ).addClass( 'active');
		} else {
		  $( ".careers-btn" ).removeClass( 'active');
		}


		///////////// menu on click active js  

		// About-inside
		$('.mega_nav_tabs li a').each(function() {
			if ($(this).attr('href') == pathname) {
				$(this).addClass('active');
			}
		});

		// About-active
		if((url == '/about') || (url=='/about#our-beliefs') || (url=='/about#awards') || (url=='/about#why-capricorn') || (url=='/about/our-network'))
		{
			$('.abt-us').addClass("active");
		}

		// Blog-active
		else if((url == '/blog')){
			$('.nwsblog-btn').addClass("active");
		}

		// Career-active
		else if((url == '/careers/life-at-capricorn') || (url=='/careers/application-form') || (url=='/careers/current-openings') || (url=='/careers/current-openings/customer-service-executive') || (url=='/careers/current-openings/accounts-assistant-executive') || (url =='/careers/current-openings/assistant-manager'))
		{
			$('.careers-btn').addClass("active");
		}

		// Contact-active
		else if((url == '/contact')){
			$('.contct').addClass("active");
		}
		
		else
		{
			$('.enq-btn').removeClass("active");
		}


		// Career-inside active
			if((url == '/careers/life-at-capricorn'))
			{
				$('.career1').addClass("active");
			}

			if((url == '/careers/application-form'))
			{
				$('.career2').addClass("active");
			}
		
			if((url == '/careers/current-openings') || (url=='/careers/current-openings/customer-service-executive') || (url=='/careers/current-openings/accounts-assistant-executive') || (url=='/careers/current-openings/assistant-manager'))
			{
				$('.career3').addClass("active");
			}

		// Service main or service inside link add class
			if ($('.gb-srvc').is(':visible')){
				$(".megamenu").fadeOut(function () {
					$('.services-btn').addClass("active");
				})
			}		
		 
		// global-freight link add class	
			if((url == '/services/global-freight-forwarding-solutions'))
				{
					$('.srvc5, .srvc-insd2, .srvc-insd3, .srvc-insd4, .srvc-insd5').removeClass("active");	
					$('.srvc1, .srvc-insd1').addClass("active");
				}
		
		// integrated link add class	
			if((url == '/services/integrated-supply-chain-solutions'))
				{
					$('.srvc1, .srvc-insd1, .srvc-insd3, .srvc-insd4, .srvc-insd5').removeClass("active");
					$('.srvc2, .srvc-insd2').addClass("active");
				}
				
		// custom-brokerage link add class	
			if((url == '/services/custom-brokerage'))
				{
					$('.srvc2, .srvc-insd1, .srvc-insd2, .srvc-insd4, .srvc-insd5').removeClass("active");	
					$('.srvc3, .srvc-insd3').addClass("active");
				}
				
		// industrial link add class	
			if((url == '/services/industrial-and-infactory-solutions/'))
				{
					$('.srvc3, .srvc-insd1, .srvc-insd2, .srvc-insd3, .srvc-insd5').removeClass("active");	
					$('.srvc4, .srvc-insd4').addClass("active");
				}
				
		// value link add class	
			if((url == '/services/value-added-solutions'))
				{
					$('.srvc4, .srvc-insd1, .srvc-insd2, .srvc-insd3, .srvc-insd4').removeClass("active");
					$('.srvc5, .srvc-insd5').addClass("active");
				}
				
		// industrial inside add class active
			if((url == '/services/industrial-and-infactory-solutions/automotive') || (url =='/services/industrial-and-infactory-solutions/engineering') || (url =='/services/industrial-and-infactory-solutions/fmcg') || (url =='/services/industrial-and-infactory-solutions/pharma') || (url =='/services/industrial-and-infactory-solutions/telecom'))	
				{
					$('.srvc3, .srvc-insd1, .srvc-insd2, .srvc-insd3, .srvc-insd5').removeClass("active");
					$('.srvc4, .srvc-insd4').addClass("active");
				}
				
			$('.srvc-insd4 a').each(function() {
				if ($(this).attr('href') == pathname) {
					$(this).addClass('active');
				}
			});	
			
			$('.drp_menu a a').each(function() {
				if ($(this).attr('href') == pathname) {
					$(this).addClass('active');
				}
			});

			
			
			
		

		// services menu on click active js END


		// blog inside menu on click active js 

		if ($('.blog-inside-page').is(':visible')){
			$('.nwsblog-btn').addClass("active");
	   	}
	


		// mbl dropdown click js

		$('.abt-dropdownmenu').on("click", function(e) {
			$('.abt-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.abt-link').toggleClass('txt-color');	
		});

		$('.srvcmain-dropdownmenu').on("click", function(e) {
			$('.srvcsmain-drop-menu').toggle();
			$(this).toggleClass('rotate');	
			$('.srvc-link').toggleClass('txt-color');
		});
		
		$('.glbl-dropdownmenu').on("click", function(e) {
			$('.glbl-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.glbl-link').toggleClass('txt-color');	
		});

		$('.intg-dropdownmenu').on("click", function(e) {
			$('.intg-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.intg-link').toggleClass('txt-color');	
		});	

		$('.cstmb-dropdownmenu').on("click", function(e) {
			$('.cstmb-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.cstmb-link').toggleClass('txt-color');	
		});

		$('.inds-dropdownmenu').on("click", function(e) {
			$('.inds-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.inds-link').toggleClass('txt-color');	
		});

		$('.career-dropdownmenu').on("click", function(e) {
			$('.career-drop-menu').toggle();
			$(this).toggleClass('rotate');
			$('.career-link').toggleClass('txt-color');	
		});	


		
		// anchor tag click dropdown js
		
		$('#menu a').on("click", function(e) {				
            $("#menu").removeClass("trnsfrm");
			$('.enq-btn').removeClass('z-indx');	
			// $(".mob-menu span").addClass("revrs-span");			
        });	



		$('.menu-btn').on("click", function(e) {
			$('.enq-btn').addClass('z-indx');	
		});	
		
		$('.menu-btn').on("click", function(e) {
			$('#menu').addClass('trnsfrm');	
		});	

		$('.close-btn').on("click", function(e) {
			$('#menu').removeClass('trnsfrm');	
		});


		

    })

	
	return (
			<>
			<nav className="navbar navbar-expand-lg navbar-dark bg-primary custm_header desk-menu" id='desk-menu'>
				<div className="container-fluid">
					
					<a className="nav-link navbar-brand logo" href="/"><img src={require('../../assets/img/logo.png')}></img></a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="main_nav">
						<div className='top-bar' id='navbar'>

							{/* <div className="dropdown lang-dv">
								<a className="dropdown-toggle lang-drop show" href="#" data-bs-toggle="dropdown" data-bs-hover="dropdown" id="icon-drp" aria-expanded="false"><span>EN</span><img className='drp_icon lang-icon' src={require('../../assets/img/lang_icon.png')}></img></a>

								<ul className="dropdown-menu dropdown-menu-end lang-menu" aria-labelledby="icon-drp">
									<li className=""><a className="dropdown-item arabic" href="/arabic/home"><img src={require('../../assets/img/arabic.png')}></img>Ar</a></li>

									<li className=""><a className="dropdown-item english" href="/"><img src={require('../../assets/img/english.jpg')}></img>En</a></li>
								</ul>
							</div> */}

							<div className='search-dv'>
								<div className='srch-box'>
									<img src={require('../../assets/img/srch-wht-vector.png')} ></img>
									<a  className='txt'>Search...</a>
								</div>
								
							</div>
							{/* <div className='phone-dv'>
								<a href='tel:+912240706000' className='txt'><img src={require('../../assets/img/phone-vector.png')} ></img>+91 22 4070 6000</a>
							</div> */}
							<div className='mail-dv'>
								{/* <img src={require('../../assets/img/email-vector.png')} ></img> */}
								<a href='mailto:sales_enquiry@capricornlogistics.com' className='txt'><img src={require('../../assets/img/email-vector.png')} ></img>sales_enquiry@capricornlogistics.com</a>
							</div>
							
						</div>
						<ul className="navbar-nav ms-auto">

						  <li className="nav-item dropdown has-megamenu hm1">
								
							<a className="nav-link  dropdown-toggle main-menu-lnk abt-us disabled" href="#" data-bs-toggle="dropdown" data-bs-hover="dropdown"> About us <img className='drp_icon' src={require('../../assets/img/lang_icon.png')}></img> </a>
							 	
								<div className="dropdown-menu megamenu" role="menu">
									<div className="row g-3">
										<div className="col-lg-4 col-6 mega-cols">
											<div className="col-megamenu">
												<h2 className="title pg-ttl">About us</h2>
												<p>Since 2001, our customers have experienced only the finest logistics services. The unshakeable trust of our customers comes from the promises we've delivered, every time. </p>
											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols mdl-mega-col">
											<div className="col-megamenu">
										
												<ul className="nav nav-pills mega_nav_tabs">
													<li className=""><a className="tab_menu abt_pg" href="/about">About Company</a></li>													
													{/* <li className=""><a className="tab_menu abt_menu1" href="/about#our-journey" >Our Journey</a></li> */}
													<li className=""><a className="tab_menu abt_menu2" href="/about#our-beliefs" >Mission, Vision & Values</a></li>
													{/* <li className=""><a className="tab_menu abt_menu3" href="/about#our-values">Our Values</a></li> */}
													<li className=""><a className="tab_menu abt_menu4" href="/about#awards" >Certification & Accreditation</a></li>
													<li className=""><a className="tab_menu abt_menu5" href="/about#why-capricorn">Why Capricorn</a></li>
												</ul>
												<li className=""><a className="srvcs-btn" href="/about/our-network" >Our Network</a></li>
											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols">
											<div className="col-megamenu">
												
												<div className="tab-content well">												
												<img className='drp_icon' src={require('../../assets/img/sub-menu/about-us/capricorn_logistics_about_us_sub_menu.jpg')}></img>
												</div>
											</div>   
										</div>    
										
									</div> 
								</div> 
								 
							</li>

							<li className="nav-item dropdown has-megamenu srvc-lnks">
								
								<a className="nav-link dropdown-toggle main-menu-lnk services-btn  disabled" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside" data-hover="dropdown">Services <img className='drp_icon' src={require('../../assets/img/lang_icon.png')}></img> </a>
							 	
								<div className="dropdown-menu megamenu" role="menu">
									<div className="row g-3">
										<div className="col-lg-4 col-6 mega-cols">
											<div className="col-megamenu">
												<h2 className="title pg-ttl">Services</h2>
												<p>Our redefining and innovative stances in the logistic globe have exponentially improved the core of how we drive the supply chain solutions forward.</p>
											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols mdl-mega-col srvc-clm2">
											<div className="col-megamenu">
										
												<ul className="nav nav-pills mega_nav_tabs">
													<li className="drp_menu"><a href="#global-freight-forwarding-solutions" data-toggle="tab"><a href="/services/global-freight-forwarding-solutions" className="tab_menu srvc1">Global Freight Forwarding Solutions</a></a></li>

													<li className="drp_menu"><a href="#integrated-supply-chain-solutions" data-toggle="tab"><a href="/services/integrated-supply-chain-solutions" className="tab_menu srvc2">Integrated Supply Chain Solutions</a></a></li>
													
													<li className="drp_menu"><a href="#custom-brokerage" data-toggle="tab"><a href="/services/custom-brokerage" className="tab_menu srvc3">Custom Brokerage</a></a></li>

													<li className="drp_menu"><a href="#industrial-Infactory-solutions" data-toggle="tab"><a href="/services/industrial-and-infactory-solutions/" className="tab_menu srvc4">Industrial & Infactory Solutions</a></a></li>

													<li className="drp_menu"><a href="#value-added-solutions" data-toggle="tab"><a href="/services/value-added-solutions" className="tab_menu srvc5">Value Added Solutions</a></a></li>
												</ul>

												<a href='/services' className='srvcs-btn srvcs-color'>Services Overview</a>

											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols srvc-clm3">
											<div className="col-megamenu">
												
												<div className="tab-content well">

													{/* Global Freight Forwarding Solutions */}
													<div className="tab-pane srvc-insd1 active" id="global-freight-forwarding-solutions" >
														<a smooth href="/services/global-freight-forwarding-solutions#air_freight" className='mega-menu-link airFreight'>
															<img src={require('../../assets/img/sub-menu/global-freight-forwarding-solutions/capricorn_logistics_air_freight_sub_menu.jpg')}></img>
															<h3>Air Freight</h3>	
														</a>
														<a smooth href='/services/global-freight-forwarding-solutions#ocean_freight' className='mega-menu-link oceanFreight'>
															<img src={require('../../assets/img/sub-menu/global-freight-forwarding-solutions/capricorn_logistics_sea_freight_forwarding_sub_menu_sub_menu.jpg')}></img>
															<h3>Ocean Freight</h3>	
														</a>
														<a smooth href='/services/global-freight-forwarding-solutions#land_freight' className='mega-menu-link landFreight'>
															<img src={require('../../assets/img/sub-menu/global-freight-forwarding-solutions/capricorn_logistics_land_freight_sub_menu_sub_menu.jpg')}></img>
															<h3>Land Freight</h3>	
														</a>
													</div>

													{/* Integrated Supply Chain Solutions */}
													<div className="tab-pane srvc-insd2" id="integrated-supply-chain-solutions">
														<a smooth href='/services/integrated-supply-chain-solutions/#warehousing' className='mega-menu-link Warehousing'>
															<img src={require('../../assets/img/sub-menu/integrated-supply-chain-solutions/capricorn_logistics_warehousing_sub_menu.jpg')}></img>
															<h3>Warehousing</h3>	
														</a>
														<a smooth href='/services/integrated-supply-chain-solutions/#transportation' className='mega-menu-link Transportation'>
															<img src={require('../../assets/img/sub-menu/integrated-supply-chain-solutions/capricorn_logistics_transportation_sub_menu.jpg')}></img>
															<h3>Transportation</h3>	
														</a>
														<a smooth href='/services/integrated-supply-chain-solutions/#express' className='mega-menu-link Express'>
															<img src={require('../../assets/img/sub-menu/integrated-supply-chain-solutions/capricorn_logistics_express_sub_menu.jpg')}></img>
															<h3>Express</h3>	
														</a>
													</div>

													{/* Custom Brokerage */}
													<div className="tab-pane srvc-insd3" id="custom-brokerage">
														<a href='/services/custom-brokerage' className='mega-menu-link Compliance'>
															<img src={require('../../assets/img/sub-menu/compliance/capricorn_logistics_compliance_sub_menu.jpg')}></img>
															<h3>Compliance</h3>	
														</a>
													</div>

													{/* Industrial & Infactory Solutions */}
													<div className="tab-pane srvc-insd4" id="industrial-Infactory-solutions">
														
														<a href='/services/industrial-and-infactory-solutions/automotive' className='mega-menu-link Automotive'>
															<img src={require('../../assets/img/sub-menu/industrial-and-infactory-solutions/capricorn_logistics_automotive_sub_menu.jpg')}></img>
															<h3>Automotive</h3>	
														</a>
														<a href='/services/industrial-and-infactory-solutions/engineering' className='mega-menu-link Engineering'>
															<img src={require('../../assets/img/sub-menu/industrial-and-infactory-solutions/capricorn_logistics_engineering_sub_menu.jpg')}></img>
															<h3>Engineering</h3>	
														</a>
														<a href='/services/industrial-and-infactory-solutions/fmcg' className='mega-menu-link FMCG'>
															<img src={require('../../assets/img/sub-menu/industrial-and-infactory-solutions/capricorn_logistics_fmcg_sub_menu.jpg')}></img>
															<h3>FMCG</h3>	
														</a>
														<a href='/services/industrial-and-infactory-solutions/pharma' className='mega-menu-link Pharma'>
															<img src={require('../../assets/img/sub-menu/industrial-and-infactory-solutions/capricorn_logistics_pharma_sub_menu.jpg')}></img>
															<h3>Pharma</h3>	
														</a>
														<a href='/services/industrial-and-infactory-solutions/telecom' className='mega-menu-link Telecom'>
															<img src={require('../../assets/img/sub-menu/industrial-and-infactory-solutions/capricorn_logistics_telecom_sub_menu.jpg')}></img>
															<h3>Telecom</h3>	
														</a>													
														
													</div>

													{/* Value Added Solutions */}
													<div className="tab-pane srvc-insd5" id="value-added-solutions">
														
														<a href='/services/value-added-solutions' className='mega-menu-link ValueAdded'>
															<img src={require('../../assets/img/sub-menu/value-added-servies/capricorn_logistics_value_added_services_sub_menu.jpg')}></img>
															<h3>Value Added Solutions</h3>	
														</a>
														
													</div>

													

												</div>
											</div>   
										</div>    
										
									</div> 
								</div> 
								 
							</li>						
		

							{/* <li className="nav-item"><a className="nav-link main-menu-lnk nwsblog-btn" href="/blog"> News & blog </a></li> */}
							
							<li className="nav-item dropdown has-megamenu">
								
							<a className="nav-link  dropdown-toggle main-menu-lnk disabled careers-btn" href="#" data-bs-toggle="dropdown" data-bs-hover="dropdown"> CAREERS <img className='drp_icon' src={require('../../assets/img/lang_icon.png')}></img> </a>
							 	
								<div className="dropdown-menu megamenu" role="menu">
									<div className="row g-3">
										<div className="col-lg-4 col-6 mega-cols">
											<div className="col-megamenu">
												<h2 className="title pg-ttl">CAREERS</h2>
												<p>Become a part of an experienced, spirited, and forward-thinking team. At Capricorn Logistics, we create a work environment you actually want to work in.</p>
											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols mdl-mega-col">
											<div className="col-megamenu">
										
												<ul className="nav nav-pills mega_nav_tabs">
													<li className=""><a className="tab_menu career1" href="/careers/life-at-capricorn" data-toggle="tab">Life At Capricorn</a></li>
													<li className=""><a className="tab_menu career2" href="/careers/application-form" data-toggle="tab">Application form</a></li>
													<li className=""><a className="tab_menu career3" href="/careers/current-openings" data-toggle="tab">Current Openings</a></li>
												</ul>

											</div>   
										</div> 
										<div className="col-lg-4 col-6 mega-cols">
											<div className="col-megamenu">
												
												<div className="tab-content well">												
												<img className='drp_icon' src={require('../../assets/img/sub-menu/life-at-capricorn/capricorn_logistics_life_at_capricorn_sub_menu_sub_menu.jpg')}></img>
												</div>
											</div>   
										</div>    
										
									</div> 
								</div> 
								 
							</li>


							<li className="nav-item"><a className="nav-link main-menu-lnk contct" href="/contact"> Contact us </a></li>

						</ul>
						
					</div>
					<a className='track_div' id='track_div' href='http://soft.vriddhilogitech.in:888/' target='blank'>Tracking</a>
				</div>
			</nav>




{/* ============ ============ ============ mobile view ============ ============ ============*/}


			<div className="mobile-menu">							
							
							<nav role="navigation" className='mob-nav'>
									<div className='mob-logo'>
										<a className="nav-link navbar-brand logo" href="/"><img src={require('../../assets/img/logo.png')}></img></a>
									</div>
								<div id="menuToggle">

								<div className='search-dv'>
									<div className='srch-box'>
										<img src={require('../../assets/img/search_icon.svg').default} ></img>
										<a  className='txt'>Search...</a>
									</div>									
								</div>

									<div className='mob-menu'>
									<img className='menu-btn' src={require('../../assets/img/menu.svg').default}></img>
										{/* <input type="checkbox" />
												<span></span>
												<span></span>
												<span></span> */}
									
											<ul id="menu">

												<img className='close-btn' src={require('../../assets/img/close.svg').default}></img>
												<li className=""><a className="" href="/"> Home </a></li>

												<li className=""><a className="abt-link" href="/about"> About us </a><img className='abt-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

															<ul className='abt-drop-menu hide'>
																{/* <li className=""><a href="/about#ourjourney" className="anchr-tg">Our Journey</a></li> */}
																<li><a  href="/about#our-beliefs" className='anchr-tg'>Our Beliefs</a></li>
																<li><a   href='/about#our-values' className='anchr-tg'>Our Values</a></li>
																<li><a   href='/about#awards' className='anchr-tg'>Awards</a></li>
																<li><a   href='/about#why-capricorn' className='anchr-tg'>Why Capricorn</a></li>
																<li><a   href='/about/our-network' className=' '>Our Network</a></li>
															</ul>

												<li className=""><a className="srvc-link" href="/services"> Services </a><img className='srvcmain-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>
													
													<ul className='srvcsmain-drop-menu hide'>

														<li className=""><a href="/services/global-freight-forwarding-solutions" className="glbl-link">Global Freight Forwarding Solutions </a><img className='glbl-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

															<ul className='glbl-drop-menu hide'>
																<li><a  href="/services/global-freight-forwarding-solutions#air_freight" className='anchr-tg'>Air Freight </a></li>
																<li><a   href='/services/global-freight-forwarding-solutions#ocean_freight' className='anchr-tg'>Ocean Freight</a></li>
																<li><a   href='/services/global-freight-forwarding-solutions#land_freight' className='anchr-tg'>Land Freight</a></li>
															</ul>

														<li><a  href="/services/integrated-supply-chain-solutions" className='intg-link'>Integrated Supply Chain Solutions </a><img className='intg-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

															<ul className='intg-drop-menu hide'>
																<li className=""><a href="/services/integrated-supply-chain-solutions/#warehousing" className="anchr-tg">Warehousing</a></li>
																<li><a  href="/services/integrated-supply-chain-solutions/#transportation" className='anchr-tg'>Transportation </a></li>
																<li><a   href='/services/integrated-supply-chain-solutions/#express' className='anchr-tg'>Express</a></li>
															</ul>

														<li><a   href='/services/custom-brokerage' className='cstmb-link'>Custom Brokerage</a><img className='cstmb-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

															<ul className='cstmb-drop-menu hide'>
																<li className=""><a href="/services/custom-brokerage" className="">Compliance</a></li>
															</ul>

														<li><a   href='/services/industrial-and-infactory-solutions' className='inds-link'>Industrial & Infactory Solutions</a><img className='inds-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

															<ul className='inds-drop-menu hide'>
																<li className=""><a href="/services/industrial-and-infactory-solutions/automotive" className="">Automotive</a></li>
																<li className=""><a href="/services/industrial-and-infactory-solutions/engineering" className="">Engineering</a></li>			
																<li className=""><a href="/services/industrial-and-infactory-solutions/fmcg" className="">FMCG</a></li>
																<li className=""><a href="/services/industrial-and-infactory-solutions/pharma" className="">Pharma</a></li>			
																<li className=""><a href="/services/industrial-and-infactory-solutions/telecom" className="">Telecom</a></li>			
															</ul>

														<li><a   href='/services/value-added-solutions' className=' '>Value Added Solutions</a></li>													
													</ul>
											
												{/* <li className=""><a href="/blog" className="">News & Blog</a></li>	 */}
												
												<li className=""><a className="career-link" href=""> Careers </a><img className='career-dropdownmenu svg-icon' src={require('../../assets/img/angle-down-solid.svg').default}></img></li>

														<ul className='career-drop-menu hide'>
																<li className=""><a href="/careers/life-at-capricorn" className="">Life At Capricorn</a></li>
																<li><a  href="/careers/application-form" className=' '>Application form </a></li>
																<li><a   href='/careers/current-openings' className=' '>Current Openings</a></li>
														</ul>

												<li className=""><a className="" href="/contact"> Contact us </a></li>
												<a href="http://soft.vriddhilogitech.in:888/" target="blank">Tracking </a>
											</ul>
									</div>
								</div>
							</nav>									
			</div>
			
			</>
  )
}
