
import React from 'react'

import Header from '../../components/global/header';
import Services_main from '../../components/sections/services_main';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';
import Industrial_infactory_sec from '../../components/sections/services/industrial_infactory_sec';

export default function Industrial_infactory_page() {

  return (
    <>
      
      <div className='pg-layout gb-srvc'>
          <Header/>
          {/* <Services_main /> */}
          <Industrial_infactory_sec />
          <Connect_us />
          <Footer/>
      </div>
     
    </>
  )
}
