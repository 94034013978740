import React from 'react'
import '../../assets/css/awards.css'; 
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/cap_fun_slider.css'; 

export default function Awards() {

    const awrd_sld = {
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
            
    };

  return (
    <>
        <div className='awards_main' id='awards'>
            <div className='cstm-container'>
                <div className='row awrds_row'>

                    <div className='col-lg-12 awrd-hd-sec'>
                        <h2 className='awrd-ttl'>Certification & Accreditation</h2>
                        <p className='awrd-sub-ttl'>Trusted And Honored by</p>
                    </div>
                    

                    <Slider {...awrd_sld} className='awrds-sld'>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_iata_logo.png')}></img>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_ffi_logo.png')}></img>
                        {/* <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_dun_and_bradstreet_logo.png')}></img> */}
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_fiata_logo.png')}></img>                        
                        {/* <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_lognet_global_logo.png')}></img> */}
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_iata_logo.png')}></img>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_ffi_logo.png')}></img>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_aeo_global_logo.png')}></img>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_iso_logo.png')}></img>
                        <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_fiata_logo.png')}></img>                        
                        {/* <img className='awrd__img' src={require('../../assets/img/about-us/awards-and-accrediations/capricorn_logistics_lognet_global_logo.png')}></img> */}
                        
                    </Slider>

                </div>
            </div>
        </div>     
    </>
  )
}
