import React from 'react'
import '../../assets/css/team.css'; 

export default function Team() {
  return (
    <>
      <div className='team-main sec-pd-tb'>
          <div className='cstm-container'>
              <div className='row team-row'>
                  <div className='col-lg-12'>
                        <div  className='sec-name-dv'>
                            <span className='dot'></span>
                            <h3 className='sec-name-ttl'>The Team</h3>
                        </div>
                        <p className='sec-ttl'>Meet our team of experts in<br/> logistics and supplying</p>
                  </div>
                  <div className='col-lg-4'>

                      <div className='team-bx'>
                        <div className='tm-img-bx'>
                            <img src={require('../../assets/img/team.png')}></img>
                        </div>
                        <div className='team-content'>
                            <h2 className='tm-nm'>Mr.Sheetal S.Shetty</h2>
                            <p className='tm-des'>Group Managing Director</p>
                            <a className='bio-btn' href="#">Read Bio {'>'} </a>
                        </div>
                      </div>

                  </div>
                  <div className='col-lg-4'>

                    <div className='team-bx '>
                        <div className='tm-img-bx nw-bg'>
                            <img src={require('../../assets/img/team.png')}></img>
                        </div>
                        <div className='team-content'>
                            <h2 className='tm-nm'>Mr.Sheetal S.Shetty</h2>
                            <p className='tm-des'>Group Managing Director</p>
                            <a className='bio-btn' href="#">Read Bio {'>'} </a>
                        </div>
                    </div>

                  </div>
                  <div className='col-lg-4'>

                    <div className='team-bx'>
                        <div className='tm-img-bx'>
                            <img src={require('../../assets/img/team.png')}></img>
                        </div>
                        <div className='team-content'>
                            <h2 className='tm-nm'>Mr.Sheetal S.Shetty</h2>
                            <p className='tm-des'>Group Managing Director</p>
                            <a className='bio-btn' href="#">Read Bio {'>'} </a>
                        </div>
                    </div>

                  </div>
              </div>
          </div>
      </div>
    </>
  )
}
