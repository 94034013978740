import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Compliance() {
  return (
    < >
      <div className='vlus_main'>
            <div className='mbl-img'>
                      <img className='vlue_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_compliance.png')}></img>
            </div> 
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                    <h2 className='air-frgt-ttl'>Compliance</h2>
                    <p className='air-frgt-para'>
                    We provide complete assistance & services of Pre Import and Export consultation till the delivery of valuable shipment.
                    </p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/customs-clearance/compliance/capricorn_logistics_compliance.jpg')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
