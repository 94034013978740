import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/case_study.css'; 
import '../../assets/css/news.css'; 
import useFetch from "react-fetch-hook";

export default function News() {

    const news = {
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        centerMode: true,
        centerPadding: '40px',
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 3,
                
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
            
    };

    // useEffect(() => {

    //     $(document).on("click",".add-btn", function(e) {
    //       $(this).parent('.nw-prt1').next('.nw-prt2').child('.new_para').slideToggle();
    //     });
    // })

    const { isLoading, data, error } = useFetch(
        "https://wordpress-819107-2935324.cloudwaysapps.com/wp-json/wp/v2/news"
    );

  return (
    <>
      <div className='new-section sec-pd-tb' id='news-sec'>
          <div className=''>
              <div className='row'>
                    <div className='col-lg-12 no-pdng'>
                        <div className='row no-margin'>
                            <div className='col-lg-9 news-hd-col1 no-pdng'>
                                <div className='whts-happn-txt'>
                                    <span className='dot'></span>
                                    <h3 className='sec-name-ttl'>What's happening</h3>
                                </div>
                                <h2 className='news-ttl'>News & Blog</h2>
                            </div>
                            <div className='col-lg-3 news-hd-col2 no-pdng' id='desk-btn'>
                              <a className='view-btn' href="/blog">view all 
                                <div className='view-red-btn'>
                                    {/* <img src={require('../../assets/img/srvc-btn.png')}></img> */}
                                    <div className="icon-container">
                                        <div className="icon icon--left">
                                            <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                        </div>
                                        <div class="icon icon--right">
                                            <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                        </div>
                                    </div>
                                </div></a>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-12 no-pdng'>
                        <Slider {...news} className='news-sld'>

                        {data && data.map(({ id, title , acf , categories, slug }) => (
                            <div className='news-item' key={id}>
                                <div className='nw-prt1'>
                                    <div className='nws-dates'>
                                        <p>{acf.media_category}</p>
                                        <p className='dt'><b>.</b></p>
                                        <p>{acf.date}</p>
                                    </div>
                                    <div className='add-btn'>
                                        <span className='frst-spn'></span>
                                        <span></span>
                                    </div>
                                </div>

                                <div className='nw-prt2'>
                                <h2>{title.rendered}</h2>       
                                </div>

                                <div className='nw-prt3'>
                                    <img src={acf.image} />         
                                    <a className='blg-view-btn' href={"/news/" + slug}>Read more </a>                
                                </div>
                            </div>
                        ))}      


                        </Slider>                        
                    </div>
              </div>
          </div>
      </div>
                <div className='col-lg-2 news-hd-col2 no-pdng' id='mobl-btn'>
                                            <a className='view-btn' href="/blog">view all </a>
                                            <span className='view-red-btn'>
                                                <img src={require('../../assets/img/srvc-btn.png')}></img>
                                            </span>
                </div>
    </>
  )
}
