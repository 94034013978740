import React from 'react'
import Header from '../../components/global/header';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import BLog_inside from '../../components/sections/blog_inside';

export default function News_inside_page(props) {



  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <BLog_inside />
          <h1>  Now showing Blogs here</h1>
          
          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}
