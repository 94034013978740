
import React from 'react'
import Header from '../components/global/header';
import About_spotlight from '../components/spotlight/about_spotlight';
import About_sec1 from '../components/sections/about_sec1';
import Our_beliefs from '../components/sections/our_beliefs';
import Our_values from '../components/sections/our_values';
import Team from '../components/sections/team';
import Why_capricorn from '../components/sections/why_capricorn';
import Awards from '../components/sections/awards';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Journey_tabs_mobl from '../components/sections/home_jrney_tabs_mobile';
import Our_values_mob from '../components/sections/our_values_mob';
import '../assets/css/home_mobil.css'; 

export default function About() {


  return (
    <>
      <div className='pg-layout' id='about-pag'>
          <Header/>
          <About_spotlight />
          <About_sec1/>
          <Journey_tabs_mobl /> 
          <Our_beliefs />
          <Our_values />
          <Our_values_mob />
          <Awards/>
          <Team/>
          <Why_capricorn/>          
          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}
