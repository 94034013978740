import React from 'react'
import Header from '../../components/global/header';
import Our_network_spotlight from '../../components/spotlight/our_network/our_network_spotlight';
import Our_network_map from '../../components/sections/our_network_map';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';



export default function Current_openings() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Our_network_spotlight/>
          <Our_network_map/> 
          <Connect_us /> 
          <Footer/>

      </div>
    </>
  )
}
