import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Express() {
  return (
    <>
      <div className='vlus_main' id='express'>
      <div className='col-lg-6 vlue-col2 air-frght-col2 mbl-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logistics_express.png')}></img>
              </div>
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                  <h2 className='air-frgt-ttl'>Express</h2>
                  <p className='air-frgt-para'>
                  At Capricorn Express, we are committed to delivering the right product at the right place at the right time. Capricorn Express offers a complete range of express distribution services and transportation networks across countries that allow you to choose as per your business requirement.
                  </p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logistics_express.png')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
