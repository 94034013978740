import React from 'react'
import Header from '../components/global/header';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Terms_spot from '../components/spotlight/terms_spotlight';
import Terms_sec from '../components/sections/terms_sec'


export default function Terms_page() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Terms_spot />
          <Terms_sec />         
          <Global_reach/>
          <Footer/>
      </div>
    </>
  )
}
