import React from 'react'
import '../../../assets/css/ocean_freight.css'; 

export default function Ocean_freight() {
  return (
    <>
      <div className='vlus_main' id='ocean_freight'>
          <div className='row vlus_row'>

                <div className='col-lg-6 vlue-col2 ocen-frght-col1'>
                    <img className='vlue_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/sea-freight-forwarding/capricorn_logistics_sea_freight_forwarding.png')}></img>
                </div> 
                <div className='col-lg-6 vlue-col1 ocen-frght-col2'>
                    <div className='hd-dv'>
                        <h2 className='air-frgt-ttl'>Ocean Freight Forwarding</h2>
                        <p className='air-frgt-para'>
                        Our long-standing relationships with the global, regional shipping lines and NVOCC enable us to provide our customers with compelling customised and cost-effective solutions.
                        </p>
                    </div>
                </div>
               
          </div>
      </div>
    </>
  )
}
