import React from 'react'
import Slider from 'react-slick';
import { useState, useRef ,useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/about_sec.css'; 
import Journey_slider from './journey_slider';
import {NavLink} from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import '../../assets/css/arabic.css'

export default function Arabic_about() {
    gsap.registerPlugin(ScrollTrigger); 

    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {   
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);

    const abt_settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500
            
    };
  return (
    <>
        <div className='about-main pdng-tb arabic-pg'>
        
            <img ref={slide_up} id='slide_up' className='abt-bg-vct' src={require('../../assets/img/about-vct.png')}></img>
            {/* Part 1 */}
            <div className='cstm-container '>
            <div className='row'>
                <div className='col-lg-7 abt-col1' ref={text_fade} id='fade'>
                    <div  className='sec-name-dv'>
                        <span className='dot'></span>
                        <h3 className='sec-name-ttl'>معلومات عنا</h3>
                    </div>
                    <p className='sec-ttl'>تجعل الخدمات اللوجستية الشاملة لشركة سلسلة التوريد الخاصة بك جاهزة للمستقبل بالابتكار وتسبق منافسيك.</p>

                    <p className='dcrb'>تأسست Capricorn Logistics في عام 2001 ، وبدأت عملياتها برؤية لتقديم أفضل الخدمات اللوجستية في فئتها في الهند. على مر السنين ، كان شغفنا بأن نكون الشريك اللوجستي الاستراتيجي المفضل مدعومًا بأحدث التقنيات المتكاملة لدينا. لقد ساعدنا نهج العميل أولاً في اكتساب ثقة العديد من الشركات الكبيرة والشركات الصغيرة والمتوسطة عبر المناطق الجغرافية. تنتشر شبكتنا حاليًا عبر 27 موقعًا في الهند و 10 مواقع في الاقتصادات الرئيسية مثل الولايات المتحدة الأمريكية والإمارات العربية المتحدة والصين. تتوافق فرقنا المتخصصة في جميع أنحاء العالم مع رؤيتنا القوية لتوفير التحسين المستمر لسلاسل التوريد العالمية.</p>
                    <p className='dcrb'>عملك يجعلك فريدة من نوعها. هذا هو السبب في أن حلولنا مصممة بشكل فريد لتلبية كل متطلبات سلسلة التوريد الخاصة بك. ينصب تركيزنا على استخدام أحدث التقنيات لتحسين سلسلة التوريد الخاصة بك وفتح مجالات نمو جديدة.</p>
                    <p className='dcrb'>تمتد إمكانات خدماتنا من الشحن الجوي ، والشحن البحري ، والنقل السطحي ، والخدمة السريعة من الباب إلى الباب ، والوساطة الجمركية ، و 3PL ، و 4PL و 5PL إلى الحلول الصناعية. مع مجموعة من الخدمات للاختيار من بينها ، نضمن وصول شحناتك إليك بدقة وفي الوقت المحدد وفي كل مرة.</p>  
                    <a className='rd-btn explore-btn' href="/about">اكتشف برج الجدي</a>
                </div>
                <div className='col-lg-5 abt-col2' ref={text_fade} id='fade'>
                    <img  className='world-img' src={require('../../assets/img/world.png')}></img>
                    <Slider {...abt_settings} className='about-sld'>
                        <div className='mission-sld abt-sld'>
                           <h2>مهمتنا</h2>
                           <p><ul class="mission-list"><li>ضمان إسعاد العملاء</li><li>اجعل الجدي صاحب العمل المفضل</li><li>أن تكون مدفوعة بالجودة وموجهة نحو العملية</li><li>احصل على لمسة إنسانية لعملنا</li><li>أداء ثابت لتحقيق نمو مربح</li></ul></p>
                        </div>
                        <div className='vision-sld abt-sld'>
                           <h2>رؤيتنا</h2>
                           <p>أن نكون شركة لوجستية عالمية تقدم حلولاً متكاملة حقًا لسلسلة التوريد من خلال شبكتنا الخاصة مع التركيز على العملاء ومشاركة الموظفين والتكنولوجيا والابتكار والنهج الصديق للبيئة.</p>
                        </div>
                    </Slider>
                </div>
            </div>
            </div> 

            {/* Part 2 -- Our Journey Of Growth */}
            <Journey_slider/>
        </div>
    </>
  )
}
