import React from 'react'
import Header from '../../components/global/header';
import Custom_brokerage_spotlight from '../../components/spotlight/services/custom_brokerage_spotlight';
import Compliance from '../../components/sections/services/compliance';
import Compliance_services from '../../components/sections/services/compliance_services';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';

export default function custom_brokerage_page() {
  return (
    <>
        <div className='pg-layout srvcs-text'>
          <Header/>
          <Custom_brokerage_spotlight/>
          <Compliance/>
          <Compliance_services/>
          <Connect_us />
          <Footer/>
        </div>
    </>
  )
}
