import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Warehousing_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>
                      <h3 className='air_srvc_ttl'>State-of-the-art global warehousing solutions</h3>
                      <p className='air_srvc_para'>
                      While our local network of warehouses caters to the customers in all Metros and remote cities like Brynihat, Siliguri, and Raipur, our international network of warehouses in Hong Kong, Chicago, and UAE cater to our overseas customers. We are steadily extending our network and establishing our footprints in the Middle East, US, UK, South East, and other parts of the globe.
                      </p>
                      <p className='air_srvc_para'>
                      Capricorn has become one of the major logistic players offering top-notch SCM services to its valued clients. Our technology-driven comprehensive range of warehousing and fulfilment services enabled our clients to be more flexible, scalable, and future ready in the competition.
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_free_trade_warehousing.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Free Trade Warehousing</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics offers comprehensive Free Trade warehousing services to its global customers, including end-to-end service from port to customer destination, warehousing, distribution, dedicated customs compliance team, and value-added services.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_fulfilment_center.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Fulfilment Center</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics provides customized fulfilment solutions through innovative technology for real-time control and visibility over inventory, orders, shipments, and returns. We have multiple fulfilment centres across geographies that help you distribute your inventory from any place, thus reducing shipping costs, transit time, and better customer satisfaction.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship cross-drock-spacmange'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logisitcs_cross_docking.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Cross Docking</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn's cross-docking solution facilitates delivering products from a manufacturing plant directly to customers with little or no material handling. Thus, save you costs incurred on storage and other warehousing activities. Cross-docking will dramatically reduce your distribution cost and delivery time and is especially helpful with product consolidation. 
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_bonded_warehouses.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Bonded Warehouses</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn provides the most efficient and cost-effective custom bonded facilities to customers to store goods with the deferment of duties until the point of despatch. We offer best-in-class secure warehousing facilities backed by round-the-clock surveillance and strategically located near CFS facilities and inland depots.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_shared_warehouses.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Shared Warehouses</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics provides customized and shared warehousing space, MHE, systems, and workforce as per the dynamic requirements of the clients. Thus, the cost gets shared among customers accordingly. We also provide shared warehousing solutions for both forward and reverse logistics.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_dedicated_warehouse_solutions.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Dedicated Warehouse Solutions</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics' dedicated warehousing solutions match your type of product, space, and capacity requirements. Whether it's components or consumer goods, high-bay or low-bay, automated or manual, we offer you solutions based on your particular business need. In addition, we design and deliver solutions based on your products' seasonal profiles, packing requirements, and volume requirements.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        
                        
                    



                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img>
      </div>
    </>
  )
}
