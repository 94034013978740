import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Air_freight() {
  return (
    < >
      <div className='vlus_main' id='air_freight'>
      <div className='mbl-img'>
                      <img className='vlue_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_air_frieght_forwarding_mob.png')}></img>
      </div>
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                    <h2 className='air-frgt-ttl'>Air Freight Forwarding</h2>
                    <p className='air-frgt-para'>
                    Our unmatched air freight service gives you the experience of connecting to any part of the world in the shortest time, eliminating the hurdles of dealing with carriers, customs, compliance, and documentation.
                    </p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_air_frieght_forwarding.png')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
