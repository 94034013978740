import React from 'react'
import Header from '../../components/global/header';
import Application_spot from '../../components/spotlight/careers/application_spot';
import Application_form_comp from '../../components/sections/application_form_comp';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';


export default function Application_form() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Application_spot/>
          <h2 className="apply-nw-txt">Apply Now</h2>
          <Application_form_comp/>
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
