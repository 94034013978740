import React from 'react'
import Header from '../../components/global/header';
import Application_spot from '../../components/spotlight/careers/application_spot';
import Application_form_comp from '../../components/sections/application_form_comp';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Bdm_dubai_comp from '../../components/sections/business_development_manger_dubai';


export default function  Business_development_manager_dubai_page() {

    
  return (
    <>
      <div className='pg-layout' id='custmr_serv_main'>
          <Header/>
          <Application_spot/>
          <Bdm_dubai_comp />
          <h2 className="apply-nw-txt">Apply Now</h2>
          <Application_form_comp career_fld="Business Development Manager"/>
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
