import React from 'react'
import { useState, useEffect } from 'react';
// import $ from "jquery";
import Sector_Slider from 'react-slick';
import '../../assets/css/sector_slider.css'; 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {a} from 'react-router-dom';

export default function Sector_slider() {

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });
//   handleBeforeChange = () => {
//     console.log("Before Change", "color: blue");
//   };

//   handleAfterChange = () => {
//     console.log("After Change", "color: red");
//   };

// beforeChange: this.handleBeforeChange,
// afterChange: this.handleAfterChange,

  const sctr_settings = {
    dots: false,
    swipe:false,
    fade:true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    
    asNavFor: '.slider-nav'
  };

   const sctr1_settings = {
    dots: false,
    infinite: true,
    vertical: true,
    slidesToShow: 5,
    asNavFor: '.slider-for',
    centerMode: true,
    focusOnSelect: true,
    centerPadding: '10px'
  };

  useEffect(() => {

    // $("#btn").on("click", function() {
    //       $('.para').toggle();
    //     });
  })

  return (
    <>
      <div className='row light-blue sector-main'>
          <div className='col-lg-12'>
            <div className='cstm-container'>

                <div className='row sec-sld-row'>
                    <div className='col-lg-10 sec-sld-col1'>

                        <div className='sec-hd'>
                            <span className='dot'></span>
                            <h3 className='sec-name-ttl'>Discover Our sectors</h3>
                            <h3 className='sec-subttl'>Our Alliance with<br />Key Sectors.</h3>
                        </div>
                        <Sector_Slider {...sctr_settings} className='sector-sld' asNavFor={nav2} ref={slider => (setSlider1(slider))}>
                           
                            {/* slide 1     */}
                            <div className='sctr-sld'>
                                <div className='sectr-sld-contnt'>
                                    
                                    <img className='sec-img' src={require('../../assets/img/homepage/discover-our-sectors/capricorn_logistics_automotive.png')}></img>
                                    <div className='sec-sub-cont'>
                                        <h2 className='sec-sld-ttl'>Automotive</h2>
                                        <p className='sec-para'>We are one of the leading logistics service providers with extensive expertise in providing end-to-end supply chain solutions specific to the automotive industry.</p>
                                        <a href='/services/industrial-and-infactory-solutions/automotive' className='sec-read-btn'>Read More</a>
                                    </div>
                                    <h4 className='sld-counter'><span>01</span>/05</h4>
                                </div>
                            </div>
                            {/* slide 2     */}
                            <div className='sctr-sld'>
                                <div className='sectr-sld-contnt'>
                                    
                                    <img className='sec-img' src={require('../../assets/img/homepage/discover-our-sectors/capricorn_logistics_engineering.png')}></img>
                                    <div className='sec-sub-cont'>
                                        <h2 className='sec-sld-ttl'>Engineering</h2>
                                        <p className='sec-para'>Our experience in the engineering sector has enabled us to gain sector-specific knowledge and design solutions that give you an edge over your competitors.</p>
                                        <a href='/services/industrial-and-infactory-solutions/engineering' className='sec-read-btn'>Read More</a>
                                    </div>
                                    <h4 className='sld-counter'><span>02</span>/05</h4>
                                </div>
                            </div>
                            {/* slide 3     */}
                            <div className='sctr-sld'>
                                <div className='sectr-sld-contnt'>
                                    
                                <img className='sec-img' src={require('../../assets/img/homepage/discover-our-sectors/capricorn_logistics_fmcg.png')}></img>
                                    <div className='sec-sub-cont'>
                                        <h2 className='sec-sld-ttl'>FMCG</h2>
                                        <p className='sec-para'>Our specialist teams are adaptable and agile in delivering supply chain solutions to meet the fast-paced, dynamic requirements of the FMCG sector.</p>
                                        <a href='/services/industrial-and-infactory-solutions/fmcg' className='sec-read-btn'>Read More</a>
                                    </div>
                                    <h4 className='sld-counter'><span>03</span>/05</h4>
                                </div>
                            </div>
                            {/* slide 4     */}
                            <div className='sctr-sld'>
                                <div className='sectr-sld-contnt'>
                                    
                                <img className='sec-img' src={require('../../assets/img/homepage/discover-our-sectors/capricorn_logistics_pharma.png')}></img>
                                    <div className='sec-sub-cont'>
                                        <h2 className='sec-sld-ttl'>Pharma</h2>
                                        <p className='sec-para'>We are fully equipped to manage all aspects of end-to-end supply chain solutions for the highly regulated healthcare sector.</p>
                                        <a href='/services/industrial-and-infactory-solutions/pharma' className='sec-read-btn'>Read More</a>
                                    </div>
                                    <h4 className='sld-counter'><span>04</span>/05</h4>
                                </div>
                            </div>
                            {/* slide 5     */}
                            <div className='sctr-sld'>
                                <div className='sectr-sld-contnt'>
                                    
                                <img className='sec-img' src={require('../../assets/img/homepage/discover-our-sectors/capricorn_logistics_telecom.png')}></img>
                                    <div className='sec-sub-cont'>
                                        <h2 className='sec-sld-ttl'>Telecom</h2>
                                        <p className='sec-para'>Our years of experience serving the Telecom industry help us provide specialized logistics solutions as per the clients' unique requirements.</p>
                                        <a href='/services/industrial-and-infactory-solutions/telecom' className='sec-read-btn'>Read More</a>
                                    </div>
                                    <h4 className='sld-counter'><span>05</span>/05</h4>
                                </div>
                            </div>
                            
                        </Sector_Slider>

                    </div>
                    <div className='col-lg-2 sec-sld-col2 home-sectr'>

                        <Sector_Slider {...sctr1_settings} className='sector-sld-nav' asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                            <div className='sctr-nav'>
                                <h2 className='nv-ttl'>Automotive</h2>
                            </div>
                            <div className='sctr-nav'>
                                <h2 className='nv-ttl'>Engineering</h2>
                            </div>
                            <div className='sctr-nav'>
                                <h2 className='nv-ttl'>FMCG</h2>
                            </div>
                            <div className='sctr-nav'>
                                <h2 className='nv-ttl'>Pharma</h2>
                            </div>
                            <div className='sctr-nav '>
                                <h2 className='nv-ttl hardware-ttl'>Telecom</h2>
                            </div>
                            
                        </Sector_Slider>
                        <a href='/services/industrial-and-infactory-solutions/' className='brder-btn explore-sec-btn'>Explore Sectors</a>

                    </div>
                </div>



            </div>
          </div>
      </div>
    </>
  )
}
