import React from 'react'
import { NavLink } from 'react-router-dom';
import '../../assets/css/media_news.css';
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';


export default function Custom_manager() {


    return (
        <>
            <div className='media_new_sec' id='custmr_serv'>
                <div className='cstm-container '>
                    <div className='row'>
                        <div className='co-lg-12'>

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>

                            <div className='news-item nws-grd'>

                                <div className='nw-prt2'>
                                    <h2>Manager - Custom Brokerage</h2>
                                    <h5 className="job-descrp main-descrp">Job Description</h5>
                                    <ul class="ul-styl">
                                        <li>Monitor the performance of the team and ensure the shipment of the key client’s TAT are completed in time;</li>
                                        <li>Co-ordination with Customs Executives, CFS Executives, Customer Service &amp; Documentation Dept;</li>
                                        <li>Maintaining cordial relations with customers;</li>
                                        <li>Keeping team members and subordinates motivating;</li>
                                        <li>Liaison with Customs, Shipping lines, Port Authorities, Other Government regulatory bodies such as Port Health Office, Assistant Drug Controller, and Plant/Animal Quarantine etc., and updating superiors on latest procedural changes or any other trade information;</li>
                                        <li>Follow the rules &amp; regulations laid down by the company in terms of clearance of consignment and credit norms laid down and the S.O.P. of customers;</li>
                                        <li>Keeping updated all the details of notices &amp; circulars from the customs and informing the company and customers;</li>
                                        <li>Developing and improving systems &amp; processes;</li>
                                        <li>Trouble shooting with due care and updating superior and customers on action taken;</li>
                                        <li>Ensuring the proper updating in the system and preparing the report to Management;</li>
                                        <li>Respect &amp; Maintain Company Policies and Commitments</li>
                                    </ul>

                                    <div class="row  job-detail">
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Department</h5>
                                            <ul class="ul-styl">
                                                <li>Accounts & finance</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Location</h5>
                                            <ul class="ul-styl">
                                                <li>Mumbai</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Qualification</h5>
                                            <ul class="ul-styl">
                                                <li>Bcom graduate</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Experience</h5>
                                            <ul class="ul-styl">
                                                <li>1 – 4 years</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
