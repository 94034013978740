import React from 'react'
import Header from '../components/global/header';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Privacy_spot from '../components/spotlight/privacy_spotlight';
import Privacy_sec from '../components/sections/privacy_sec'


export default function Privacy_policy() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Privacy_spot />
          <Privacy_sec />         
          <Global_reach/>
          <Footer/>
      </div>
    </>
  )
}
