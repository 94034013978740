import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/media_news.css'; 
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';
 

export default function Customer_service() {
 

  return (
    <>
    <div className='media_new_sec' id='custmr_serv'>
    <div className='cstm-container '>

        <div className='row'>
            <div className='col-12'>  
                        
                            <div className='news-item nws-grd'>                             
                                
                                <div className='nw-prt2'>
                                        <h2>Customer Service Executive</h2>
                                        <h5 className="job-descrp main-descrp">Job Description</h5>
                                        <ul class="ul-styl">
                                            <li>Placing booking,</li>
                                            <li>Follow up for bookings,</li>
                                            <li>Co-ordinating with transporter for container placement for stuffing,</li>
                                            <li>Providing container details to shipper,</li>
                                            <li>Arranging checklist approval from shipper</li>
                                            <li>Co-ordination with operations for clearance</li>
                                            <li>Arranging form 13timely updation of status to all the concerned, co-ordination with overseas office regarding shipments, keeping track of shipment etc</li>
                                        </ul>
                                        
                                        <div class="row job-detail">
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Department</h5>
                                                <ul class="ul-styl">
                                                    <li>FF/CC</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Location</h5>
                                                <ul class="ul-styl">
                                                    <li>Mumbai – andheri</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Qualification</h5>
                                                <ul class="ul-styl">
                                                    <li>Any graduate</li>
                                                </ul>
                                            </div>
                                            <div class="col-sm">
                                                <h5 className="job-descrp">Experience</h5>
                                                <ul class="ul-styl">
                                                    <li>1 – 4 years</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>                      
            
            </div>
        </div>
    </div> 
    </div>
    </>
  )
}
