import React from 'react'
import Slider from 'react-slick';
import { useState, useRef ,useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/about_sec1.css'; 
import Journey_slider from './journey_slider';
import {NavLink} from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function About_sec1() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);
    const roll_txt = useRef(null);



    useEffect(() => {
        gsap.to(".rollingText", {
        x: -1000,
        scrollTrigger: {
            trigger: ".rollingText",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
            markers: true
        }
        });
    }, []);


    useEffect(() => {
        gsap.to(".mob_rollingText", {
        x: -800,
        scrollTrigger: {
            trigger: ".mob_rollingText",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
            markers: true
        }
        });
    }, []);


    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);



  return (
    <>
        <div className='about-sec1-main pdng-tb'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-lg-5 abt-col1' ref={text_fade} id='fade'>
                        <div  className='sec-name-dv'>
                            <span className='dot'></span>
                            <h3 className='sec-name-ttl'>About us</h3>
                        </div>
                        <p className='sec-ttl'>We drive on the passion for excellence.</p>
                            <p className='sec-ttl-2'>Since 2001, our customers have experienced only the finest logistics services. The unshakeable trust of our customers comes from the promises we have delivered every time. As a result, we have established ourselves among the premier services providers in India's Clearing and Forwarding industry for over two decades.</p>

                        
                    </div>
                    <div className='col-lg-7 abt-col2' ref={text_fade} id='fade'>
                        <p className='dcrb'>From a humble start, Capricorn Logistics has swiftly spread its network globally.  Currently, we have our presence across 32 Indian and ten overseas offices in major economies like the United States of America, Europe, China, Africa, and the United Arab Emirates.</p>
                            
                        <p className='dcrb'>Our vision has always been to deliver superior logistics service quality. Capricorn achieves the ultimate customer satisfaction by coupling human expertise with logistics technology.</p>

                        <p className='dcrb'>Over the years, our expertise has grown with us. Today, we execute projects including Air Freight, Ocean Freight, Surface Transportation, Door To Door Express Service, Customs Brokerage, 3PL, 4PL & 5PL, and Industrial Solutions.</p>    

                        <p className='dcrb'>Capricorn Logistics aspires to give customers the maximum output of their investment of time, trust, and costs. Our forte is to deeply understand the requirements and design logistics and freight solutions to help businesses gain a competitive advantage. No matter what your supply chain challenges are, Capricorn is adept at delivering value for businesses of all sizes.</p>

                        {/* <a className='rd-btn explore-btn' href="/services">Explore Capricorn</a> */}
                    </div>
                </div>
            </div>
            <div className='roll_txt_dv desk_txt'>
                <h2 ref={roll_txt} className='rollingText'>COMMITMENT  /  INNOVATION  /  INTEGRITY - VERSATILITY</h2>
            </div>
            <div className='mob_roll_txt_dv'>
                <h2 ref={roll_txt} className='mob_rollingText'>COMMITMENT  /  INNOVATION  /  INTEGRITY - VERSATILITY</h2>
            </div>
            <Journey_slider/>
        </div>
    </>
  )
}
