import React from 'react'
import '../../assets/css/privacy.css'


export default function Privacy_sec() {
  return (
    <>
        <div className='privcy-main'>
            <div className='cstm-container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='awrd-ttl'>Capricorn Logistics Pvt. Ltd. – Privacy Policy</h2>
                        
                        <p>Capricorn Logistics Pvt. Ltd. (“CLPL”) is aware of the importance of data protection. CLPL respects your right to privacy and your right to control and protect the dissemination of your sensitive personal information. This Privacy policy applies strictly to the information collected on this website – <a href='/'>www.capricornlogistics.com</a>. It shall notify you of the following:</p>
                        
                        <ul class="mission-list">
                            <li>What personally identifiable information is collected from you through the website, how it is collected and how it is used</li>
                            <li>Tools through which information is gathered and processed for statistical purposes</li>
                            <li>The security procedures and mechanisms in place to ensure the confidentiality of your personal data</li>
                        </ul>

                        <h2 className='awrd-ttl'>“Personally Identifiable Information”</h2> 

                        <p>Personally identifiable information (“PII”) is the information relating to the specific personal and factual characteristic of a certain natural person. It is the information which establishes a direct link to your identity.</p>

                        <h2 className='awrd-ttl'>CLPL collects PII through the following two pages on our website:</h2>

                        <ul class="mission-list">
                            <li><a href='/contact'>https://www.capricornlogistics.com/reach-us/ </a><br />
                            In relation to the online contact form, filled by the user as part of an information request, the PII includes a combination of the name, e-mail, phone number, subject of information being provided, concerned service being covered, company of employment and comment concerned with the information being provided.</li>
                            <li><a href='/careers/application-form'>https://www.capricornlogistics.com/careers/application-form/ </a><br />
                            PII, in relation to the online job application will include a combination of the applicant’s name, e-mail, phone number, work experience, department of interest and attached curriculum vitae.</li>
                        </ul>

                        <h2 className='awrd-ttl'>Information Collection, Use and Sharing</h2> 

                        <p>CLPL are the sole owners of the PII collected on this website. We will not disseminate this information with any other website or social media platform, unless with your explicit consent. This information will be used by the CLPL to respond to you, regarding the reason you contacted us. In the event the need arises to use that information in any other way, except where expressly permitted by law, CLPL shall seek the user’s permission in advance of such use.</p>

                        <h2 className='awrd-ttl'>Cookies</h2> 

                        <p>The purpose of using cookies on this website is to better a user’s experience while visiting this website. Cookies are small files saved to the user’s computer’s hard drive that track, save and store information about the user’s interactions and usage of the website. This allows the website, through its server, to provide the user with a tailored experience within this website. Users are advised that if they wish to deny the use and saving of cookies from this website on to their computer’s hard drive, they should take necessary steps within their web browser’s security settings to block all cookies from this website. You can find this information in your browser’s help section.<br /><br />

                        This website uses tracking software to monitor its visitors to better understand how they use it. This software is provided by Google Analytics which uses cookies to track visitor usage. The software will save a cookie to your computer’s hard drive in order to track and monitor your engagement and usage of the website, but will not store, save or collect personal information. CLPL uses the following cookies to collect information for statistical purposes:</p>

                        <ul class="mission-list">
                            <li>Session ID Cookies: Used to enable certain features of the website to better understand how you interact with it, to monitor aggregate usage by CLPL users and web traffic routing on the website.</li>
                            <li>Persistent Cookies: This type of cookie is saved on your computer for a fixed period (usually a year or longer) and is not deleted when the browser is closed. Persistent cookies are used where we need to know who you are for more than one browsing session. For example, we use this type of cookie to store your preferences, so that they are remembered for the next visit.</li>
                        </ul>

                        <h2 className='awrd-ttl'>Web Beacons</h2> 

                        <p>Our website may contain electronic images known as Web Beacons (also called single-pixel gifs) and are used along with cookies to compile aggregate statistics to evaluate website performance and gauge a user’s activity on the website.</p>

                        <h2 className='awrd-ttl'>Business Transfer</h2> 

                        <p>CLPL may sell, transfer or otherwise share some or all of its assets, including your PII in connection with a merger, acquisition or reorganization.</p>

                        <h2 className='awrd-ttl'>Data Security, Integrity and Retention</h2> 

                        <p>The security, integrity and confidentiality of your PII is of extreme importance to CLPL. We have implemented stringent technical, administrative and physical security measures to protect guest information from unauthorized access, disclosure, use and modification. We are constantly reviewing our security procedures to stay abreast and updated with appropriate new technology and methods. Please be aware though that, despite our best efforts, no security measures are fool-proof or impenetrable. Your PII will be retained for the length of time needed to fulfill the purposes outlined in this privacy policy unless a longer retention period is required or permitted by law.</p>

                        <h2 className='awrd-ttl'>Changes to the Privacy Policy</h2>

                        <p>In the event of any modification, alterations and amendments carried out with this privacy policy, CLPL will notify you by placing a prominent notice on the website. Any amendment or alterations to this privacy policy which requires a disclosure of PII that an authorized user has requested us not to disclose, will only be disclosed with the prior written consent of such authorized user.</p>

                        <h2 className='awrd-ttl'>Contact Information</h2>
                        <p>If you have questions about this Privacy Policy, the practices of this website, or if you believe your personal information has been used inappropriately and in violation of the terms contained in this policy or if you have further questions related to this policy, please contact us.</p>
                    </div>
                </div>
            </div>
        </div>  
    </>
  );
}
