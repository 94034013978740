import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services telecom_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-6'>

                      <h3 className='air_srvc_ttl'>What makes us the best Logistics Partner for Telecom Industry?</h3>
                      <p className='air_srvc_para'>
                      With the accelerated growth of wireless communications, high bandwidth networks, private and government infrastructure projects, and massive worldwide sales of multimedia devices, there is a growing need for reliable and experienced logistics partners to handle the industry's global freight and logistics needs. There has been a radical shift from being a supply-driven industry to a demand-driven industry in the telecom sector. We understand that achieving operational efficiencies and reducing cost has taken paramount importance. We don't just provide the services, but we partner with our clients to understand the dynamics of their business and offer an end-to-end supply chain – right from sourcing to warehousing to distribution of the products.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                  <h3 className='air_srvc_ttl'>Our Capabilities Include:</h3>
                <ul className='serv_cap_icn_lst'>
                <li>Assembly and kitting</li>
	<li>Packaging and repackaging</li>
	<li>Sequencing/line feeding</li>
	<li>Vendor Management</li>
	<li>Installation & Maintenance of Equipment</li>
	<li>Testing & Commissioning of Networks</li>
	<li>Optimisation</li>
	<li>Training</li>
	<li>Operation & Maintenance of Wireless Network</li>
                </ul>
                  </div>

                  
              </div>
          </div>
          {/* <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img> */}
      </div>
    </>
  )
}
