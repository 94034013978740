
import React from 'react'

import Header from '../components/global/header';
import Home_Spotlight from '../components/spotlight/home_spotlight';
import Arabic_spotlight from '../components/spotlight/arabic_spotlight';
import About from '../components/sections/about';
import Servies from '../components/sections/services';
import Sector_slider from '../components/sections/sector_slider';
import Case_study from '../components/sections/case_study';
import Testimonials from '../components/sections/testimonials';
import Life_capricorn from '../components/sections/life_capricorn';
import News from '../components/sections/news';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Arabic_about from '../components/sections/arabic_about'

export default function Arabic_page() {

  return (
    <>
      
      <div className='pg-layout' id='home'>
          <Header/>
          {/* <Home_Spotlight /> */}
          <Arabic_spotlight />
          {/* <About /> */}
          <Arabic_about />
          <Servies />
          <Sector_slider />
          <Case_study />
          <Testimonials />
          <Life_capricorn />
          <News />
          <Global_reach/>
          <Footer/>
      </div>
     
    </>
  )
}
