import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Air_freight_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>
                      <h3 className='air_srvc_ttl'>Built to suit all your Air Freight Forwarding needs</h3>
                      <p className='air_srvc_para'>
                      Being one  of the best air freight service provider, Capricorn Logistics understands the criticality of handling high-value and fragile shipments in air cargo. We are known for providing air freight services across major airports globally with reliability and speed.
                        <br/><br/>
                        Whether it is a small package or an oversized shipment, our customized approach ensures that we offer the best possible solution to your dynamic requirements adhering to the customs regulations of the country of delivery.
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_door_to_door_service.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Door-To-Door Service</h3>
                                    <p className='srvc-bx-para'>
                                    Our 'Door-To-Door' air cargo network services reach widespread locations across geographies. We pick up your packages from your home, office, or factory and deliver them to their destination.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_large_size_shipment.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Large-Size Shipment</h3>
                                    <p className='srvc-bx-para'>
                                    We are well-equipped for handling oversized containers or pallets that require special equipment, like cranes or forklifts, and provide you with special shipments with customized solutions.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship small-sip-spacmange'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_small_size_shipment.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Small-Size Shipment</h3>
                                    <p className='srvc-bx-para'>
                                    At Capricorn Logistics, we provide speedy air freight solutions for your small-package shipments. Our highly competitive rates and the flexibility to offer customized options fit all your requirements.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/air-frieght-forwarding/capricorn_logistics_standard_air.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Standard Air</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics expertly handles the complexities of your global and domestic air-cargo needs with the highest level of personalized service, giving you end-to-end visibility and defined lead time.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>



                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img>
      </div>
    </>
  )
}
