import React from 'react'
import Header from '../../components/global/header';
import Application_spot from '../../components/spotlight/careers/application_spot';
import Application_form_comp from '../../components/sections/application_form_comp';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Accounts_assistant from '../../components/sections/accounts_assistant';


export default function  Accounts_assistant_page() {

    
  return (
    <>
      <div className='pg-layout' id='custmr_serv_main'>
          <Header/>
          <Application_spot/>
          <Accounts_assistant />
          <h2 className="apply-nw-txt">Apply Now</h2>
          <Application_form_comp career_fld="Accounts assistant / executive"/>
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
