import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Warehousing() {
  return (
    < >
      <div className='vlus_main' id='warehousing'>
            <div className='mbl-img'>
                      <img className='vlue_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_warehousing.jpg')}></img>
             </div>
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                    <h2 className='air-frgt-ttl'>Warehousing</h2>
                    <p className='air-frgt-para'>
                    Capricorn Logistics warehousing solutions are backed by the latest technologies and top-class Material Handling Equipment (MHE), such as forklifts, electrical pallet trucks, load based racking systems, hydraulic docking,<b> RFID, bar coding, real time information to customers on  stocks and inbound/outbound</b> etc., coupled with well-trained professionals across all levels. We have enabled our clients to grow their operations with our strategically located country-wide and worldwide network of warehouses and distribution centres.
                    </p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/warehousing/capricorn_logistics_warehousing.jpg')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
