
import React from 'react'
import Header from '../../components/global/header';
import Global_freight_spotlight from '../../components/spotlight/services/global_freight_spotlight';
import Air_freight from '../../components/sections/services/air_freight';
import Air_freight_services from '../../components/sections/services/air_freight_services';
import Ocean_freight from '../../components/sections/services/ocean_freight';
import Ocean_freight_services from '../../components/sections/services/ocean_freight_services';
import Land_freight from '../../components/sections/services/land_freight';
import Land_freight_services from '../../components/sections/services/land_freight_services';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';

export default function Global_freight_forwarding_solutions_page() {


  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Global_freight_spotlight/>
          <Air_freight />
          <Air_freight_services/>
          <Ocean_freight/>
          <Ocean_freight_services/>
          <Land_freight/>
          <Land_freight_services/>
          <Connect_us />
          <Footer/>

      </div>
    </>
  )
}
