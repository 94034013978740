
import React from 'react'

import Header from '../components/global/header';
import Services_main from '../components/sections/services_main';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Connect_us from '../components/sections/connect_us';
import '../assets/css/home_mobil.css'; 

export default function Servicespage() {

  return (
    <>
      
      <div className='pg-layout gb-srvc'>
          <Header/>
          <Services_main />
          {/* <Global_reach/> */}
          <Connect_us />
          <Footer/>
      </div>
     
    </>
  )
}
