import React from 'react'
import '../../assets/css/global_reach.css'; 
import {NavLink} from 'react-router-dom';

export default function Global_reach() {
  return (
    <>
        <div className='global_main pdng-tb light-blue ' id='global_reach'>
            <div className='cstm-container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <h2 className='glob-ttl'>Our Global Reach</h2>
                        <p className='glob-para'>Capricorn Logistics brings into reality the "Think Global, Act Local" ideology by giving customers both - the trust of traditional companies and the expertise & infrastructure of multinationals.</p>

                        <a href='/about/our-network/' className='brder-btn global-btn'>Explore Our Network</a>
                        
                        <img className='globe-img' src={require('../../assets/img/globe.png')}></img>
                        {/* <img className='globe-img mob-glb-img' src={require('../../assets/img/global_mob.png')}></img> */}
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
