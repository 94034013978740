import React from 'react'
import Header from '../../components/global/header';
import Integrated_suppy_spotlight from '../../components/spotlight/services/integrated_supply_spotlight';
import Warehouseing from '../../components/sections/services/warehousing';
import Warehousing_services from '../../components/sections/services/warehousing_services';
import Transportation from '../../components/sections/services/transportation';
import Transportation_services from '../../components/sections/services/transportation_services';
import Express from '../../components/sections/services/express';
import Express_services from '../../components/sections/services/express_services';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';

export default function integrated_supply_chain_solutions_page() {
  return (
    <>
        <div className='pg-layout'>
          <Header/>
          <Integrated_suppy_spotlight/>
          <Warehouseing/>
          <Warehousing_services/>
          <Transportation/>
          <Transportation_services/>
          <Express/>
          <Express_services/>
          <Connect_us />
          <Footer/>
        </div>  
    </>
  )
}
