import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';


export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> <a href="/services/industrial-and-infactory-solutions/">Industrial & Infactory Solutions </a> <span>/</span> Engineering</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Engineering Industry Solutions</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Powering the Engineering Sector with Dynamic Supply Chain Solutions.</p>
                        
                        <p className='dcrb'>Engineering and manufacturing companies require unique supply chain solutions. We at Capricorn understand the special shipping and handling requirements in the engineering sector that help streamline your operations, achieve financial targets, and deliver consistency in quality. Our proficient team works closely with some of the largest engineering and manufacturing companies and has successfully helped drive efficiencies and reduced costs in their supply chain.</p>

                    </div>
                </div>
            </div>
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
