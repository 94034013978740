import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';

export default function Openings_spot() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> CAREERS <span>/</span> Current Openings</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Current Openings</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                       

                    </div>
                </div>
            </div>
            {/* <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img> */}
        </div>
    </>
  )
}
