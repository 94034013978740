import React from 'react'
import Header from '../../components/global/header';
import Lac_spotlight from '../../components/spotlight/careers/lac_spotlight';
import Life_capricorn from '../../components/sections/life_capricorn';
import Diversity_at_work from '../../components/sections/diversity_at_work.js';
import Cap_fun_slider from '../../components/sections/cap_fun_slider';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Life_capricorn_new from '../../components/sections/life_capricorn_new';


export default function Life_at_capricorn() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Lac_spotlight/>
          <Life_capricorn_new />          
          <Diversity_at_work />
          <Cap_fun_slider/>
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
