import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/case_study.css'; 
import {a} from 'react-router-dom';



export default function Case_study() {

    const case_studies = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        centerMode: true,
        centerPadding: '40px'
       
            
    };


  return (
    <>
    <div className='case_Study_name sec-pd-tb'>
        <div className=''>
            <div className='row'>
                <div className='col-lg-12 cas-col'>
                    <h2 className='cs-ttl'>Noteworthy</h2> 
                    <h2 className='cs-ttl txt-blue'>Case Studies.</h2> 
                    <Slider {...case_studies} className='cs-sld'>

                        {/* slide1 */}
                        <div className='cs-item'>
                            <div className='cs-col-1'>
                                <img className='cs-img' src={require('../../assets/img/cs-img1.png')} />
                            </div>
                            <div className='cs-col-2'>
                                <p className='cs-para'>International freight forwarding services sought additional assistance for improving
                                    control over its global supply chain.</p>
                                <p className='cs-txt'>21 May 2022</p> 
                                <a className='rd-btn cs-btn' href="/">Explore Case Study</a>    
                            </div>
                        </div>

                        {/* slide2 */}
                        <div className='cs-item'>
                            <div className='cs-col-1'>
                                <img className='cs-img' src={require('../../assets/img/cs-img1.png')} />
                            </div>
                            <div className='cs-col-2'>
                                <p className='cs-para'>International freight forwarding services sought additional assistance for improving
                                    control over its global supply chain.</p>
                                <p className='cs-txt'>21 May 2022</p>  
                                <a className='rd-btn cs-btn' href="/">Explore Case Study</a>    
                            </div>
                        </div>

                        {/* slide3 */}
                        <div className='cs-item'>
                            <div className='cs-col-1'>
                                <img className='cs-img' src={require('../../assets/img/cs-img1.png')} />
                            </div>
                            <div className='cs-col-2'>
                                <p className='cs-para'>International freight forwarding services sought additional assistance for improving
                                    control over its global supply chain.</p>
                                <p className='cs-txt'>21 May 2022</p>  
                                <a className='rd-btn cs-btn' href="/">Explore Case Study</a>    
                            </div>
                        </div>

                        {/* slide4 */}
                        <div className='cs-item'>
                            <div className='cs-col-1'>
                                <img className='cs-img' src={require('../../assets/img/cs-img1.png')} />
                            </div>
                            <div className='cs-col-2'>
                                <p className='cs-para'>International freight forwarding services sought additional assistance for improving
                                    control over its global supply chain Last Slider.</p>
                                <p className='cs-txt'>21 May 2022</p>  
                                <a className='rd-btn cs-btn' href="/">Explore Case Study</a>    
                            </div>
                        </div>

                    </Slider>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
