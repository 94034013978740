import React from 'react'
import { NavLink } from 'react-router-dom';
import '../../assets/css/media_news.css';
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';


export default function Revenue_billing_executive() {


    return (
        <>
            <div className='media_new_sec' id='custmr_serv'>
                <div className='cstm-container '>
                    <div className='row'>
                        <div className='co-lg-12'>

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>

                            <div className='news-item nws-grd'>

                                <div className='nw-prt2'>
                                    
                                    <h2>Revenue / Billing Executive</h2>
                                    <h5 className="job-descrp main-descrp">Job Description</h5>
                                    <ul class="ul-styl">
                                        <li>Primarily responsible to checking of the followings, for all bills of Sea/Air Import &amp; Export for
                                            shipments:
                                            <ol type='a'>
                                                <li>Proper File receipt entries to the system.</li>
                                                <li>Verifying receipts of shipping company, CFS etc..</li>
                                                <li>Transport Delivery chellan.</li>
                                                <li>Checking, Billed for reimbursement and agency charges as per the terms and conditions with the
                                                client/Quotations.</li>
                                                <li>Complete attachment of receipts and completeness of the file.</li>
                                            </ol>                                           
                                        </li>
                                        <li>Cross checking shipping line payment of sea import &amp; sea export shipments..</li>
                                        <li>Follow up for any pending receipt or documents.</li>
                                        <li>Follow up with Clients/branches for centralized customer billing &amp; checking the correctness of
                                            the billing for Key clients.</li>
                                        <li>Preparing billing &amp; cross checking clearance expenses voucher.</li>
                                        <li>Preparing billing for Clients shipments of Clearance/FF.</li>
                                        <li>You shall perform jobs which are incidental to or connected with above functions and which you
                                            are capable of handling, and any other tasks being assigned to you from time to time.</li>
                                    </ul>

                                    <div class="row  job-detail">
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Department</h5>
                                            <ul class="ul-styl">
                                                <li>Accounts & finance</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Location</h5>
                                            <ul class="ul-styl">
                                                <li>Mumbai – andheri</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Qualification</h5>
                                            <ul class="ul-styl">
                                                <li>Bcom graduate</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Experience</h5>
                                            <ul class="ul-styl">
                                                <li>1 – 4 years</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
