import React from 'react'
import '../../../assets/css/services_sec.css'; 
import '../../../assets/css/services_main.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import '../../../assets/css/home_mobil.css'; 

export default function Industrial_infactory_sec() {
  gsap.registerPlugin(ScrollTrigger); 

  const up = useRef(null);
  const up2 = useRef(null);

  useEffect(() => {
    gsap.to("#up", {
    y: -220,
    duration: 1,
    ease: "linear",
    delay: 0.2,
    scrollTrigger: {
        trigger: "#up",
        markers: true
    }
    });
}, []);

useEffect(() => {
    gsap.to("#up2", {
    y: -80,
    duration: 1,
    ease: "linear",
    delay: 0.2,
    scrollTrigger: {
        trigger: "#up2",
        markers: true
    }
    });
}, []);


  return (
    <>
      <div className='service_main pdng-tb industrl' id='service_landng'>
          <div className='cstm-container'>
              <div className='row srvc-rw' id='industrial-pg-grid'>

                  <div className='col-lg-12'>
                      <h3 className='srvc-sub-ttl'>Our Alliance with <br/>Key Sectors. </h3>
                      <h2 className='srvc-ttl'>Industrial & <br/>Infactory <br/>Solutions</h2>
                  </div>

                  <div className='col-lg-4 top-spac custm-brokg'>
                      <div className='service-box' >
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_automotive_main.jpg')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Automotive</h3>
                            <p className='srvc-bx-para'>We are one of the leading logistics service providers with extensive expertise in providing end-to-end supply chain solutions specific to the automotive industry.</p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/automotive">
                              <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                              </div>
                            </NavLink>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac gloabl-fght' ref={up2} id='up2'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_engineering_main.jpg')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Engineering</h3>
                            <p className='srvc-bx-para'>Our experience in the engineering sector has enabled us to gain sector-specific knowledge and design solutions that give you an edge over your competitors.</p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/engineering">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                            </div>
                            </NavLink>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac sply-chn' ref={up} id='up'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_fmcg_main.jpg')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>FMCG</h3>
                            <p className='srvc-bx-para'>Our specialist teams are adaptable and agile in delivering supply chain solutions to meet the fast-paced, dynamic requirements of the FMCG sector.</p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/fmcg">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                            </div>
                            </NavLink>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 cstm-hide'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_pharma_main.jpg')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Pharma </h3>
                            <p className='srvc-bx-para'>We are fully equipped to manage all aspects of end-to-end supply chain solutions for the highly regulated healthcare sector.</p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/pharma">
                            
                            </NavLink>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac indstry-factry'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_pharma_main.jpg')}></img>
                        <div className='srvc_contact'>
                        <h3 className='srvc-bx-ttl'>Pharma </h3>
                            <p className='srvc-bx-para'>We are fully equipped to manage all aspects of end-to-end supply chain solutions for the highly regulated healthcare sector. </p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/pharma">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                            </div>
                            </NavLink>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac vlu-add vlu-spacmange'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_telecom_main.jpg')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Telecom </h3>
                            <p className='srvc-bx-para'>Our years of experience serving the Telecom industry help us provide specialized logistics solutions as per the clients' unique requirements.</p>
                            <NavLink className='rd-btn srvc-arrow-btn' to="/services/industrial-and-infactory-solutions/telecom">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../../assets/img/srvc-btn.png')}></img>
                                  </div>
                            </div>
                            </NavLink>
                        </div>
                      </div>
                  </div>
                  <img className='servc_bg' src={require('../../../assets/img/service-bg.png')}></img>
              </div>
          </div>
      </div>
    </>
  )
}
