import React from 'react'
import Header from '../components/global/header';
import Contact_spotlight from '../components/spotlight/contact_spotlight';
import Contact_sec from '../components/sections/contact_sec';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import Contact_spot from '../components/spotlight/contact_spotlight';
import $ from "jquery";

export default function contact() {
  
  // $("body").addClass("main-contact");
   
  return (
    <>
      <div className='pg-layout cntct-pgs'>
          <Header/>
          {/* <Contact_spotlight/> */}
          <Contact_spot />
          <Contact_sec/>
          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}
