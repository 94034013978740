import React from 'react'
import '../../assets/css/services_sec.css'; 
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';


export default function Services() {
  gsap.registerPlugin(ScrollTrigger); 

  const up = useRef(null);
  const up2 = useRef(null);

  useEffect(() => {
    gsap.to("#up", {
    y: -220,
    duration: 1,
    ease: "linear",
    delay: 0.2,
    scrollTrigger: {
        trigger: "#up",
        markers: true
    }
    });
}, []);

useEffect(() => {
    gsap.to("#up2", {
    y: -80,
    duration: 1,
    ease: "linear",
    delay: 0.2,
    scrollTrigger: {
        trigger: "#up2",
        markers: true
    }
    });
}, 

[]);


  return (
    <>
      <div className='service_main pdng-tb '>
          <div className='cstm-container'>
              <div className='row srvc-rw' id='home-pg-grid'>

                  <div className='col-lg-12'>
                      {/* <h3 className='srvc-sub-ttl'>Encompassing a<br/>Spectrum of </h3> */}
                      <h2 className='srvc-ttl'>Our <br/> Services.</h2>
                      <p className='air_srvc_para'>Expand your business<br/> horizon with our integrated<br/> logistics services.</p>
                  </div>

                  <div className='col-lg-4 top-spac custm-brokg'>
                      <div className='service-box' >
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_customs_brokerage.png')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Custom Brokerage </h3>
                            <p className='srvc-bx-para'>With ever-changing customs compliance, the movement of cargo across borders can get very confusing. Being FCPA compliant, our team handles all the complexity while keeping your intervention to the bare minimum. Using automation, we prevent errors that could have resulted in fines or delays affecting your business. Via auto-alerts and status reports, you are apprised of the real-time progress of all shipments.</p>
                            <a href='/services/custom-brokerage' className='sub-servc-lnk'>Compliance</a>

                            <a className='rd-btn srvc-arrow-btn' href="/services/custom-brokerage">
                                <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                </div>
                            </a>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac gloabl-fght' ref={up2} id='up2'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_global_freight_forwarding.png')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Global Freight<br/>Forwarding </h3>
                            <p className='srvc-bx-para'>Over several years, our freight brokers have drawn out the best freight rates with multiple carriers. So be it air, land, or sea routes, we have got you covered. Our cutting-edge technology solutions help facilitate your freight movement on time, with accuracy, and at the best rates. Whatever your domestic and global forwarding needs, Capricorn Logistics will be the forwarder of your choice.</p>
                            <a href="/services/global-freight-forwarding-solutions#air_freight" className='sub-servc-lnk'>Air Freight</a> | <a href='/services/global-freight-forwarding-solutions#ocean_freight' className='sub-servc-lnk'>Ocean Freight</a> | <br /><a href='/services/global-freight-forwarding-solutions#land_freight' className='sub-servc-lnk'>Land Freight</a>
                            <a className='rd-btn srvc-arrow-btn' href="/services/global-freight-forwarding-solutions">
                                <div className="icon-container">
                                    <div className="icon icon--left">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                                    <div class="icon icon--right">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                                </div>
                            </a>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac sply-chn sply-spacmange' ref={up} id='up'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_integrated_supply_chain_solutions.png')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Integrated Supply<br/>Chain Solutions</h3>
                            <p className='srvc-bx-para'>We provide an expansive range of tailored supply chain solutions to drive efficiency and enhance your business value. Attain complete visibility in the movement of your goods. Whether moving raw materials, storage in warehouses or transportation, we are equipped to deliver end-to-end 3PL/4PL supply chain solutions through real-time data and a centralized view.</p>
                            <a href="/services/integrated-supply-chain-solutions/#warehousing" className='sub-servc-lnk'>Warehousing</a> | <a href='/services/integrated-supply-chain-solutions/#transportation' className='sub-servc-lnk'>Transportation</a> | <br /><a href='/services/integrated-supply-chain-solutions/#express' className='sub-servc-lnk'>Express</a>
                            <a className='rd-btn srvc-arrow-btn' href="/services/integrated-supply-chain-solutions/">
                              <div className="icon-container">
                                    <div className="icon icon--left">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                                    <div class="icon icon--right">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                              </div>
                            </a>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 cstm-hide'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_industrial_and_infactory_solutions.png')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Industry and Infactory Solutions</h3>
                            <p className='srvc-bx-para'>Precision and efficiency are the hallmarks of our work. Before commencing any project, Capricorn Logistics' knowledge-laden team analyses your requirements in-depth. This is to prevent any operational risks, so your profit margins stay intact. Our team is adept at smoothly handling major and complex projects, including telecom, FMCG, automotive and engineering. We bring this confidence from our over two decades of expertise.</p>
                            <a href="/services/global-freight-forwarding-solutions#air_freight" className='sub-servc-lnk'>Air Freight</a> | <a href='/services/global-freight-forwarding-solutions#ocean_freight' className='sub-servc-lnk'>Sea Freight</a> | <br /><a href='/services/global-freight-forwarding-solutions#land_freight' className='sub-servc-lnk'>Land Freight</a> | <a href='/services/global-freight-forwarding-solutions#ocean_freight' className='sub-servc-lnk'>Sea Freight</a>
                            <a className='rd-btn srvc-arrow-btn' href="/services/industrial-and-infactory-solutions/">
                              <div className="icon-container">
                                    <div className="icon icon--left">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                                    <div class="icon icon--right">
                                        <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                    </div>
                              </div>
                            </a>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac indstry-factry'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_industrial_and_infactory_solutions.png')}></img>
                        <div className='srvc_contact'>
                        <h3 className='srvc-bx-ttl'>Industry and Infactory Solutions</h3>
                            <p className='srvc-bx-para'>We offer a wide range of logistics solutions adapted to various industries and comply with the highest quality standards. Our team is adept at smoothly handling major and complex projects, including telecom, FMCG, automotive, pharma and engineering. We bring this confidence from our over two decades of expertise. Capricorn Logistics' knowledge-laden team analyses your requirements in-depth to prevent operational risks so your profit margins stay intact.</p>
                            <a href="/services/industrial-and-infactory-solutions/automotive" className='sub-servc-lnk'>Automotive</a> | 
                            <a href='/services/industrial-and-infactory-solutions/engineering' className='sub-servc-lnk'> Engineering</a> | <br />
                            <a href='/services/industrial-and-infactory-solutions/fmcg' className='sub-servc-lnk'> FMCG</a> | 
                            <a href='/services/industrial-and-infactory-solutions/pharma' className='sub-servc-lnk'> Pharma</a> | 
                            <a href='/services/industrial-and-infactory-solutions/telecom' className='sub-servc-lnk'> Telecom</a>
                            <a className='rd-btn srvc-arrow-btn' href="/services/industrial-and-infactory-solutions">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                            </div>
                            </a>
                        </div>
                      </div>
                  </div>

                  <div className='col-lg-4 top-spac vlu-add vlu-spacmange'>
                      <div className='service-box'>
                        <img className='servc_img' src={require('../../assets/img/homepage/our-services/capricorn_logistics_value_added_solutions.png')}></img>
                        <div className='srvc_contact'>
                            <h3 className='srvc-bx-ttl'>Value-Added Solutions</h3>
                            <p className='srvc-bx-para'>At Capricorn, we comprehend our customers' dynamic and unique needs and thus offer a wide range of value-added services to your business activity. Our local team provides the best combination of customised value-added solutions that help you concentrate on your core business operations. We add value to your business by going beyond logistics and freight solutions.</p>
                            <a href='/services/value-added-solutions' className='sub-servc-lnk'>Value Added Solutions</a>
                            <a className='rd-btn srvc-arrow-btn' href="/services/value-added-solutions">
                            <div className="icon-container">
                                  <div className="icon icon--left">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                                  <div class="icon icon--right">
                                      <img className='servc_btn-arrow' src={require('../../assets/img/srvc-btn.png')}></img>
                                  </div>
                             </div>
                            </a>
                        </div>
                      </div>
                  </div>
                  <img className='servc_bg' src={require('../../assets/img/service-bg.png')}></img>
              </div>
          </div>
      </div>
    </>
  )
}
