import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';


export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES  </a> <span>/</span> <a href="/services/industrial-and-infactory-solutions/">Industrial & Infactory Solutions </a> <span>/</span>  Automotive</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Automotive Industry Solutions</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Steering world-class end-to-end supply chain solutions to the automotive sector.</p>
                        
                        <p className='dcrb'>Capricorn Logistics has been one of the world-class end-to-end supply chain solutions providers to the automotive sector for over two decades. Our experienced engineering and operational teams provide effective Freight Management services and specialise in critical, time-sensitive, and odd dimensional cargo, air, and ocean freight services.</p>  
                        <p className='dcrb'>We work closely with many renowned auto manufacturers and have implemented customised supply chain solutions to ensure efficiency and productivity in the customer's operations. Capricorn logistics is recognised and awarded the Best Services Provider for 24×7 dedicated operations and outstanding deliveries in the shortest time, thereby avoiding line stoppage.</p>  

                    </div>
                </div>
            </div>

            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
