
import React from 'react'

import Header from '../components/global/header';
import Case_study from '../components/sections/case_study';
import Testimonials from '../components/sections/testimonials';
import Portfolio_spotlight from '../components/spotlight/portfolio_spotlight';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';

export default function portfolio_page() {
  return (
    <>
    <div className='pg-layout'>
          <Header/>
         
          <Portfolio_spotlight/>
          <Case_study />
          <Testimonials />
          
          <Global_reach/>
          <Footer/>
      </div>
    </>
  )
}
