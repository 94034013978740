import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/store_locator.css'; 
import Masonry from 'react-masonry-css';
import useFetch from "react-fetch-hook";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 
import Map from '../../../src/Map';
import "../../Map.css"
import useScript from "../../hooks/useScript";



export default function Job_grid() {
  
  return (
    <>
    
    <div className='storeLocator'>
            {/* <Map/> */}
            <div className='map_dv'>
            <iframe src="https://www.google.com/maps/d/embed?mid=1imxSDR67bBFTfrRDuQARwRJlf1W3SuU&ehbc=2E312F" width="100%" height="600"></iframe>

            {/* <iframe src="https://superstorefinder.net/megalocator/mega-embed.php" width="100%" height="900px" allow="geolocation" scrolling="yes"></iframe> */}

            </div>
        </div>
                  
    </>
  )
}
