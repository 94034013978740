import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';

export default function Global_freight_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> Global Freight Forwarding</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Redefining<br/>Global Freight Forwarding <br/>Solutions</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Move any shipment across borders with utmost confidence.</p>
                        
                        <p className='dcrb'>Capricorn Logistics comes to you with the commitment and passion of a strategic logistics partner to deliver end-to-end shipments vis Land, Sea, and Air. Our experience in providing exceptional customer value, accompanied by the latest technology and a robust global network, has helped us add value and flexibility to client's supply chain requirements.</p>

                        <p className='dcrb'>Whether it is a small package or an oversized shipment, our customized approach ensures that we offer the best possible solution to your dynamic requirements adhering to the customs regulations of the country of delivery.</p>

                        

                    </div>
                </div>
            </div>
            
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
