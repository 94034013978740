import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../../../assets/css/fmcg_services.css"

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services pharma_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-6 serv_img_overlap'>

                      <h3 className='air_srvc_ttl'>Our specialised healthcare services include:</h3>
                      
                  </div>
                  <div className='col-lg-6 serv_img_overlap'></div>


                  <div className='col-lg-6 serv_img_overlap serv_clmn_gap_lft'>
                    <ul className='serv_cap_icn_lst'>
                    <li>Cold chain solutions</li>
	<li>Temperature controlled and monitored warehouse facilities and cold rooms</li>
	<li>Storage and distribution of registered medical devices</li>
                    </ul>
                  </div>

                  <div className='col-lg-6 serv_img_overlap serv_clmn_gap_rgt'>
                  <ul className='serv_cap_icn_lst'>
                  <li>Controlled pharmacy warehousing and storage</li>
	<li>Deliveries to pharmacies and hospitals across locations</li>
	<li>Medical disposals following proper guidelines.</li>
                    </ul>
                  </div>




                  
              </div>
          </div>
          {/* <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img> */}
      </div>
    </>
  )
}
