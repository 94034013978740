import React from 'react'
import Header from '../components/global/header';
import Media_spotlight from '../components/spotlight/media_spotlight';
import Media_news from '../components/sections/media_news';

import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import News_blog from '../components/sections/news&blog';


export default function media_center() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Media_spotlight/>
          <News_blog />
          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}
