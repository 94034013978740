import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services automotive_service'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-6'>

                      <h3 className='air_srvc_ttl'>What makes us the most preferred logistics partner for the major automotive companies?</h3>
                      
                  </div>
                  <div className='col-lg-6'></div>

                  <div className='col-lg-6'>
                  <p className='air_srvc_para'>
                  Our precision in providing operational excellence in implementing data-backed supply chain solutions with the latest technologies has made us the preferred partner to several leading passenger and commercial vehicle manufacturers and various spare parts suppliers.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                  <p className='air_srvc_para'>
                  Our solutions backed by an integrated ERP system trigger auto alerts, notifications, and real-time visibility at every step of the shipments, thus, ensuring a seamless flow of material to the customer's operations.
                      </p>
                  </div>

                  <div className='col-lg-6'>

                      <h3 className='air_srvc_ttl serv_ttl_new'>Our Operational Processes include:</h3>
                      
                  </div>
                  <div className='col-lg-6'></div>

                  <div className='col-lg-6'>
                    <h5 className='serv_sub-hd_ttl'>Inbound Logistics</h5>
                  <p className='air_srvc_para'>
                  We manage entire inbound logistics, including customs clearance, air and ocean freight, last-mile delivery, and material handling from various Indian and overseas merchants.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                  <h5 className="serv_sub-hd_ttl">Organized Transportation Service</h5>
                  <p className='air_srvc_para'>
                  We provide distribution management services, from organising the transportation to updating POD in our records to ensure optimal logistics solutions.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                    <h5 className='serv_sub-hd_ttl'>Customer Coordination</h5>
                  <p className='air_srvc_para'>
                  We work in coordination with our customers for dispatching materials and planning the pickup of the automotive parts/spares regularly.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                  <h5 className="serv_sub-hd_ttl">Statutory Compliance</h5>
                  <p className='air_srvc_para'>
                  Our domain experts have specialised in sector-specific statutory compliance, including excise documentation and filing returns, sales and service tax document maintenance, etc.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                    <h5 className='serv_sub-hd_ttl'>End-to-end Solution</h5>
                  <p className='air_srvc_para'>
                  From providing complex sequencing to spare parts-assembling and packing solutions, we specialise in customising every solution as per our customers' requirements.
                      </p>
                  </div>

                  <div className='col-lg-6'>
                  <h5 className="serv_sub-hd_ttl">Real-time data update on WMS</h5>
                  <p className='air_srvc_para'>
                  We keep our customers updated with real-time information receipts, inventories, assembly progress, dispatches, and PODs on WMS.
                      </p>
                  </div>


                  <div className='col-lg-6'>

                      <h3 className='air_srvc_ttl serv_ttl_new'>Our additional services to automotive companies include:</h3>
                      
                  </div>
                  <div className='col-lg-6'></div>


                  <div className='col-lg-6 serv_img_overlap bound-txt'>
                    <h5 className='serv_sub-hd_ttl'>Inbound</h5>
                    <ul className='serv_cap_icn_lst'>
                      <li>Customer Order</li>
                    	<li>Pick and Pack</li>
	                    <li>Local Delivery from Warehouse</li>
                    	<li>Customer Service</li>
                    	<li>Invoicing</li>
                    	<li>Inventory Management</li>
	                    <li>Stock Replenishment</li>
	                    <li>Pickup from FG Warehouse</li>
                    	<li>Transportation</li>
	                    <li>Repacking & Delivery</li>
	                    <li>Return Management</li>
                    </ul>
                  </div>
 
                  <div className='col-lg-6 serv_img_overlap bound-txt'>
                  <h5 className="serv_sub-hd_ttl">Outbound</h5>
                  <ul className='serv_cap_icn_lst'>
                  <li>Supplier Acquisition</li>
	                <li>Purchase Contracting</li>
	                <li>Quality Control</li>
	                <li>Order Follow-up</li>
	                <li>Acceptance</li>
	                <li>Packing</li>
	                <li>Documentation</li>
                	<li>Transportation</li>
                	<li>Unpacking & Storage</li>
	                <li>Repacking & Delivery</li>
	                <li>Transfer to Plant</li>
                    </ul>
                  </div>




                  
              </div>
          </div>
          {/* <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img> */}
      </div>
    </>
  )
}
