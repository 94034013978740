import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/our_values.css'; 
import Sector_Slider from 'react-slick';
import { useState, useEffect } from 'react';

export default function Our_values_mob() {

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);


  useEffect(() => {

    setNav1(slider1);
    setNav2(slider2);

  });

    const our_values1 = {
      dots: false,
      swipe:false,
      fade:true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      
      asNavFor: '.slider-nav'
    };
  
     const our_values2 = {
      dots: false,
      arrow: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      centerMode: true,
      focusOnSelect: true,
      centerPadding: '0px'
    };
    

  return (
    <>
      <div className='vlus_main our-values-mob mbl-img' id='our-values'>
            


          <div className='row vlus_row'>

          <div className='col-lg-6 value-sldr'>
              <Sector_Slider {...our_values2} className='sector-sld-nav' asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                
                {/* <div className='vlue-col2'>
                  <img className='vlue_img' src={require('../../assets/img/our_value.png')}></img>
                </div>
                <div className='vlue-col2'>
                  <img className='vlue_img' src={require('../../assets/img/our_value.png')}></img>
                </div> */}
                
              </Sector_Slider>  
              </div>

              <div className='col-lg-6 vlue-col1'>
                  <h2 className='vlue-ttl'>Our Values</h2>
                  {/* <Slider {...testimonial_sld} className='vlue-sld'> */}

                  <Sector_Slider {...our_values1} className='sector-sld' asNavFor={nav2} ref={slider => (setSlider1(slider))}>


                        <div className='vlue-item'>
                            <p className='vlue-sub-ttl'>Integrity</p>
                            <p className='vlue-para'>We ensure an ethical work environment for all employees; we encourage and support fair and honest behavior. We will always do the right thing each time we make a tough decision.</p>  
                        </div>

                        <div className='vlue-item'>
                            <p className='vlue-sub-ttl'>Diversity</p>
                            <p className='vlue-para'>Across all our locations, we encourage our managers to respect and ensure cultural diversity in the workforce.</p>  
                        </div>

                        <div className='vlue-item'>
                            <p className='vlue-sub-ttl'>Compassion</p>
                            <p className='vlue-para'>To show care and kindness for others by helping those in need.</p>  
                        </div>
                </Sector_Slider>
                  {/* </Slider> */}
              </div>             
          </div>
      </div>
    </>
  )
}
