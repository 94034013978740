import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Land_freight() {
  return (
    <>
      <div className='vlus_main' id='land_freight'>
            <div className='col-lg-6 vlue-col2 air-frght-col2 mbl-img'>
                      <img className='vlue_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/land-freight/capricorn_logistics_land_freight.png')}></img>
            </div>
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                  <h2 className='air-frgt-ttl'>Land Freight</h2>
                  <p className='air-frgt-para'>
                  Our reliable, local, national, and international transport services ensure 24X7 flexible, effective, and customized surface freight service solutions to any place for our clients.</p>
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/land-freight/capricorn_logistics_land_freight.png')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
