import React from 'react'
import Header from '../../components/global/header';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import BLog_inside from '../../components/sections/blog_inside';


export default function News_inside_page(props) {

  
  let { slug } = useParams();

    const { isLoading, data, error } = useFetch(
      `https://wordpress-819107-2935324.cloudwaysapps.com/wp-json/wp/v2/news/?slug=${slug}`
    );

 

  return (
    <>
      <div className='pg-layout'>
          <Header/>
         

          <div className='about-sec1-main pdng-tb'>
            {data && data.map(({  title , acf , content }) => (
              <div className='cstm-container blog-inside-page' >
                 {isLoading && <div>A moment please...</div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
                    <h1 className="sec-ttl">{title.rendered}</h1>
                    <img src={acf.image} />
                    <br /><br />
                    <p>{content.rendered}</p>
                </div>
            ))}
          </div>
          
          <Footer/>

      </div>
    </>
  )
}
