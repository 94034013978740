import React from 'react'
import '../../../assets/css/ocean_freight.css'; 

export default function Transportation() {
  return (
    <>
      <div className='vlus_main' id='transportation'>
          <div className='row vlus_row'>

                <div className='col-lg-6 vlue-col2 ocen-frght-col1'>
                    <img className='vlue_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/transportation/capricorn_logistics_transportation.jpg')}></img>
                </div> 
                <div className='col-lg-6 vlue-col1 ocen-frght-col2'>
                    <div className='hd-dv'>
                        <h2 className='air-frgt-ttl trnsprt-txt'>Transportation</h2>
                        <p className='air-frgt-para'>
                        Capricorn Logistics delivers optimal road transport solutions, including first mile, long haul, and last-mile delivery, point to point, and cross-dock, through multimodal channels, i.e., sea, air, and road. We ensure the products are delivered in time at a minimum cost. In addition, we provide real-time visibility and a 24 X 7 tracking facility with our advanced TMS (Transport Management Software).
                        </p>
                    </div>
                </div>
               
          </div>
      </div>
    </>
  )
}
