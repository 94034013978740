import React from 'react'
import '../../../assets/css/service_spotlight.css'; 
import { useState, useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { HashLink as Link } from 'react-router-hash-link';


export default function Integrated_supply_spotlight() {

    gsap.registerPlugin(ScrollTrigger); 
    
    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);




  return (
    <>
      <div className='about-sec1-main pdng-tb service_spotlight gb-srvc'>
            <div className='cstm-container '>
                <div className='row'>
                    <div className='col-12'>
                        <div className='sec-name-dv'>
                            <h3 className='sec-name-ttl'><a href="/">HOME </a> <span>/</span> <a href="/services">SERVICES </a> <span>/</span> Value Added Services</h3>
                        </div>
                    </div>
                    <div className='col-lg-6 abt-col1' ref={text_fade} id='fade'>  
                        <p className='sec-ttl'>Value Added Services</p>
                    </div>
                    <div className='col-lg-6 abt-col2' ref={text_fade} id='fade'>
                        
                        <p className='sec-sub-ttl'>Delivering value that enhances associations.</p>
                        
                        <p className='dcrb'>At Capricorn, we comprehend our customers' dynamic and unique needs and thus offer a wide range of value-added services to your business activity. Our local team provides the best combination of customised value-added solutions that help you concentrate on your core business operations.</p>  

                    </div>
                </div>
            </div>
            <div className='sptlgt_img_algn'>
                <img className='srvc_spotlight_img' src={require('../../../assets/img/service-sportlight1.png')}></img>
            </div>
        </div>
    </>
  )
}
