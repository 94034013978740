import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/testimonials.css'; 

export default function Testimonials() {

    const testimonial_sld = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500
            
    };

    const testimonial_img_sld = {
        arrows: false,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        cssEase: 'ease',
        touchThreshold: 100   
            
    };

  return (
    <>
    <div className='testimonial sec-pd-tb'>
        <div className='cstm-container'>
            <div className='row'>
                <div className='col-12'>
                    <h2 className='test-ttl'>Take the Word of Our Satisfied Customers</h2>
                </div>
                <div className='col-lg-5' id='mobil-img'>
                    <Slider {...testimonial_img_sld} className='test-img-sld'>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_1.png')}></img>
                        </div>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_2.png')}></img>
                        </div>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_3.png')}></img>
                        </div>
                    </Slider>
                </div>
                <div className='col-lg-7 test-col1'>
                    <img className='qut-img' src={require('../../assets/img/quote.png')}></img>
                    <Slider {...testimonial_sld} className='testi-sld'>

                        <div className='item'>
                            <p className='testi-para'><span>&#8220;</span>We appreciate the efforts put in by your team in clearing our shipments in the shortest possible time. Your special efforts have kept our product line functioning without any interruption.<span>&#8221;</span>
</p>
                            <p className='cust-dt2'>Vice President Operations</p>
                            <p className='cust-dtl'>Pentair Water</p>
                        </div>

                        <div className='item'>
                            <p className='testi-para'><span>&#8220;</span>It gives us great pleasure to be associated with Capricorn Logistics for export freight forwarding and customs clearance. The team has shown a lot of vim, vigour and vitality to ensure our commitments and targets are addressed with high perspective.<span>&#8221;</span>
</p>
                            <p className='cust-dt2'>Senior General Manager</p>
                            <p className='cust-dtl'>Mobis India Limited</p>
                        </div>

                    </Slider>
                </div>
                <div className='col-lg-5' id='desk-img'>
                    <Slider {...testimonial_img_sld} className='test-img-sld'>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_1.png')}></img>
                        </div>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_2.png')}></img>
                        </div>
                        <div className='img-item'>
                            <img className='qut-img' src={require('../../assets/img/homepage/testimonials/testimonials_3.png')}></img>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
