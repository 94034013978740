import React from 'react'
import Header from '../../components/global/header';
import Openings_spot from '../../components/spotlight/careers/openings_spot';
import Job_grid from '../../components/sections/job_grid';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';


export default function Current_openings() {

    
  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <Openings_spot/>
          <Job_grid/>  
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
