import React from 'react'
import Header from '../../components/global/header';
import Application_spot from '../../components/spotlight/careers/application_spot';
import Application_form_comp from '../../components/sections/application_form_comp';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Cust_mang from '../../components/sections/manager_custom_brokerage';


export default function  Custom_manager_page() {

    
  return (
    <>
      <div className='pg-layout' id='custmr_serv_main'>
          <Header/>
          <Application_spot/>
          <Cust_mang />
          <h2 className="apply-nw-txt">Apply Now</h2>
          <Application_form_comp career_fld="Custom Manager Brokerage"/>
          {/* <Global_reach/> */}
          <Footer/>

      </div>
    </>
  )
}
