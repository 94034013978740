import React from 'react'
import '../../assets/css/application_form_comp.css';
import { useRef  , useState } from 'react';
import emailjs from 'emailjs-com';

export default function Application_form_comp(props) {

    const form = useRef();
    const [loading, setLoading] = useState(false)
    const [statusMessage, setStatusMessage] = useState("");
    
    const nameinput = React.useRef(null);
    const emailinput = React.useRef(null);
    const phoneinput = React.useRef(null);
    const expinput = React.useRef(null);
    const departinput = React.useRef(null);
    const fileinput = React.useRef(null);


    // All field validation error hide code 
    const handleFocus = e => {
        e.target.classList.remove("error_line");

        let er1 = document.getElementById("name_err");
        er1.classList.remove("show_error");
        
        let er2 = document.getElementById("email_err");
        er2.classList.remove("show_error");

        let er3 = document.getElementById("phone_err");
        er3.classList.remove("show_error");    

        let er5 = document.getElementById("exp_err");
        er5.classList.remove("show_error");

        let er6 = document.getElementById("depart_err");
        er6.classList.remove("show_error");

        let er7 = document.getElementById("file_err");
        er7.classList.remove("show_error");

    };

    // Phone field validation error hide code    
    const handleFocus2 = e => {
        e.target.classList.remove("error_line");

        let er4 = document.getElementById("phone_err");
        er4.classList.remove("show_error");

        let er4v = document.getElementById("phone_err1");
        er4v.classList.remove("show_error");    
    };


    // Form action code
    const sendEmail = (e) => {e.preventDefault();

        // Fields get value Code
        let name_fld= (nameinput.current.value);
        let email_fld= (emailinput.current.value);
        let phone_fld= (phoneinput.current.value);
        
        let exp_fld= (expinput.current.value);
        let dprt_fld= (departinput.current.value);
        let file_fld= (fileinput.current.value);


        
        // Phone Regex Code
        let phone_fltr = document.getElementById("phone_fld");
        var mob_regx = /^(\+\d{1,3}[- ]?)?\d{1,99}$/;

            
            
     
        // Fields validation Code
        if(name_fld == '')
        {
            let v1 = document.getElementById("name_fld");
            v1.className += " error_line";

            let er1 = document.getElementById("name_err");
            er1.className += " show_error";
        }
        else if(email_fld == '')
        {
            let v2 = document.getElementById("email_fld");
            v2.className += " error_line";

            let er2 = document.getElementById("email_err");
            er2.className += " show_error";
        }
        else if(phone_fld == '')
        {
            let v3 = document.getElementById("phone_fld");
            v3.className += " error_line";

            let er3 = document.getElementById("phone_err");
            er3.className += " show_error";
        }
        else if (!mob_regx.test(phone_fltr.value)) {
            let v4 = document.getElementById("phone_fld");
            v4.className += " error_line";

            let er4 = document.getElementById("phone_err1");
            er4.className += " show_error";
        }
        else if(exp_fld == '')
        {
            let v5 = document.getElementById("exp_fld");
            v5.className += " error_line";  
            
            let er5 = document.getElementById("exp_err");
            er5.className += " show_error";
        }
        else if(dprt_fld == '')
        {
            let v6 = document.getElementById("dprt_fld");
            v6.className += " error_line";

            let er6 = document.getElementById("depart_err");
            er6.className += " show_error";
        }
        else if(file_fld == '')
        {
            let v7 = document.getElementById("myFile");
  

            let er7 = document.getElementById("file_err");
            er7.className += " show_error";
        }
        else
        {
            fetch('https://script.google.com/macros/s/AKfycbwt3uop0VhZbveUVNygY7lpV5NPyNSoxhFRSMYlie7ZEr7ZlY5fUrUtnj3Zo_zJLLj-jQ/exec', {
                method: 'POST', 
                body: new FormData(form.current),
            
            }).then(res => {
                console.log("SUCCESSFULLY SUBMITTED")
                setLoading(false)
            })
            .catch(err => console.log(err))


            emailjs.sendForm('service_8keqc5e', 'template_gjypo8h', e.target, 'SsPYHKCapw4h-xBn_')
            .then((result) => {

                console.log(result.text);
                setStatusMessage("Your Message has been sent successfully");
                let bx = document.getElementById("thnk_box");
                bx.className += " thnk_show";

                let frm_bx = document.getElementById("app_form_bx");
                frm_bx.className += " form_hide";

            }, (error) => {

                console.log(error.text);
                setStatusMessage(`${error.text} happened`);

            });  
            e.target.reset()  
        } 

    };

  return (
    <div className='cstm-container' id='application-pg'>
        <div className='row cntct-frm-row' id='get-in-touch'>
            <div className='col-12 contact-frm-col'>

              <form  className='form_bx' id='app_form_bx' ref={form} onSubmit={sendEmail}>

                  <input type='hidden' className='hidden_fld' name='hidden_fld' value={props.career_fld}></input>
                  <div className='fld_dv'>
                      <input type='text' className='input-fld' id='name_fld' placeholder='Name' name='c_name'  ref={nameinput} onFocus={handleFocus}></input>
                      <p className='error_msg' id='name_err'>Please Enter Name</p>
                  </div>

                  <div className='fld_dv'>
                      <input type='email' className='input-fld' id='email_fld'  placeholder='Email' name='c_email'  ref={emailinput} onFocus={handleFocus}></input>
                      <p className='error_msg' id='email_err'>Please Enter Email</p>
                  </div>

                  <div className='fld_dv'>
                      <input type='text' className='input-fld' id='phone_fld' placeholder='Phone' name='c_phone'  ref={phoneinput} onFocus={handleFocus2}></input>
                      {/* { <button className='snd-otp-btn'>Send OTP {'>'} </button> } */}
                      <p className='error_msg' id='phone_err'>Please Enter Phone Numbers</p> 
                      <p className='error_msg' id='phone_err1'>Please Enter Valid Phone Numbers</p> 
                  </div>

                  <div className='fld_dv'>
                      <input type='text' className='input-fld' id='exp_fld'  placeholder='Experience' name='c_exp'  ref={expinput} onFocus={handleFocus}></input>
                      <p className='error_msg' id='exp_err'>Please Enter Experience</p>
                  </div>

                  <div className='fld_dv srvc_dv'>
                      <select className='input-fld' id='dprt_fld' name='c_depart'  ref={departinput} onFocus={handleFocus}>
                          <option value="" disabled selected>Select Department</option>
                          <option value="Finance">Finance</option>
                          <option value="Operations">Operations</option>
                          <option value="Sale">Sale</option>
                          <option value="hr">HR</option>
                          <option value="it">IT</option>
                          <option value="admin">Admin</option>
                      </select>
                      <p className='error_msg' id='depart_err'>Please Select Department</p>
                  </div>

                  <div className='fld_dv1'>
                      <label>Attach CV</label>
                      <input type="file" id="myFile" name="filename" placeholder='Attach CV'  ref={fileinput} onFocus={handleFocus}/>
                      <p className='error_msg' id='file_err'>Please Attach CV</p>
                  </div>

                  <input type='submit' value='Submit' className='submit-btn rd-btn'></input>

              </form>

              {/* <p>{statusMessage}</p> */}
              <p className='thnk' id='thnk_box'>Your Application has been sent successfully</p>

            </div>
        </div>
    </div>
  )
}