import React from 'react'
import Header from '../../../components/global/header';
import Telecom_spotlight from '../../../components/spotlight/services/telecom_spotlight';
import Telecom from '../../../components/sections/services/telecom';
import Telecom_services from '../../../components/sections/services/telecom_services';
import Global_reach from '../../../components/sections/global_reach';
import Footer from '../../../components/global/footer';
import Connect_us from '../../../components/sections/connect_us';


export default function value_added_solutions_page() {
  return (
    <>
      <Header/>
          <Telecom_spotlight/>
          <Telecom/>
          <Telecom_services/>
          <Connect_us />
          <Footer/>
    </>
  )
}
