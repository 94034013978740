import React from 'react'
import '../../assets/css/our_beliefs.css'; 

export default function Our_beliefs() {
  return (
    <>
        <div className='beliefs_main pdng-tb' id='our-beliefs'>
                        <div className='blfs-col2 mbl-img'>
                                        <h2 className='srvc-ttl'>Diversity and Inclusion</h2>
                        </div>
            <div className='cstm-container'>
                <div className='row '>
                    <div className='col-lg-9'>

                        <div className='belief-box' >
                            <img className='belief_img' src={require('../../assets/img/life-at-capricorn/diversity-at-work/gender-equality.jpg')}></img>
                            <div className='srvc_contact'>
                                <h3 className='srvc-bx-ttl'>Gender Equality</h3>
                                <p className='srvc-bx-para'>We actively encourage applications from diverse candidates and follow a strict policy to avoid gender bias during the hiring process. In addition, we have programs to support women in the workplace and provide resources to help women advance in their careers. We also look forward to partnering with organizations that promote women's empowerment.</p>
                                
                            </div>
                        </div>

                        <div className='belief-box' >
                            <img className='belief_img' src={require('../../assets/img/life-at-capricorn/diversity-at-work/capricorn_logistics_disability_inclusion.jpg')}></img>
                            <div className='srvc_contact'>
                                <h3 className='srvc-bx-ttl'>Disability Inclusion</h3>
                                <p className='srvc-bx-para'>At Capricorn Logistics, we foster Disability Inclusion. We are dedicated to providing equal employment opportunities where all employees are treated with respect and compassion. We value each of our employees' unique skills and perspectives and strive to create a culture where all employees feel welcomed and appreciated.</p>
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-3 blfs-col2 desk-img'>
                        <h2 className='srvc-ttl'>Diversity and Inclusion</h2>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
