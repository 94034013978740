import React, {useRef} from 'react'
import Header from '../components/global/header';
import Global_reach from '../components/sections/global_reach';
import Footer from '../components/global/footer';
import { useParams } from "react-router-dom";
import useFetch from "react-fetch-hook";
import Masonry from 'react-masonry-css';
import Service_search from '../components/service_search';
import $ from "jquery";
import { useEffect } from 'react';
import {NavLink} from 'react-router-dom';

import '../assets/css/search.css'

export default function Search_page() {

   const form = useRef();
   const resultinput = React.useRef(null);

   const sendResult = (e) => {
     e.preventDefault();
 
     let resultinput_var= (resultinput.current.value);
     window.location.href = "/search/" + resultinput_var;
   };

  let { name } = useParams();

    const { isLoading, data, error } = useFetch(
      `https://wordpress-819107-2935324.cloudwaysapps.com/wp-json/wp/v2/news?search=${name}`
    );

    const breakpointColumnsObj = {
      default:1,
      1100: 1,
      700: 2,
      500: 1
  };

  useEffect(() => {
     

      if ($('.my-masonry-grid_column').is(':visible'))
      {
        $('.result-error').hide();
        $('.found-txt').show();
      }
      else {         
          $('.result-error').show(); 
          $('.found-txt').hide();              
      }
  })


  return (
    <>
      <div className='pg-layout'>
          <Header/>
          <div className='media_new_sec search-bg'>
              <div className='cstm-container '>
              <div className='serch-result-dv blg-srch-rsult'>
                  <div className='row'>
                    <div className='col-12'>
                    <h3 className='found-txt'> Showing search result for &#8220;<span>{name}</span>&#8221;</h3> 
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                            >
                              {/* {data.length}  */}
                                  {data && data.map(({  title , acf , content, slug }) => (
                                    <div className='cstm-container blog-inside-page' >
                                      {isLoading && <div>A moment please...</div>}
                                  {error && (
                                      <div>{`There is a problem fetching the post data - ${error}`}</div>
                                  )}

                                  
                                          <h1 className="sec-ttl">{title.rendered}</h1>
                                          {/* <img src={acf.image} /> */}
                                          {/* <br /><br /> */}
                                          <p>{content.rendered}</p>
                                          <a className='blg-view-btn' href={"/news/" + slug}>Read more </a> 
                                      </div>
                                  ))}
                        </Masonry>
                         {/* : 'not found'} */}
                    </div>
                    <Service_search />
                   
                  </div>
                  <div className='result-error'>
                      <div className='row srch-row srch-pg-rw'>
                          <div className='col-12'>
                            
                              <form onSubmit={sendResult}> 
                                <input className='srch-input' type='text' placeholder='Search here...' ref={resultinput} />
                                      <button type="submit" className='srch-btn'>
                                  <img src={require('../assets/img/srch-vctr.png')} ></img>
                                </button>
                              </form> 
                              
                          </div>
                      </div>
                      <h2>No matching found!</h2>                    
                  </div>
              </div>
              </div>
          </div>

          <Global_reach/>
          <Footer/>

      </div>
    </>
  )
}