import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services engineering-sec'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-6 serv_img_overlap'>

                      <h3 className='air_srvc_ttl'>Combining the latest technology and human expertise to provide end-to-end engineering supply chain solutions.</h3>
                      
                  </div>
                  <div className='col-lg-6 serv_img_overlap'></div>

                  <div className='col-lg-6 serv_img_overlap'>
                  <p className='air_srvc_para'>
                  Given the complexity of the engineering sector, we consistently evaluate our supply chain techniques and strategies to ensure we deliver consistent and predictable outcomes.
                      </p>
                      <p className='air_srvc_para'>
                      Our transport and logistics solutions include end-to-end solutions for the machinery, components, and spare parts for manufacturers of engines, etc.
                      </p>
                  </div>

                  <div className='col-lg-6 serv_img_overlap'>
                  <p className='air_srvc_para'>
                  We work with clients to develop alternative transport modes that offer speed and reduced risk of delays while offering complete visibility and transparency to support the ever-changing Industrial requirements.
                      </p>
                  </div>

                                    
              </div>
          </div>
          {/* <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img> */}
      </div>
    </>
  )
}
