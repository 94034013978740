import React from 'react'
import '../../assets/css/life_capricorn_new.css'; 
import $ from "jquery";
import { useEffect } from 'react';


export default function Why_capricorn() {

    useEffect(() => {        

		$('.read-mr-btn').on("click", function() {
			$('.technology-content').css('display','block');
		});

        $('.read-mr-btn-qlty').on("click", function() {
            $('.quality-content').css('display','block');
		});

        $('.read-mr-btn-envrmt').on("click", function() {
            $('.environment-content	 ').css('display','block');
		});

        $('.content-close-btn').on("click", function() {
			$('.technology-content').css('display','none');
            $('.quality-content').css('display','none');
            $('.environment-content').css('display','none');
		});

        $('#technology-tab').on("click", function() {
            $('.img1').css({'opacity':'1', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'0', 'position':'absolute', 'top':'0'});
		});

        $('#quality-tab').on("click", function() {
            $('.img1').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'1', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'0', 'position':'absolute', 'top':'0'});
		});

        $('#esg-tab').on("click", function() {
            $('.img1').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img2').css({'opacity':'0', 'position':'absolute', 'top':'0'});
            $('.img3').css({'opacity':'1', 'position':'absolute', 'top':'0'});
		});


         
    })

  return (
    <>
        
      <div className='whyCp_main capr-lif' id='techno'>

      <div className='col-lg-6 whyCp-col2 img-content mbl-img'>
                <div className='img1'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_opportunities_2.jpg')}></img>                            
                </div>
                <div className='img2'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_growth_2.jpg')}></img>                            
                </div>
                <div className='img3'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_culture_1.jpg')}></img>                            
                </div>
      </div>


          <div className='row whyCp_row'>
              <div className='col-lg-6 whyCp-col1 bg'>
                    <div  className='sec-name-dv'>
                        <span className='dot'></span>
                        <h3 className='sec-name-ttl'>Life at Capricorn</h3>
                    </div>

                    <div className='cap_tabs_sec'>

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">                                
                                <a className="active" id="technology-tab" data-bs-toggle="tab" href="#technology" role="tab" aria-controls="technology" aria-selected="true">Culture</a>
                            </li>

                            <li className="nav-item" role="presentation">                                
                                <a className="" id="quality-tab" data-bs-toggle="tab" href="#quality" role="tab" aria-controls="quality" aria-selected="false">Growth</a>
                            </li>

                            <li className="nav-item" role="presentation">                               
                                <a className="" id="esg-tab" data-bs-toggle="tab" href="#esg" role="tab" aria-controls="esg" aria-selected="false">Opportunities</a>
                            </li>
                        </ul>


                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technology" role="tabpanel" aria-labelledby="technology-tab">
                                <p className='sec-ttl'>Culture</p>
                                <p className='cap_tabs_para'>As a global company, we value the diverse culture of our employees. We bring together people from various cultural backgrounds with a wide range of knowledge, skill set, ideas, and innovation. We share a culture of mutual respect and drive our passion for achieving a common goal of excellence and customer satisfaction.</p>
                            </div>
                            <div className="tab-pane fade" id="quality" role="tabpanel" aria-labelledby="quality-tab">
                                <p className='sec-ttl'>Growth</p>
                                <p className='cap_tabs_para'>Pursuing a career with Capricorn can give you various career growth opportunities within the organization.Being a part of passion-driven professionals, you will be challenged to go beyond the status quo to achieve more every day.</p>
                            </div>
                            <div className="tab-pane fade" id="esg" role="tabpanel" aria-labelledby="esg-tab">
                                <p className='sec-ttl'>Opportunities</p>
                                <p className='cap_tabs_para'>At Capricorn, we cherish our employees and provide them with unique opportunities to use their skills and imagination to push the boundaries of what's possible. We encourage our employees to upskill and gain knowledge and experience. We also ensure that we reward hard work time and again.</p>
                            </div>
                        </div>


                    </div>
              </div>
              <div className='col-lg-6 whyCp-col2 img-content desk-img'>
                <div className='img1'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_opportunities_2.jpg')}></img>                            
                </div>
                <div className='img2'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_growth_2.jpg')}></img>                            
                </div>
                <div className='img3'>
                    <img className='' src={require('../../assets/img/capricorn_logistics_culture_1.jpg')}></img>                            
                </div>
              </div>  
          </div>
      </div>
    </>
  )
}
