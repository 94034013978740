import React from 'react'
import '../../assets/css/why_capricorn.css'; 
import $ from "jquery";
import { useEffect } from 'react';
import Masonry from 'react-masonry-css';
import useFetch from "react-fetch-hook";
import '../../assets/css/case_study.css'; 
import '../../assets/css/news.css'
import '../../assets/css/news&blog.css';
import {NavLink} from 'react-router-dom';
import Media_news from './media_news';
import Media_blogs from './media_blogs';




export default function News_blog() {

    useEffect(() => {        
         
    })


    const breakpointColumnsObj = {
        default:3,
        1100: 3,
        700: 2,
        500: 1
    };

  return (
    <>
      <div className='whyCp_main nws-land-page' id='techno'>
          <div className='row whyCp_row'>
              <div className='col-lg-12 whyCp-col1' id='nws-blg-clm'>
                    <div  className='sec-name-dv'>
                        <span className='dot'></span>
                        <h3 className='sec-name-ttl'>News & Blog</h3>
                    </div>
                    {/* <p className='sec-ttl'>To Deliver A Complete Solution Is<br/> Tough But Not Impossible.</p> */}

                    <div className='cap_tabs_sec nws-blg-sec'>

                        <ul className="nav nav-tabs nws-blg-tab" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a className="active" id="technology-tab" data-bs-toggle="tab" href="#technology" role="tab" aria-controls="technology" aria-selected="true">News</a>
                            </li>
                            <li className="nav-item" role="presentation">                                
                                <a className="" id="quality-tab" data-bs-toggle="tab" href="#quality" role="tab" aria-controls="quality" aria-selected="false">Blog</a>
                            </li>
                        </ul>


                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technology" role="tabpanel" aria-labelledby="technology-tab">
                                <Media_news />

                                {/* <a className="read-mr-btn" href="#techno"> Read more </a> */}
                            </div>
                            <div className="tab-pane fade" id="quality" role="tabpanel" aria-labelledby="quality-tab">
                                 <Media_blogs />   
        
                            </div>
                            
                        </div>


                    </div>
              </div>
          </div>
      </div>
    </>
  )
}
