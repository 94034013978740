import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function value_added_services() {
  return (
    <>
      <div className='service_main sec-pd-tb air_freight_services value_added_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>

                      <h3 className='air_srvc_ttl'>Value-Added <br/>services include:</h3>
                      <p className='air_srvc_para'>
                      Our additional value-added services integrated with your supply chain give you the flexibility to streamline the entire logistics process, reduce cost and improve efficiency and productivity.
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_labelling.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Labelling</h3>
                                    <p className='srvc-bx-para'>
                                    We do all types of labelling, including pallet or carton labelling, inner carton labelling, racks, etc.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_kitting.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Kitting</h3>
                                    <p className='srvc-bx-para'>
                                    Capricorn Logistics helps you in kitting and product customisation as per the requirement of any local market or customers. Our kitting solutions simplify and give you the flexibility to move the operations of building SKUs, product boxing, localising, etc., into our facility. Thus, it saves time and optimizes warehouse storage space.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac repacking-spacmange'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_re_packaging.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Re-Packaging</h3>
                                    <p className='srvc-bx-para'>
                                    Our value-added service includes packaging and re-packaging solutions as per your specified requirements.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        
                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/value-added-services/capricorn_logistics_return_solutions.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Return Solutions</h3>
                                    <p className='srvc-bx-para'>
                                    Our capabilities include reverse logistics to facilitate your operations by managing return flows of goods into the facility.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>



                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='air_servc_bg' src={require('../../../assets/img/air_srvc_vector.png')}></img>
      </div>
    </>
  )
}
