import React from 'react'
import '../../../assets/css/air_freight.css'; 

export default function Value_added() {
  return (
    < >
      <div className='vlus_main pharma'>
              <div className='mbl-img'>
                        <img className='vlue_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_pharma_main.jpg')}></img>
              </div> 
          <div className='row vlus_row'>
              <div className='col-lg-6 vlue-col1 air-frght-col1'>
                  <div className='hd-dv'>
                    <h2 className='air-frgt-ttl'>Pharma</h2>                    
                  </div>
                  
              </div>
              <div className='col-lg-6 vlue-col2 air-frght-col2 desk-img'>
                <img className='vlue_img' src={require('../../../assets/img/services/industrial_and_infactory_solutions/capricorn_logistics_pharma_main.jpg')}></img>
              </div>  
          </div>
      </div>
    </>
  )
}
