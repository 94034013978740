
import './App.css';
import Home from "./pages/Home_page";
import About from "./pages/About_page";
import News_page from "./pages/newsblog_page";
import News_inside_page from "./pages/news/news_inside_page";
import Blogs_page from "./pages/blogs/blogs_page";
import Blogs_inside_page from "./pages/blogs/blogs_inside_page";
import Global_freight from "./pages/services/global_freight_forwarding_solutions_page";
import Integrated_supply from "./pages/services/integrated_supply_chain_solutions_page";
import Custom_brokerage from "./pages/services/custom_brokerage_page";
import Industrial_infactory_page from "./pages/services/industrial_infactory_solutions";
import Value_added_solutions from "./pages/services/value_added_solutions_page";
import Telecom from "./pages/services/industrial-and-infactory-solutions/telecom_page";
import Automotive from "./pages/services/industrial-and-infactory-solutions/automotive_page";
import Fmcg from "./pages/services/industrial-and-infactory-solutions/fmcg_page";

import Pharma from "./pages/services/industrial-and-infactory-solutions/pharma_page";
import Engineering from "./pages/services/industrial-and-infactory-solutions/engineering_page";
import Life_at_capricorn from "./pages/careers/life_at_capricorn_page";
import Application_form from "./pages/careers/application_form_page";
import Current_openings from "./pages/careers/current_openings";
import Customer_service from "./pages/careers/customer_services_page";
import Accounts_assistant from "./pages/careers/accounts_assistant_page";
import Assistant_manager from "./pages/careers/assistant_manager_page";
import Bdm_mumbai from "./pages/careers/business_development_manager_mumbai_page";
import Bdm_dubai from "./pages/careers/business_development_manger_dubai_page";
import Bdm_melb from "./pages/careers/business_development_manger_melbourne_page";
import Cust_man from "./pages/careers/custom_manager_page";
import Cust_ser_ex from "./pages/careers/customer_service_executive_dubai_page";
import Rev_bill_ex from "./pages/careers/revenue_billing_executive_page";
import Portfolio from "./pages/portfolio_page";
import Contact from "./pages/contact_page";
import Services from "./pages/Services_page";
import Arabic_page from "./pages/Arabic_lang_page"
import Privacy_policy from './pages/privacy_page';
import Terms_page from './pages/terms_page';
import Search_page from "./pages/search_page"
import '../src/assets/css/our_values.css';
import '../src/assets/css/contact_sec.css';



import Our_network from "./pages/about_us/our_network";

// import Scroll from './components/sections/smoothscroll';
import { BrowserRouter, Route, Routes, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import $ from "jquery";
import { initializedState } from 'react-slick/lib/utils/innerSliderUtils';
import ScrollToTop from '../src/components/ScrollToTop'
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';


function App() {

  //const form = useRef();
  const resultinput = React.useRef(null);

  const sendResult = (e) => {
    e.preventDefault();

    let resultinput_var = (resultinput.current.value);
    window.location.href = "/search/" + resultinput_var;
  };

  //form submission code Start here

  const form = useRef();
  const [loading, setLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("");

  const nameinput = React.useRef(null);
  const companyinput = React.useRef(null);
  const emailinput = React.useRef(null);
  const phoneinput = React.useRef(null);
  const serviceinput = React.useRef(null);
  const msginput = React.useRef(null);


  // All field validation error hide code 
  const handleFocus = e => {
    e.target.classList.remove("error_line");

    let er1 = document.getElementById("nm_err");
    er1.classList.remove("show_error");

    let er2 = document.getElementById("cmp_err");
    er2.classList.remove("show_error");

    let er3 = document.getElementById("eml_err");
    er3.classList.remove("show_error");

    let er5 = document.getElementById("srvc_err");
    er5.classList.remove("show_error");

    let er6 = document.getElementById("msg_err");
    er6.classList.remove("show_error");

  };

  // Phone field validation error hide code    
  const handleFocus1 = e => {
    e.target.classList.remove("error_line");

    let er4 = document.getElementById("phn_err");
    er4.classList.remove("show_error");

    let er4v = document.getElementById("phn_err1");
    er4v.classList.remove("show_error");
  };

  // Form action code
  const sendEmail = (e) => {
    e.preventDefault();



    // Fields get value Code
    let name_fld = (nameinput.current.value);
    let company_fld = (companyinput.current.value);
    let email_fld = (emailinput.current.value);
    let phone_fld = (phoneinput.current.value);
    let service_fld = (serviceinput.current.value);
    let msg_fld = (msginput.current.value);



    // Phone Regex Code
    let phone_fltr = document.getElementById("phone_fld");
    var mob_regx = /^(\+\d{1,3}[- ]?)?\d{1,99}$/;




    // Fields validation Code
    if (name_fld == '') {
      let v1 = document.getElementById("name_fld");
      v1.className += " error_line";

      let er1 = document.getElementById("nm_err");
      er1.className += " show_error";
    }

    else if (company_fld == '') {
      let v2 = document.getElementById("company_fld");
      v2.className += " error_line";

      let er2 = document.getElementById("cmp_err");
      er2.className += " show_error";
    }
    else if (email_fld == '') {
      let v3 = document.getElementById("email_fld");
      v3.className += " error_line";

      let er3 = document.getElementById("eml_err");
      er3.className += " show_error";
    }
    else if (phone_fld == '') {
      let v4 = document.getElementById("phone_fld");
      v4.className += " error_line";

      let er4 = document.getElementById("phn_err");
      er4.className += " show_error";
    }
    else if (!mob_regx.test(phone_fltr.value)) {
      let v4 = document.getElementById("phone_fld");
      v4.className += " error_line";

      let er4 = document.getElementById("phn_err1");
      er4.className += " show_error";
    }
    else if (service_fld == '') {
      let v5 = document.getElementById("service_fld");
      v5.className += " error_line";

      let er5 = document.getElementById("srvc_err");
      er5.className += " show_error";
    }
    else if (msg_fld == '') {
      let v6 = document.getElementById("msg_fld");
      v6.className += " error_line";

      let er6 = document.getElementById("msg_err");
      er6.className += " show_error";
    }
    else {

      fetch('https://script.google.com/macros/s/AKfycbwKwXlSn3z8LwYXy3ezVmyTc6k9b00chNW0vGKoIifTwjs23OeOE7guz8cfLdaCMVeC/exec', {
        method: 'POST',
        body: new FormData(form.current),

      }).then(res => {
        console.log("SUCCESSFULLY SUBMITTED")
        setLoading(false)
      })
        .catch(err => console.log(err))


      emailjs.sendForm('service_8keqc5e', 'template_nhxyute', e.target, 'SsPYHKCapw4h-xBn_')
        .then((result) => {

          console.log(result.text);
          // setStatusMessage("Your Message has been sent successfully");
          let bx = document.getElementById("thnk_box");
          bx.className += " thnk_show";

          let frm_bx = document.getElementById("form_bx");
          frm_bx.className += " form_hide";

        }, (error) => {

          console.log(error.text);
          setStatusMessage(`${error.text} happened`);

        });
      e.target.reset()



    }
  };

  //form submission code End here



  useEffect(() => {

    $('.search-dv').on("click", function (e) {
      $('.search_div').css('z-index', '999');
      $('.search_div').css('opacity', '1');
    });

    $('.cls-btn').on("click", function (e) {
      $('.search_div').css('z-index', '-2');
      $('.search_div').css('opacity', '0');
    });

    $('.enq-btn').on("click", function () {
      $('#get-in-touch').css('opacity', '1').fadeIn();
      $('#get-in-touch').css('display', 'block').fadeIn();
      // $("body").css("overflow", "hidden");
    });

    $('.close').on("click", function () {
      $('#get-in-touch').css('opacity', '0').fadeOut();
      $('#get-in-touch').css('display', 'none').fadeOut();
      $("body").css("overflow", "auto");
    });

    $('p, h1').each(function () {
      var string = $(this).html();
      string = string.replace(/ ([^ ]*)$/, '&nbsp;$1');
      $(this).html(string);
    });



    //------------- Image Popup localStorage code START
    const popUp = document.querySelector('.img_pop_row');
    const popUpCloseButton = document.querySelector('.img_pp_cls');
    let currentTime = new Date().getTime();

    popUpCloseButton.onclick = function () {
      popUp.classList.add('hidden');
      localStorage.setItem('popUpHidden', true);
      localStorage.setItem('timeClosed', currentTime);
    };

    if (localStorage.getItem('popUpHidden', true)) {
      popUp.classList.add('hidden');
    }

    const closedTime = parseInt(localStorage.getItem('timeClosed'));
    const oneDay = 86400; //24hrs in ms

    if (currentTime >= closedTime + oneDay) {
      localStorage.setItem('popUpHidden', false);
      popUp.classList.remove('hidden');
    }
    //------------- Image Popup localStorage code END



    const concernedElement = document.querySelector(".contact-frm-col");

    document.addEventListener("mousedown", (event) => {
      if (concernedElement.contains(event.target)) {
        // console.log("Clicked Inside");
      } else {
        $('#get-in-touch').css('display', 'none').fadeOut();
        $("body").css("overflow", "auto");
      }
    });

  })


  return (
    <>
      {/* <Scroll/> */}
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services/global-freight-forwarding-solutions" element={<Global_freight />} />
          <Route path="/services/integrated-supply-chain-solutions" element={<Integrated_supply />} />
          <Route path="/services/custom-brokerage" element={<Custom_brokerage />} />
          <Route path="/services/value-added-solutions" element={<Value_added_solutions />} />
          <Route path="/services/industrial-and-infactory-solutions" element={<Industrial_infactory_page />} />
          <Route path="/services/industrial-and-infactory-solutions/telecom" element={<Telecom />} />
          <Route path="/services/industrial-and-infactory-solutions/automotive" element={<Automotive />} />
          <Route path="/services/industrial-and-infactory-solutions/engineering" element={<Engineering />} />
          <Route path="/services/industrial-and-infactory-solutions/fmcg" element={<Fmcg />} />

          <Route path="/services/industrial-and-infactory-solutions/pharma" element={<Pharma />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/careers/life-at-capricorn" element={<Life_at_capricorn />} />
          <Route path="/careers/application-form" element={<Application_form />} />
          <Route path="/careers/current-openings" element={<Current_openings />} />
          <Route path="/careers/current-openings/customer-service-executive" element={<Customer_service />} />
          <Route path="/careers/current-openings/accounts-assistant-executive" element={<Accounts_assistant />} />
          <Route path="/careers/current-openings/assistant-manager" element={<Assistant_manager />} />
          <Route path="/careers/current-openings/business-development-manager-mumbai" element={<Bdm_mumbai />} />
          <Route path="/careers/current-openings/business-development-manager-dubai" element={<Bdm_dubai />} />
          <Route path="/careers/current-openings/business-development-manager-melbourne" element={<Bdm_melb />} />
          <Route path="/careers/current-openings/manager-custom-brokerage" element={<Cust_man />} />
          <Route path="/careers/current-openings/customer-service-executive-dubai" element={<Cust_ser_ex />} />
          <Route path="/careers/current-openings/revenue-billing-executive" element={<Rev_bill_ex />} />
          <Route path="/blog" element={<News_page />} />
          <Route path="/news/:slug" element={<News_inside_page />} />
          <Route path="/media_center/blogs" element={<Blogs_page />} />
          <Route path="/media_center/blogs/blogs_inside_page" element={<Blogs_inside_page />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about/our-network" element={<Our_network />} />
          <Route path="/arabic/home" element={<Arabic_page />} />
          <Route path="/privacy-policy" element={<Privacy_policy />} />
          <Route path="/terms-conditions" element={<Terms_page />} />
          <Route path="/search/:name" element={<Search_page />} />
        </Routes>
      </BrowserRouter>

      <div className='search_div'>

        <div className='cls-btn'>
          <span className='line1'></span>
          <span className='line2'></span>
        </div>
        <div className='row srch-row'>
          <div className='col-12'>

            <form onSubmit={sendResult}>
              <input className='srch-input' type='text' placeholder='Search here...' ref={resultinput} />
              <button type="submit" className='srch-btn'>
                <img src={require('./assets/img/srch-vctr.png')} ></img>
              </button>
            </form>

          </div>
        </div>
      </div>

      <a role="button" class="enq-btn">Get a Quote</a>


      {/* Get a Quote Form popup*/}
      <div class="pop-up">
        <div className='cstm-container'>
          <div className='row cntct-frm-row' id='get-in-touch'>

            <div className='col-12 contact-frm-col'>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">×</button>
              <h2 className='cntct-ttl'>Get In Touch</h2>
              <form ref={form} onSubmit={sendEmail} className='form_bx' id='form_bx'>

                <div className='fld_dv'>
                  <input type='text' className='input-fld' id='name_fld' placeholder='Name' name='name_fld' ref={nameinput} onFocus={handleFocus} ></input>
                  <p className='error_msg' id='nm_err'>Please Enter Name</p>
                </div>

                <div className='fld_dv'>
                  <input type='text' className='input-fld' id='company_fld' placeholder='Company' name='company_fld' ref={companyinput} onFocus={handleFocus}></input>
                  <p className='error_msg' id='cmp_err'>Please Enter Company Name</p>
                </div>

                <div className='fld_dv'>
                  <input type='email' className='input-fld' id='email_fld' placeholder='Email' name='email_fld' ref={emailinput} onFocus={handleFocus}></input>
                  <p className='error_msg' id='eml_err'>Please Enter Email</p>
                </div>

                <div className='fld_dv'>
                  <input type='text' className='input-fld' id='phone_fld' placeholder='Phone' name='phone_fld' ref={phoneinput} onFocus={handleFocus1}></input>
                  <p className='error_msg' id='phn_err'>Please Enter Phone Numbers</p>
                  <p className='error_msg' id='phn_err1'>Please Enter Valid Phone Numbers</p>
                </div>

                <div className='fld_dv srvc_dv'>
                  <select className='input-fld' id='service_fld' name='service_fld' ref={serviceinput} onFocus={handleFocus}>
                    <option value="" disabled selected>Select Service</option>
                    <option value="Redefining Global Freight Forwarding Solutions">Redefining Global Freight Forwarding Solutions</option>
                    <option value="Integrated Supply Chain Solutions">Integrated Supply Chain Solutions</option>
                    <option value="Customs Clearance and Insurance">Customs Clearance and Insurance</option>
                    <option value="Industrial & Infactory Solutions">Industrial & Infactory Solutions</option>
                    <option value="Value Added Services">Value Added Services</option>
                  </select>
                  <p className='error_msg' id='srvc_err'>Please Select Service</p>
                </div>

                <div className='fld_dv1'>
                  <textarea className='txtarea' id='msg_fld' placeholder='Comment' name='msg_fld' ref={msginput} onFocus={handleFocus}></textarea>
                  <p className='error_msg' id='msg_err'>Please Enter Comment</p>
                </div>

                <input type='submit' value='Submit' className='submit-btn rd-btn'></input>

              </form>
              {/* <p>{statusMessage}</p> */}
              <p className='thnk' id='thnk_box'>Your Message has been sent successfully</p>
            </div>
          </div>
        </div>
      </div>


      {/* Image Popup */}
      <div class="pop-up image_popup ads_pop_up_hide">
        <div className='cstm-container'>
          <div className='row img_pop_row'>

            <div className='col-12 img_pop_col'>
              <button type="button" class="close img_pp_cls" data-dismiss="modal" aria-label="Close">×</button>
              <a href='https://www.iicsexpo.com/registrations' target='_blank'>
                <img src={require('./assets/img/capricorn_show.png')}></img>
              </a>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}


export default App;
