import React from 'react'
import '../../assets/css/our_beliefs.css'; 

export default function Our_beliefs() {
  return (
    <>
        <div className='beliefs_main pdng-tb' id='our-beliefs'>
                        <div className='blfs-col2 mbl-img'>
                                        <h2 className='srvc-ttl'>Our <br/>Beliefs</h2>
                                        <p className='belief-sub-ttl'>We are relentless. Our passion helps us to deliver seamless logistics solutions every time.
                                        </p>
                        </div>
            <div className='cstm-container'>
                <div className='row '>
                    <div className='col-lg-9'>

                        <div className='belief-box' >
                            <img className='belief_img' src={require('../../assets/img/vision.png')}></img>
                            <div className='srvc_contact'>
                                <h3 className='srvc-bx-ttl'>Our Vision</h3>
                                <p className='srvc-bx-para'>To be a global logistics leader providing seamlessly integrated supply chain solutions through our network keeping in mind customer focus, employee participation, technology, innovation, and environment–friendly approach.</p>
                                
                            </div>
                        </div>

                        <div className='belief-box' >
                            <img className='belief_img' src={require('../../assets/img/mission.png')}></img>
                            <div className='srvc_contact'>
                                <h3 className='srvc-bx-ttl'>Our Mission</h3>
                                {/* <p className='srvc-bx-para'>We understand your need for customs required for importation, exportation and mobility of
                                goods, and solely take charge of all the formalities to provide you maximum convenience. </p> */}
                                <ul className='mission-list'>
                                    <li>Ensure Customer Delight</li>
                                    <li>Make Capricorn the Employer of Choice</li>
                                    <li>To be Quality-driven and Process-oriented</li>
                                    <li>Have a Humane Touch to our Doing</li>
                                    <li>Consistent Performance towards Profitable Growth</li>
                                </ul>


                            </div>
                        </div>

                    </div>
                    <div className='col-lg-3 blfs-col2 desk-img'>
                        <h2 className='srvc-ttl'>Our <br/>Beliefs</h2>
                        <p className='belief-sub-ttl'>We are relentless. Our passion helps us to deliver seamless logistics solutions every time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
