import React from 'react'
import Header from '../../components/global/header';
import Value_added_spotlight from '../../components/spotlight/services/value_added_spotlight';
import Value_added from '../../components/sections/services/value_added';
import Value_added_services from '../../components/sections/services/value_added_services';
import Global_reach from '../../components/sections/global_reach';
import Footer from '../../components/global/footer';
import Connect_us from '../../components/sections/connect_us';

export default function value_added_solutions_page() {
  return (
    <>
      <Header/>
          <Value_added_spotlight/>
          <Value_added/>
          <Value_added_services/>
          <Connect_us />
          <Footer/>
    </>
  )
}
