import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Land_freight_services() {
  return (
    <>

<div className='service_main sec-pd-tb air_freight_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>
                      <h3 className='air_srvc_ttl'>Deliver operational excellence with an array of Land Freight Services</h3>
                      <p className='air_srvc_para'>
                      At Capricorn, we offer a range of flexible road and rail transport solutions, providing fast and secure connections across geographies.
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/land-freight/capricorn_logistics_road_transport.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Road Transport</h3>
                                    <p className='srvc-bx-para'>Our road transport offers standard services, including LTL (Less-than-Truck Load), FTL (Full-Truck Load), temperature-controlled, and highly customized road transport solutions for perishable and special shipments.</p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/global_freight_forwarding_solutions/land-freight/capricorn_logistics_rail_transport.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Rail Transport</h3>
                                    <p className='srvc-bx-para'>Our Rail transport solutions offer secure, reliable, and cost-effective freight transportation that helps you meet your commitment to become an efficient organization.</p>
                                    
                                </div>
                            </div>
                        </div>

                        
                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='land_servc_bg' src={require('../../../assets/img/Services_Vectors_land.png')}></img>
      </div>

    </>
  )
}
