import React from 'react'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/home_spotlight.css'; 
import '../../assets/css/arabic_spotlight.css';
import { HashLink as Link } from 'react-router-hash-link';


export default function Arabic_spotlight() {

    const settings = {
        arrows: true,
        infinite: true,
        slidesToShow: 1,
	    slidesToScroll: 1,
        speed: 500
         
    };
    return (
    <>
        <div className='spotlight-main' id='arabic-pg'>
            
            {/* <div className='overlay-img'>
                <img className='overly-img' src={require('../../assets/img/overlay.png')}></img>
            </div> */}
            <Slider {...settings} className='spotlight-sld'>
                
                <div className='sld1 sld'>
                    {/* <div className='overlay-img'> */}
                    <video loop autoPlay playsinline muted>
                            <source src={require('../../assets/video/CL_Spotlight_Video.mp4')} type="video/mp4" />
                    </video>
                        {/* <img className='overly-img' src={require('../../assets/img/overlay.png')}></img> */}
                    {/* </div> */}
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'>أفضل مزود حلول لوجستية متكاملة وسلسلة التوريد.</h1>
                        <a href='/services/integrated-supply-chain-solutions' className='rd-btn spt-btn'>خدماتنا</a>
                        <a className='brder-btn spt-btn' href="/about">معلومات عنا</a>
                    </div>
                </div>
                <div className='sld3 sld'>
                    <div className='overlay-img'>
                        <img className='overly-img' src={require('../../assets/img/overlay.png')}></img>
                    </div>
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'> حلول سريعة ومعتمدة ومرنة.</h1>
                        <p className='spt-dcrp'>تحمل Capricorn Logistics إرثًا لأكثر من عقدين من الزمن في تقديم عمليات لوجستية وشحن البضائع عبر البلدان. ساعدت خدماتنا اللوجستية عملائنا على تحقيق التميز التشغيلي وتعزيز رضا العملاء.</p>
                        <Link href='/services/global-freight-forwarding-solutions#sea_freight' className='rd-btn spt-btn'>خدماتنا</Link>
                        <a className='brder-btn spt-btn' href="/about">معلومات عنا</a>
                    </div>
                </div>
                <div className='sld2 sld'>
                    <div className='overlay-img'>
                        <img className='overly-img' src={require('../../assets/img/overlay.png')}></img>
                    </div>
                    <div className='sld-cnt'>
                        <h1 className='spt-ttl'>حلول محسّنة لنموك</h1>
                        <p className='spt-dcrp'>في Capricorn Logistics ، نساعد الشركات على النمو من خلال القيام بأكثر من مجرد نقل البضائع. نحن نبني الجيل التالي من العمليات اللوجستية لمساعدة العالم وأنت على التحرك بشكل أسرع دون أي خلل في سلسلة التوريد.</p>
                        <a href='/services/value-added-solutions' className='rd-btn spt-btn'>خدماتنا</a>
                        <a className='brder-btn spt-btn' href="/about">معلومات عنا</a>
                    </div>
                </div>
                
            </Slider>
            
        </div>
    </>
  )
}
