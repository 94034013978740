import React from 'react'
import {NavLink} from 'react-router-dom';
import '../../assets/css/media_news.css'; 
import '../../assets/css/job_grid.css';
import Masonry from 'react-masonry-css';
import useFetch from "react-fetch-hook";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';
 

export default function Job_grid() {
  
 

    const { isLoading, data, error } = useFetch(
        "https://phpstack-608738-2577092.cloudwaysapps.com/wordpress/wp-json/wp/v2/careers"
    );
    
    const breakpointColumnsObj = {
        default:3,
        1100: 3,
        700: 2,
        500: 1
    };
  return (
    <>
    <div className='media_new_sec currnt-opng'>
    <div className='cstm-container '>
       <div className='row'>
            <div className='co-lg-12'>

            {isLoading && <div>A moment please...</div>}
            {error && (
                <div>{`There is a problem fetching the post data - ${error}`}</div>
            )}
            
            </div>
        </div> 

        <div className='row'>
            <div className='col-12'>
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                    >  
                        
                            <div className='news-item nws-grd'>                             
                                
                                <div className='nw-prt2'>
                                        <h2>Customer Service Executive</h2>
                                        <h5 className="job-descrp">Location</h5>
                                        <ul class="ul-styl">
                                            <li>Mumbai</li>
                                        </ul>
                                        <h5 className="job-descrp">Experience</h5>
                                        <ul class="ul-styl">
                                            <li>1 – 4 years</li>
                                        </ul>
                                        <a className="apply-nw" href="/careers/current-openings/customer-service-executive" data-toggle="tab">View details</a>
                                </div>

                            </div>
                            

                            <div className='news-item nws-grd'>                          

                                <div className='nw-prt2'>
                                        <h2>Accounts assistant / executive</h2>
                                        <h5 className="job-descrp">Location</h5>
                                        <ul class="ul-styl">
                                            <li>Mumbai</li>
                                        </ul>
                                        <h5 className="job-descrp">Experience</h5>
                                        <ul class="ul-styl">
                                            <li>1 – 4 years</li>
                                        </ul>
                                        <a className="apply-nw" href="/careers/current-openings/accounts-assistant-executive" data-toggle="tab">View details</a>
                                </div>

                            </div>


                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Assistant manager</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Mumbai</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/assistant-manager" data-toggle="tab">View details</a>
                                </div>

                            </div>

                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Business Development Manager</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Mumbai & All over India</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/business-development-manager-mumbai" data-toggle="tab">View details</a>
                                </div>

                            </div>

                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Business Development Manager</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Dubai</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/business-development-manager-dubai" data-toggle="tab">View details</a>
                                </div>

                            </div>

                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Business Development Manager</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Melbourne - Australia</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/business-development-manager-melbourne" data-toggle="tab">View details</a>
                                </div>

                            </div>

                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Manager - Custom Brokerage</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Mumbai</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/manager-custom-brokerage" data-toggle="tab">View details</a>
                                </div>

                            </div>

                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Customer Service Executive</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Dubai</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/customer-service-executive-dubai" data-toggle="tab">View details</a>
                                </div>

                            </div>


                            <div className='news-item nws-grd'>                                 
                                

                                <div className='nw-prt2'>
                                    <h2>Revenue / Billing Executive</h2>
                                    <h5 className="job-descrp">Location</h5>
                                    <ul class="ul-styl">
                                        <li>Mumbai</li>
                                    </ul>
                                    <h5 className="job-descrp">Experience</h5>
                                    <ul class="ul-styl">
                                        <li>1 – 4 years</li>
                                    </ul>
                                    <a className="apply-nw" href="/careers/current-openings/revenue-billing-executive" data-toggle="tab">View details</a>
                                </div>

                            </div>
                       

                </Masonry>
            </div>
        </div>
    </div> 
    </div>
                  
    </>
  )
}
