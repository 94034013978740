import React from 'react'
import { NavLink } from 'react-router-dom';
import '../../assets/css/media_news.css';
import '../../assets/css/job_grid.css';
import '../../assets/css/customer_service.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { HashLink as Link } from 'react-router-hash-link';


export default function Customer_service_executive_dubai() {


    return (
        <>
            <div className='media_new_sec' id='custmr_serv'>
                <div className='cstm-container '>
                    <div className='row'>
                        <div className='co-lg-12'>

                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>

                            <div className='news-item nws-grd'>

                                <div className='nw-prt2'>
                                    <h2>Customer Service Executive - Dubai</h2>
                                    <h5 className="job-descrp main-descrp">Job Description</h5>
                                    <ul class="ul-styl">
                                        <li>Co-ordination and rate negotiation with Shipping Lines and Air lines.</li>
                                        <li>Follow-up with Clients.</li>
                                        <li>Providing the quotation/rates to the clients.</li>
                                        <li>Taking the Cargo Bookings from customers.</li>
                                        <li>Preparing MAWB, HAWB, MBL, HBL (Drafts &amp;Original)</li>
                                        <li>Providing the Vessel schedule and Cargo Tracking.</li>
                                        <li>Maintaining Registers.</li>
                                        <li>Handling overseas coordination for nomination shipment.</li>
                                        <li>Plan the shipments/ consignments dispatches as per the schedules.</li>
                                        <li>Follow up with the shipper for cargo readiness; accordingly prepare the quotation Air/Shipping</li>
                                        <li>Line freight &amp; Vessel schedule, for Agent.</li>
                                        <li>Follow-ups with airlines for CSR reports &amp; updating same thing to accounts.</li>
                                        <li>Maintaining airway bill stocks.</li>
                                        <li>Coordinate with factory &amp; CHA for stuffing plan, transporters and DO boys.</li>
                                        <li>Communicating with overseas agents through mails for shipment pickups, Sending Pre-Alerts to</li>
                                        <li>Overseas and taking approval from Overseas Agent.</li>
                                        <li>Asking for Invoice, Packing List , S/bill copies, BL draft copies from shipper.</li>
                                        <li>Accordingly prepare booking for S/Line</li>
                                        <li>Follow up with S/Line for D/O , Load list , SOB</li>
                                        <li>Send the messages of shipment tracking with all details to Agent &amp; shipper</li>
                                        <li>Follow up for B/L , Payment etc.</li>
                                        <li>Co-ordination with accounts team for billing</li>
                                    </ul>

                                    <div class="row  job-detail">
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Department</h5>
                                            <ul class="ul-styl">
                                                <li>Accounts & finance</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Location</h5>
                                            <ul class="ul-styl">
                                                <li>Dubai</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Qualification</h5>
                                            <ul class="ul-styl">
                                                <li>Bcom graduate</li>
                                            </ul>
                                        </div>
                                        <div class="col-sm">
                                            <h5 className="job-descrp">Experience</h5>
                                            <ul class="ul-styl">
                                                <li>1 – 4 years</li>
                                            </ul>
                                        </div>
                                    </div>

                                    {/* <Link className="apply-nw" to="/application_form" data-toggle="tab">Read more</Link> */}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



