import React from 'react'
import '../../assets/css/life_capricorn_new.css'; 
import $ from "jquery";
import { useEffect } from 'react';


export default function Journey_tabs_mobl() {

    useEffect(() => {        

		$('.read-mr-btn').on("click", function() {
			$('.technology-content').css('display','block');
		});

        $('.read-mr-btn-qlty').on("click", function() {
            $('.quality-content').css('display','block');
		});

        $('.read-mr-btn-envrmt').on("click", function() {
            $('.environment-content	 ').css('display','block');
		});

        $('.content-close-btn').on("click", function() {
			$('.technology-content').css('display','none');
            $('.quality-content').css('display','none');
            $('.environment-content').css('display','none');
		});

        $('#technology-tab').on("click", function() {
            $('.img1').css('display','block');
            $('.img2').css('display','none');
            $('.img3').css('display','none');
		});

        $('#quality-tab').on("click", function() {
            $('.img1').css('display','none');
            $('.img2').css('display','block');
            $('.img3').css('display','none');
		});

        $('#esg-tab').on("click", function() {
            $('.img1').css('display','none');
            $('.img2').css('display','none');
            $('.img3').css('display','block');
		});


         
    })

  return (
    <> 
     <div id='ourjourney'></div>
      <div className='whyCp_main mobb-tabs' id='techno'>         
          <div className='row whyCp_row'>
              <div className='col-lg-12 whyCp-col1 bg'>
                    <div  className='sec-name-dv'>
                        <h2 className='jrny-mobl-ttl'>Our Journey Of Growth.</h2>
                    </div>

                    <div className='cap_tabs_sec'>

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">                                
                                <a className="active" id="technology-tab" data-bs-toggle="tab" href="#technology" role="tab" aria-controls="technology" aria-selected="true">Clients</a>
                            </li>

                            <li className="nav-item" role="presentation">                                
                                <a className="" id="quality-tab" data-bs-toggle="tab" href="#quality" role="tab" aria-controls="quality" aria-selected="false">Employees</a>
                            </li>

                            <li className="nav-item" role="presentation">                               
                                <a className="" id="esg-tab" data-bs-toggle="tab" href="#esg" role="tab" aria-controls="esg" aria-selected="false">Partners</a>
                            </li>
                        </ul>


                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="technology" role="tabpanel" aria-labelledby="technology-tab">
                                <div className='clint-box1'>
                                    <p>From<br />50 clients<br /> in 2001</p>
                                    <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                </div>
                                <div className='clint-box2'>
                                    <p>To<br />134 clients<br /> in 2021</p>
                                    <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="quality" role="tabpanel" aria-labelledby="quality-tab">
                                <div className='clint-box1'>
                                        <p>From<br />17 Employees <br /> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                    <div className='clint-box2'>
                                        <p>To<br />1200 Employees <br /> in 2022</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="esg" role="tabpanel" aria-labelledby="esg-tab">
                                <div className='clint-box1'>
                                        <p>From<br />50 clients<br /> in 2001</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                    </div>
                                    <div className='clint-box2'>
                                        <p>To<br />134 clients<br /> in 2021</p>
                                        <img className='clnt-arrow' src={require('../../assets/img/clnt-arrow.png')}></img>
                                </div>  
                            </div>
                        </div>


                    </div>
              </div>
          </div>
      </div>
    </>
  )
}
