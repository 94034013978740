import React from 'react'
import '../../../assets/css/air_freight_services.css'; 
import {NavLink} from 'react-router-dom';
import {  useRef ,useEffect } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Express_services() {
  return (
    <>

<div className='service_main sec-pd-tb air_freight_services'>
          <div className='cstm-container'>
              <div className='row srvc-rw'>

                  <div className='col-lg-4 air-srvc-col1'>
                      <h3 className='air_srvc_ttl'>Fast and On-Time Delivery Express Logistics with Real-time Tracking</h3>
                      <p className='air_srvc_para'>
                      Our fast and on-time delivery express logistics with real-time tracking works on the principle of committed transit time, complete visibility of shipment lifecycle, and consistency in meeting high-quality delivery service.  
                      </p>
                  </div>

                  <div className='col-lg-8 air-srvc-col2'>
                      <div className='row'>

                        <div className='col-lg-6 top-spac door'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logistics_international_express.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>International Express</h3>
                                    <p className='srvc-bx-para'>
                                    We are a carrier-neutral based service with a wide range of well-maintained multiple networks across the globe.For every international express, we do a complete carrier analysis to determine the best possible mode of transportation to offer you guaranteed delivery at the best cost and in the shortest time. We provide the latest tracking facility to help you monitor your shipment in real-time. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac large-ship'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logisitcs_parcel_express.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Parcel Express </h3>
                                    <p className='srvc-bx-para'>
                                    Our experience in parcel delivery across countries makes us one of the best express cargo companies to deliver with utmost safety and on time. Our domain experts at Capricorn ensure that we provide a complete understanding of the procedures required to get your parcels delivered, even in the remotest of locations.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac small-ship visible-hde'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logistics_express.png')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Vendor Managed Inventory</h3>
                                    <p className='srvc-bx-para'>
                                    Our proactive strategic planning and implementation enable you to keep your paperwork to the minimum, thus bringing value to your business with seamless delivery.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6 top-spac stand-air'>
                            <div className='service-box'>
                                <img className='servc_img' src={require('../../../assets/img/services/integrated-supply-chain-solutions/express/capricorn_logistics_document_express.jpg')}></img>
                                <div className='srvc_contact'>
                                    <h3 className='srvc-bx-ttl'>Document Express</h3>
                                    
                                    <p className='srvc-bx-para'>
                                    We understand the importance of confidential and time-sensitive document delivery. Our proactive strategic planning and implementation enable you to keep your paperwork to the minimum, thus bringing value to your business with seamless delivery.
                                    </p>
                                    
                                </div>
                            </div>
                        </div>

                        
                      </div>
                  </div>

                  
              </div>
          </div>
          <img className='land_servc_bg' src={require('../../../assets/img/Services_Vectors_land.png')}></img>
      </div>

    </>
  )
}
