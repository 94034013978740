import React from 'react'
import Slider from 'react-slick';
import { useState, useRef ,useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../assets/css/about_sec.css'; 
import Journey_slider from './journey_slider';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function About() {
    gsap.registerPlugin(ScrollTrigger); 

    const text_fade = useRef(null);
    const slide_up = useRef(null);

    useEffect(() => {
        gsap.to("#fade", {
        opacity: 1,
        duration: 1,
        ease: "linear",
        delay: 0.5,
        scrollTrigger: {
            trigger: "#fade",
            markers: true
        }
        });
    }, []);

    useEffect(() => {
        gsap.to("#slide_up", {   
        y: -100,
        duration: 0.5,
        ease: "linear",
        delay: 0.6,
        scrollTrigger: {
            trigger: "#slide_up",
            markers: true
        }
        });
    }, []);

    const abt_settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500
            
    };
  return (
    <>
        <div className='about-main pdng-tb'>
        
            <img ref={slide_up} id='slide_up' className='abt-bg-vct' src={require('../../assets/img/about-vct.png')}></img>
            {/* Part 1 */}
            <div className='cstm-container '>
            <div className='row'>
                <div className='col-lg-7 abt-col1' ref={text_fade} id='fade'>
                    <div  className='sec-name-dv'>
                        <span className='dot'></span>
                        <h3 className='sec-name-ttl'>About us</h3>
                    </div>
                    <p className='sec-ttl'>Capricorn Logistics' comprehensive logistics services keep your supply chain future-ready with innovation and ahead of your competition.</p>

                    <p className='dcrb'>Founded in 2001, Capricorn Logistics started its operations with the vision to offer the best in class logistics services in India. Over the years, our passion for being the preferred strategic logistics partner has been assisted by our latest integrated technology. Our customer-first approach has helped us gain the trust of several large enterprises and SMEs across geographies. Currently, we have our presence across 32 Indian and ten overseas offices in major economies like the United States of America, Europe, China, Africa, and the United Arab Emirates. Our dedicated teams worldwide are aligned in our strong vision to provide continual improvement to the world's supply chains.</p>
                    <p className='dcrb'>Your business makes you unique. That's why our solutions are uniquely crafted to your every supply chain requirement. Our emphasis is to use the latest technology to optimize your supply chain and open up new growth avenues.</p>
                    <p className='dcrb'>Our services capabilities extend from Air Freight, Ocean Freight, Surface Transportation, Door To Door Express Service, Customs Brokerage, 3PL, 4PL & 5PL to Industrial Solutions. With a host of services to choose from, we ensure your shipments reach you accurately, on time, every time.</p>  
                    <a className='rd-btn explore-btn' href="/about">Explore Capricorn</a>
                </div>
                <div className='col-lg-5 abt-col2' ref={text_fade} id='fade'>
                    <img  className='world-img' src={require('../../assets/img/world.png')}></img>
                    <Slider {...abt_settings} className='about-sld'>
                        <div className='mission-sld abt-sld'>
                           <h2>Our Mission</h2>
                           <p><ul class="mission-list"><li>Ensure Customer Delight</li><li>Make Capricorn the Employer of Choice</li><li>To be Quality-driven and Process-oriented</li><li>Have a Humane Touch to our Doing</li><li>Consistent Performance towards Profitable Growth</li></ul></p>
                        </div>
                        <div className='vision-sld abt-sld'>
                           <h2>Our Vision</h2>
                           <p>To be a global logistics leader providing seamlessly integrated supply chain solutions through our own network keeping in mind customer focus, employee participation, technology, innovation and environment–friendly approach.</p>
                        </div>
                    </Slider>
                </div>
            </div>
            </div> 

            {/* Part 2 -- Our Journey Of Growth */}
            <Journey_slider/>
        </div>
    </>
  )
}
